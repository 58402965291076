import React, { useState } from 'react'
import { FaEnvelope, FaComment, FaShippingFast, FaRegClock } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const notificationsData = [
    { id: 1, message: 'You have joined Inkqubee', icon: <FaEnvelope />, timestamp: '2024-12-05 08:30 AM' },
];
function NotificationsPage() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const [notifications, setNotifications] = useState(notificationsData)

    return (
        <div
        className={`flex flex-col hide-scrollbar min-h-screen overflow-auto max-w-full  w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2`}
      >
        <div className={`flex w-full  max-h-full h-auto ${!isDark ? 'bg-white' : 'bg-black'} space-y-6 rounded-lg py-2 mt-4 px-2`}>
        <div className="flex flex-col justify-center items-center notification-container w-full max-w-md mx-auto mt-6">
            <h2 className="text-2xl font-semibold mb-4 text-center">Notifications</h2>
            <ul className=" space-y-4 w-[600px]">
                {notifications.map((notification) => (
                    <li key={notification.id} className="bg-white p-4 rounded-lg shadow-md hover:bg-gray-100 transition duration-200 ease-in-out">
                        <div className="flex items-center space-x-4">
                            {/* <div className="text-xl text-blue-500">{notification.icon}</div> */}
                            <div className="flex-grow">
                                <p className="font-medium">{notification.message}</p>
                                {/* <span className="text-sm text-gray-500">{notification.timestamp}</span> */}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
        </div>
        </div>
    )
}

export default NotificationsPage