import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Alert, message, Spin } from 'antd'
import { useLoginForm } from '../../../helper/auth/authFormHook'
import { signinUser } from '../../../helper/auth/authRequestCall'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import awsStartup from '../../../assets/aws-startup.jpeg'
import startupIndia from '../../../assets/Startup-India_Preview.png'

function Login() {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("")

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSignIn = (values) => {
    setLoading(true)
    const username = values.userName.toLowerCase()
    const password = values.password
    signinUser(username, password)
      .then((data) => {
        if (data && data.status === 200) {
          const token = data.data.data.token
          const refreshToken = data.data.data.token
          localStorage.setItem('authToken', token)
          localStorage.setItem('refresh', refreshToken)
          message.success("Login successful")
          setError("")
          window.location.href = '/dashboard'
        } else if (data.status === 401) {
          setError("Login Failed: Make sure entered details are correct")
        } else {
          setError("Login Failed")
        }
      })
      .catch((err) => {
        console.error("Error occurred", err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const formik = useLoginForm(handleSignIn)
  return (
    <div className='flex min-h-screen'>
      <div className={`flex flex-col justify-center items-center w-full md:w-1/2 lg:w-1/2 px-12  ${!isDark ? 'bg-white' : 'dark-mode'} `}>

        <div className='flex w-full  max-w-full'>
          <div className={`flex-1 bg-white py-10 flex flex-col w-1/2 justify-center items-center `}>

            <form className='flex flex-col border w-full md:w-3/4 lg:w-3/4 px-5 py-7' onSubmit={formik.handleSubmit}>
              {error &&
                <div className='px-4 w-full mt-4'>
                  <Alert
                    message={error}
                    className='mb-2 '
                    type='error'
                    closable
                  />
                </div>
              }
              <h1 className='text-black text-2xl font-bold text-center mb-4'>Sign In</h1>
              <div className='relative w-full mb-4'>
                <input
                  type='email'
                  id='userName'
                  placeholder='Enter your email'
                  className={`w-full p-2 border border-gray-200 focus:outline-none placeholder-slate-600 text-black rounded-lg placeholder:text-sm bg-white ${formik.touched.userName && formik.errors.userName ? 'border-red-600' : 'border-gray-400'}`}
                  onChange={formik.handleChange}
                  // onChange={(e)=>setUserName(e.target.value)}
                  onBlur={formik.handleBlur}
                  value={formik.values.userName}
                // value={userName}
                />
                <label htmlFor='userName' className='flex absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'>
                  Email<span className='text-red-600 ml-1 '>*</span>
                </label>
                {formik.touched.userName && formik.errors.userName && <div className='text-red-700 ml-2 mt-1 text-sm'>{formik.errors.userName}</div>}
              </div>

              <div className='relative w-full mb-4'>
                <input
                  type={showPassword ? "text" : "password"}
                  id='password'
                  placeholder='Enter your password'
                  className={`w-full p-2 border border-gray-200 focus:outline-none placeholder-slate-600 text-black rounded-lg placeholder:text-sm bg-white ${formik.touched.password && formik.errors.password ? 'border-red-600' : 'border-gray-400'}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                <label htmlFor='password' className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'>
                  Password <span className='text-red-600 ml-1'>*</span>
                </label>
                <button
                  type="button"
                  className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-600 hover:text-gray-800"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </button>
                {formik.touched.password && formik.errors.password && <div className='text-red-700 ml-2 mt-1 text-sm'>{formik.errors.password}</div>}
              </div>

              <div className="flex justify-between mt-2 text-sm border-b p-2">
                {/* <p>Don't have an account? </p> */}
                <Link to='/login/forgot-password' className='text-blue-700 mt-1'>Forgot password?</Link>
                <div className='flex justify-center items-center'>
                  {loading ? (
                    <button className='btn w-full' disabled>
                      <Spin className='custom-spinner' />
                    </button>
                  ) : (
                    <input type='submit' value='Sign In' className='btn w-full' />
                  )}
                </div>
              </div>

              <div className='flex justify-between p-2 text-center'>
                <span className='text-gray-500'>Need an acount?</span>
                <Link to='/register' className='text-blue-700  underline'>Sign up</Link>
                {/* */}
              </div>
              <span className='text-gray-800 text-sm p-3'>By continuing, you agree to Inkqubee's<button type='button' className='text-blue-700 underline'>Terms and Conditions</button> and <button type='button'  className='text-blue-700 underline'>Privacy Policy</button>.</span>

            </form>
          </div>
        </div>
      </div>
      <div className="flex-1 bg-blue-700 rounded-l-[170px] mt-6 text-white justify-center items-center p-8 hidden md:flex lg:flex flex-col w-full min-h-screen">
        <h2 className="text-5xl font-bold mb-4    relative">  inkqubee<span className='absolute text-sm text-white top-0 '>™</span></h2>
        <p className="text-lg ml-2">LEARN.CONNECT.INVEST</p>
        <div className='flex justify-center items-center p-5'>
          <img
            src={awsStartup}
            alt='aws startup'
            className=' w-56'
          />
          <img
            src={startupIndia}
            alt='startup India'
            className=' w-56 h-full'
          />
        </div>
      </div>
    </div>
  )
}

export default Login
