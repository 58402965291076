import React from 'react'
import userAvatar from "../../../assets/userAvatar2.jpg"
import ProfileDropdown from '../../../utils/dropdowns/ProfileDropdown'
import { useLocation, useNavigate } from 'react-router-dom'
import UserProfile from '../../../utils/Modal/ProfileModal'
import { FaRegUser } from "react-icons/fa";
import PropTypes from 'prop-types'


function Auth({
    auth,
    profileDropdown,
    profileItems,
    setOpenProfile,
    openProfile,
    handleDropDown,
    profileDropdownRef,
    }) {
    const location = useLocation()

    const dropdownHiddn = ['/']

    const displayMenu = dropdownHiddn.includes(location.pathname)
    const navigate = useNavigate()
    return (
        <div>
            {
                !auth &&
                <button onClick={() => navigate('/login')} className='btn bg-white text-black flex'>
                    <FaRegUser className='mt-1 me-1' />SignIn</button>
            }

            {auth && displayMenu && <div className='relative' ref={profileDropdownRef}>
                <div className='flex items-center space-x-3 cursor-pointer' onClick={() => handleDropDown()}>
                    <img src={userAvatar} alt='avatar' className='h-10 w-10 rounded-full' />
                </div>
                {profileDropdown && <ProfileDropdown
                    profileDropdown={profileDropdown}
                    items={profileItems}
                    handleOpenProfile={() => setOpenProfile(!openProfile)}
                />}
            </div>}

        </div>
    )
}

Auth.propTypes = {
    auth: PropTypes.bool,
    profileDropdown: PropTypes.bool,
    //  profileItems:PropTypes.array  
    openProfile: PropTypes.bool,
    handleDropDown: PropTypes.func
}
export default Auth