import React from 'react'

function InvestmentRequirments({ formik }) {
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className='flex flex-col justify-center items-center p-3'>
                <div className='flex flex-col w-96'>
                    <label className='mb-3 text-center font-bold'>Investment Size</label>
                    <div className='flex w-96'>
                        <div className='relative w-full mb-4'>
                            <input
                                type='number'
                                id='minimumInvestment'
                                name='minimumInvestment'
                                className={`p-2 border focus:outline-none placeholder:text-sm focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1  border-gray-400 `}
                                placeholder='Minimum'
                                value={formik.values.minimumInvestment}
                                onChange={formik.minimumInvestment}
                                onBlur={formik.handleBlur}
                            />
                            <label
                                htmlFor='minimumInvestment'
                                className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                            >
                                Minimum Amount
                            </label>
                        </div>
                        <span className='ml-1 me-1 mt-2 text-lg font-bold'>-</span>
                        <div className='relative w-full mb-4'>
                            <input
                                type='number'
                                id='maximumInvestment'
                                name='maximumInvestment'
                                className={`p-2 border focus:outline-none placeholder:text-sm focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400  `}
                                placeholder='Maximum'
                                value={formik.values.maximumInvestment}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <label
                                htmlFor='maximumInvestment'
                                className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                            >
                                Minimum Amount
                            </label>
                            
                        </div>
                    </div>
                </div>

            </div>
            <div className='flex justify-end items-end'>
                <button type='submit' className='btn'>Submit</button>
            </div>
        </form>
    )
}

export default InvestmentRequirments