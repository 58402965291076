import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { CAPTCHA_SITE_KEY } from '../../../utils/constant'
import SelectBox from '../../../utils/selectbox/SelectBox'
import Select from 'react-select'

function AuthSecond({ formik, countries, province, cities, isDark }) {
    const customStyles = {
        control: (base) => ({
            ...base,
            width: "50%", // Matches the parent width
            minWidth: "150px", // Ensures a minimum width
            maxWidth: "400px", // Optional: Sets a maximum width
        }),
        menu: (base) => ({
            ...base,
            width: "50%",
            minWidth: "250px",
        }),
    };

    return (
        <div >

            <div className='relative w-full mb-4'>
                <input
                    type='text'
                    id='addressLine1'
                    placeholder='Enter Address Line 1'
                    className={`w-full p-2 border placeholder:text-sm focus:border-blue-500 focus:border-2 border-gray-200 focus:outline-none placeholder-slate-600 text-black rounded-lg ${!isDark ? 'bg-white' : 'bg-slate-300'} ${formik.touched.addressLine1 && formik.errors.addressLine1 ? 'border-2 border-red-600' : ' border-gray-400'}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.addressLine1}
                />
                <label htmlFor='addressLine1' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-white' : 'bg-slate-300'}`}>
                    Address Line 1 <span className='text-red-600 ml-1'>*</span>
                </label>
                {
                    formik.touched.addressLine1 && formik.errors.addressLine1 ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.addressLine1}
                        </div> : null
                }
            </div>
            <div className='relative w-full mb-4'>
                <input
                    type='text'
                    id='addressLine2'
                    placeholder='Enter Address Line 2'
                    className={`w-full p-2 border placeholder:text-sm focus:border-blue-500 focus:border-2 border-gray-200 focus:outline-none placeholder-slate-600 text-black rounded-lg ${!isDark ? 'bg-white' : 'bg-slate-300'} ${formik.touched.addressLine2 && formik.errors.addressLine2 ? 'border-2 border-red-600' : ' border-gray-400'}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.addressLine2}
                />
                <label htmlFor='addressLine2' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-white' : 'bg-slate-300'}`}>
                    Address Line 2 <span className='text-red-600 ml-1'>*</span>
                </label>
                {
                    formik.touched.addressLine2 && formik.errors.addressLine2 ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.addressLine2}
                        </div> : null
                }
            </div>
            <div className='relative w-full mb-4'>
                <input
                    type='text'
                    id='postalCode'
                    placeholder='Enter Pincode'
                    className={`w-full p-2 border placeholder:text-sm focus:border-blue-500 focus:border-2 border-gray-200 focus:outline-none placeholder-slate-600 text-black rounded-lg ${!isDark ? 'bg-white' : 'bg-slate-300'} ${formik.touched.postalCode && formik.errors.postalCode ? 'border-2 border-red-600' : ' border-gray-400'}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.postalCode}
                />
                <label htmlFor='postalCode' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-white' : 'bg-slate-300'}`}>
                    Pin Code <span className='text-red-600 ml-1'>*</span>
                </label>
                {
                    formik.touched.postalCode && formik.errors.postalCode ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.postalCode}
                        </div> : null
                }
            </div>

            <div className='flex md:flex-row lg:flex-row flex-col justify-between w-full gap-2 mb-4'>

                <div className='relative w-full md:w-1/3 lg:w-1/3 mt-1 me-4'>
                    <Select
                        id='country'
                        className={`p-2 w-full focus:outline-none  placeholder-slate-600 text-black rounded-lg`}
                        options={countries}
                        formikValue={countries.find((option) => option.value === formik.values.country)}
                        // onChange={(selected) => {
                        //     formik.setFieldValue("typesOfInvestment", selected.map((option) => option.value))
                        // }}
                        styles={{
                            control: (base) => ({
                                ...base,
                                width: '100%', 
                                minWidth: '100%', 
                                borderColor: '#9ca3af',
                                borderRadius: '0.5rem', 
                                height: '44px', 
                            }),
                            placeholder: (base) => ({
                                ...base,
                                color: '#475569',
                                fontSize: '0.875rem', 
                            }),
                        }}
                        onChange={(selectedOption) => {
                            formik.setFieldValue("country", selectedOption ? selectedOption.value : "")
                        }}
                        placeholder="Select"
                    />
                    <label
                        htmlFor='country'
                        className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Country <span className='text-red-600 ml-1'>*</span>
                    </label>
                    {
                        formik.touched.country && formik.errors.country ?
                            <div className='text-red-600 ml-1 placeholder:text-sm '>
                                {formik.errors.country}
                            </div> : null
                    }
                </div>
                {/* </div> */}

                {/* <div className='flex'>
                        <label className='flex me-1 ml-3 mt-4 font-bold'>State: <span className='text-red-600 ml-1'>*</span></label> */}

                <div className='relative w-full md:w-1/2 lg:w-1/3 mt-1 me-4'>
                    <Select
                        id='state'
                        className={`p-2 w-full focus:outline-none placeholder-slate-600 text-black rounded-lg`}
                        options={province}
                        formikValue={province.find((option) => option.value === formik.values.state)}
                        // onChange={(selected) => {
                        //     formik.setFieldValue("typesOfInvestment", selected.map((option) => option.value))
                        // }}
                        onChange={(selectedOption) => {
                            formik.setFieldValue("state", selectedOption ? selectedOption.value : "")
                        }}
                        placeholder="Select"
                    />
                    <label
                        htmlFor='state'
                        className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        State <span className='text-red-600 ml-1'>*</span>
                    </label>
                    {
                        formik.touched.state && formik.errors.state ?
                            <div className='text-red-600 ml-1 placeholder:text-sm '>
                                {formik.errors.state}
                            </div> : null
                    }
                </div>

                {/* </div> */}

                <div className='relative w-full md:w-1/3 lg:w-1/3  mt-1 me-4'>
                    <Select
                        id='city'
                        className={`p-2 w-full focus:outline-none placeholder-slate-600 text-black rounded-lg`}
                        options={cities}
                        formikValue={cities.find((option) => option.value === formik.values.city)}
                        // onChange={(selected) => {
                        //     formik.setFieldValue("typesOfInvestment", selected.map((option) => option.value))
                        // }}
                        onChange={(selectedOption) => {
                            formik.setFieldValue("city", selectedOption ? selectedOption.value : "")
                        }}
                        placeholder="Select"
                    />
                    <label
                        htmlFor='city'
                        className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        City <span className='text-red-600 ml-1'>*</span>
                    </label>
                    {
                        formik.touched.city && formik.errors.city ?
                            <div className='text-red-600 ml-1 placeholder:text-sm '>
                                {formik.errors.city}
                            </div> : null
                    }
                </div>
            </div>

        </div>
    )
}

export default AuthSecond