import React from 'react'

function CompanyProfileLoad() {
    return (
        <div className='flex flex-col justify-center items-center w-full h-48 border shadow-md md:p-2  rounded-md bg-white'>
            <div className='flex flex-col animate-pulse space-y-2'>
                <div
                    className={` rounded-full bg-slate-300 h-16 w-16`}
                ></div>
                <div className='flex flex-col justify-center items-center mt-2'>
                    <h1 className='h-4 w-full bg-slate-300 rounded'>

                    </h1>
                    <span className='h-2 w-14 bg-slate-300 rounded mt-2'></span>
                </div>
                <button className='h-5 w-full bg-slate-300 rounded'>
                </button>
            </div>
        </div>
    )
}

export default CompanyProfileLoad