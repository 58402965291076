import React, { useEffect, useState } from 'react'
// import UnderConstruction from '../../../assets/workstation.avif'
import { useSelector } from 'react-redux'
import { FaFilter } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { CiLocationOn } from 'react-icons/ci';
import { ImProfile } from 'react-icons/im';
import { GiTakeMyMoney } from "react-icons/gi";
import { getAllInvestors, getIndustry, getTypes } from '../../../../helper/investors/investorRequestCall';
import Select from 'react-select'
import notFound from '../../../../assets/no-result-found.avif'
import { ImCancelCircle } from "react-icons/im";


function InvestorList() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const [query, setQuery] = useState(false)
    const [type, setType] = useState("")
    const [industry, setIndustry] = useState("")
    const [industries, setIndustries] = useState([])
    const [types, setTypes] = useState([])
    const [investors, setInvestors] = useState([])
    const navigate = useNavigate()

    const clearFilter = () => {
        setType("")
        setIndustry("")
        setQuery(false)
    }

    const customStyles = {
        control: (base) => ({
            ...base,
            width: "100%", // Matches the parent width
            minWidth: "250px", // Ensures a minimum width
            maxWidth: "400px", // Optional: Sets a maximum width
        }),
        menu: (base) => ({
            ...base,
            width: "100%",
            minWidth: "250px",
        }),
    };

    const handleOpenDetail = (id) => {
        navigate(`/investor/${id}`)
    }

    const fetchIndustreies = () => {
        getIndustry()
            .then((res) => {
                if (res.status === 200) {
                    setIndustries(res.data.data)
                }
            })
            .catch((err) => {
                console.error("err", err)
            })
    }
    const fetchTypes = () => {
        getTypes()
            .then((res) => {
                if (res.status === 200) {
                    setTypes(res.data.data)
                }
            })
            .catch((err) => console.error("error", err))
    }

    useEffect(() => {
        fetchIndustreies()
        fetchTypes()
    }, [])

    const industriesMaped = industries.map((industry) => ({
        value: industry,
        label: industry
    }))

    const typesMaped = types.map((type) => ({
        value: type,
        label: type
    }))

    const buildQuery = () => {
        const params = new URLSearchParams()
        if (type) {
            params.append('investmentType', type)
            setQuery(true)
        }
        if (industry) {
            setQuery(true)
            params.append('industryPreference', industry);
        }
        return params.toString()
    }

    const fetchQurey = () => {
        const queryString = buildQuery()
        getAllInvestors(queryString)
            .then((res) => {
                if (res.status === 200) {
                    setInvestors(res.data.data)
                }
            })
            .catch((err) => {
                console.error("error:", err)
                setInvestors([])
            })
    }

    const fetchAllInvestors = () => {
        getAllInvestors("")
            .then((res) => {
                if (res.status === 200) {
                    setInvestors(res.data.data);
                }
            })
            .catch((err) => {
                console.error("error:", err);
                setInvestors([]);
            });
    };

    useEffect(() => {
        if (type || industry) {
            fetchQurey()
        } else {
            fetchAllInvestors()
        }
    }, [type, industry])
    return (
        <div className={`flex hide-scrollbar flex-col min-h-screen overflow-auto justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
            <div className={`flex flex-col ${isDark ? 'bg-slate-400' : 'bg-white'} h-full w-full p-6 rounded space-y-4 mt-6`}>

                    <div className='flex flex-col  md:flex-row justify-between border shadow-md '>
                        <h1 className='flex text-2xl font-bold mt-1 ml-2 text-gray-500'><FaFilter className='mt-0.5 me-2' />Filter</h1>
                        <div className='flex flex-col md:flex-row p-2'>


                            <div className='relative w-[250px]  mt-1 me-4'>
                                <Select
                                    id='type'
                                    className={`p-2 w-full focus:outline-none placeholder-slate-600 text-black rounded-lg`}
                                    options={typesMaped}
                                    value={typesMaped.filter((option) =>
                                        type.includes(option.value)
                                    )}
                                    // onChange={(selected) => {
                                    //     formik.setFieldValue("typesOfInvestment", selected.map((option) => option.value))
                                    // }}
                                    onChange={(selectedOption) => {
                                        setType(selectedOption ? selectedOption.value : "");
                                    }}
                                    placeholder="Filter With Types"
                                    styles={customStyles}
                                />
                                <label
                                    htmlFor='type'
                                    className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                                >
                                    Types
                                </label>
                            </div>

                            <div className='relative w-[250px]  mt-1 me-3'>
                                <Select
                                    id='industry'
                                    className={`p-2 w-full focus:outline-none placeholder-slate-600 text-black rounded-lg`}
                                    options={industriesMaped}
                                    // isMulti
                                    value={industriesMaped.filter((option) =>
                                        industry.includes(option.value)
                                    )}
                                    onChange={(selectedOption) => {
                                        setIndustry(selectedOption ? selectedOption.value : "");
                                    }}
                                    placeholder="Filter With Services"
                                    styles={customStyles}
                                />
                                <label
                                    htmlFor='industry'
                                    className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                                >
                                    Services
                                </label>
                            </div>

                            {query && <div className='flex justify-center items-center me-1 ml-2'>
                                <button className='flex text-blue-700' onClick={() => clearFilter()}><ImCancelCircle className='me-0.5 mt-0.5' />Clear Filter</button>
                            </div>}

                        </div>
                </div>
                <div className='flex flex-col md:grid md:grid-cols-2 gap-2 justify-center items-center'>
                    {
                        investors.length > 0 ? (
                            investors?.map((investor) => (
                                <div className='h-52 w-full  bg-white rounded-lg mt-4 border border-gray-400 shadow' key={investor.investorId}>
                                    <div className='p-3' >
                                        <div className='flex justify-between'>
                                            <div>
                                                <div className='flex justify-between'>
                                                    <Link to={`/investor/${investor.investorId}`} className='mt-2 text-xl font-bold cursor-pointer hover:underline'>{investor.firstName} </Link>
                                                </div>
                                            </div>
                                            <div className='flex'>
                                                {/* <div className=' ml-5'>
                                                    <Link to={`/investor/${investor.id}`} className='flex border border-blue-800 bg-blue-800 hover:bg-blue-900 text-white ml-2 p-2 me-1 mt-2 rounded-lg  hover:shadow-lg' >Add To Bookmark <ImProfile className='mt-1 ml-1' /></Link>
                                                </div> */}
                                                <div className=''>
                                                    <Link to={`/investor/${investor.investorId}`} className='flex border border-blue-800 hover:bg-blue-800 hover:text-white ml-2 p-2 me-4 mt-2 rounded-lg text-black hover:shadow-lg' >View Profile <ImProfile className='mt-1 ml-1' /></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex gap-12 px-4 p-9 '>
                                            <div>
                                                <li className='flex text-gray-500'><CiLocationOn className='mt-1 me-1' /> {investor.location.city.name},{investor.location.state.name}</li>
                                                <li className='flex text-gray-500'><GiTakeMyMoney className='mt-1 me-1' />{investor.investmentSize.minInvestment}-{investor.investmentSize.maxInvestment}</li>
                                            </div>
                                            <div className='flex '>

                                                <div className='md:ml-8'>
                                                    <h1 className='text-blue-500'>Type</h1>
                                                    <ul>
                                                        <li>{investor.investmentType}</li>
                                                    </ul>
                                                </div>
                                                <div className='hidden md:block ml-8'>
                                                    <h1 className=' text-blue-500'>About</h1>
                                                    <ul>
                                                        <li>
                                                            {investor.profileDescription.length > 10
                                                                ? (
                                                                    <>
                                                                        {investor.profileDescription.substring(0, 60)}....
                                                                        <button onClick={() => handleOpenDetail(investor.investorId)} className='text-blue-500'>
                                                                            View more
                                                                        </button>
                                                                    </>
                                                                )
                                                                : investor.profileDescription}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))) : (
                            <div className='flex justify-center items-center w-screen h-full'>
                                <img
                                    src={notFound}
                                    alt='No result found'
                                    className='h-72 w-56'
                                />
                            </div>
                        )}
                </div>
            </div>
        </div>
    )
}

export default InvestorList