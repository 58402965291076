import React from 'react'

function CompanyInfo({ formik, employees,compTypes }) {
 

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 1899 }, (_, i) => currentYear - i);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className='mt-2 grid grid-cols-2 gap-2 p-4'>
                <div className='relative w-full mb-4'>
                    <select
                        name='companyType'
                        id='companyType'
                        className='shadow border rounded w-full py-2 mb-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                        onChange={formik.handleChange}
                        value={formik.values.companyType}
                        onBlur={formik.handleBlur}
                    >
                        <option value=''>Select Company Type</option>
                        {compTypes.map((type, index) => (
                            <option key={index} value={type}>{type}</option>
                        ))}
                    </select>
                    {formik.touched.companyType && formik.errors.companyType ?
                        <div className='text-red-600 ml-1 mb-4'>
                            {formik.errors.companyType}
                        </div> : null
                    }
                    <label htmlFor='companyType' className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'>Company Type<span className='text-red-600 ml-1'>*</span></label>
                </div>

              

                <div className='relative w-full mb-4 mt-1'>
                    <input
                        type='text'
                        id='gstNumber'
                        className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg border-1  border-gray-400 `}
                        placeholder='Enter GST Number'
                        onChange={formik.handleChange}
                        value={formik.values.gstNumber}
                        onBlur={formik.handleBlur}
                    />
                    <label
                        htmlFor='gstNumber'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        GST Number
                    </label>
                </div>

                <div className='relative w-full mb-3'>
                    <select
                        name='established'
                        className='shadow border rounded w-full py-2 mb-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.established}
                    >
                        <option value=''>Select Company Established Year</option>
                        {years.map((Year, index) => (
                            <option key={index} value={Year}>
                                {Year}
                            </option>
                        ))}
                    </select>
                    {
                        formik.touched.established && formik.errors.established ?
                            <div className='text-red-600 ml-1'>
                                {formik.errors.established}
                            </div> : null
                    }
                    <label htmlFor='established' className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'>Company Established Year: <span className='text-red-600 ml-1'>*</span></label>
                </div>

              

                <div className='relative w-full mb-4'>
                    <input
                        type='text'
                        placeholder='legalEntityName'
                        id='legalEntityName'
                        onChange={formik.handleChange}
                        value={formik.values.legalEntityName}
                        className='w-full mt-1 p-2 rounded border border-gray-400 focus:outline-none'
                    />
                    <label
                        htmlFor='legalEntityName'
                        className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Legal Entity Name <span className='text-red-600 ml-1'>*</span>
                    </label>
                </div>

                <div className='relative w-full mb-4'>
                    <input
                        type='text'
                        placeholder='Company/Brand name'
                        id='companyBrandName'
                        onChange={formik.handleChange}
                        value={formik.values.companyBrandName}
                        className='w-full mt-1 p-2 rounded border border-gray-400 focus:outline-none'
                    />
                    <label
                        htmlFor='companyBrandName'
                        className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Brand name <span className='text-red-600 ml-1'>*</span>
                    </label>
                </div>

                <div className='relative w-full mb-4'>
                    <select
                        name='noOfEmployees'
                        className='shadow border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                        onChange={formik.handleChange}
                        value={formik.values.noOfEmployees}
                    >
                        <option value=''>Select </option>
                        {employees && employees.map((empCount, index) => (
                            <option key={index} value={empCount}>
                                {empCount}
                            </option>
                        ))}
                    </select>
                    <label htmlFor='noOfEmployees' className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'>Number Of Employees<span className='text-red-600 ml-1'>*</span></label>
                </div>

                <div className='relative w-full mb-4 mt-2'>
                    <input
                        type='text'
                        id='companyUrl'
                        onChange={formik.handleChange}
                        value={formik.values.companyUrl}
                        placeholder='company url'
                        className='w-full p-2 rounded border border-gray-400 focus:outline-none'
                    />
                    <label
                        htmlFor='companyUrl'
                        className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Company URL <span className='text-red-600 ml-1'>*</span>
                    </label>
                </div>
            </div>
            <div className='flex justify-center items-center ml-6 me-6'>
                    <div className='relative mt-4 w-full mb-2'>
                        <textarea
                            id='description'
                            name='description'
                            className='p-3 w-full  border border-gray-600 focus:outline-none placeholder-slate-600 text-black rounded-lg'
                            placeholder='Write Something About Company'
                            rows='5'
                            onChange={formik.handleChange}
                            value={formik.values.description}
                        />
                        <label
                            htmlFor='description'
                            className='absolute top-0 left-4 font-semibold -mt-2 px-1 text-sm transition-all text-black bg-white'
                        >
                            Business Description
                        </label>
                    </div>
                </div>
            <div className='flex justify-center items-center'>
                <button type='submit' className='btn'>Submit</button>
            </div>
        </form>
    )
}

export default CompanyInfo