import { SiGooglemarketingplatform } from "react-icons/si";
import marketing from '../../assets/marketing.svg'
import design from '../../assets/design.svg'
import tech from '../../assets/tech.svg'
import portfolio1 from '../../assets/temp/portfolio1.jpg'
import portfolio2 from '../../assets/temp/portfolio2.webp'
import organization from '../../assets/logo/._4.png'
import GoogleImg from '../../assets/temp/google.jpg'
import abc from '../../assets/temp/abcComp.jpg'
import abcCompany from '../../assets/temp/abcComp.jpg'
import cba from '../../assets/temp/cba.jpg'
import someCompany from '../../assets/temp/some company.jpg'
import requirment from '../../assets/clientRequirment.svg'
import cs4learn from '../../assets/brands/Cs4Learn_Logo.png'
// import cs4learn from '../../assets/brands/Cs4Learn_Logo.png'
import Mapetit from '../../assets/brands/Mapetit.png'
import mindInc from '../../assets/brands/mindInc.png'


export const inkqubee_acadamy =
    [
        // {
        //     name: "Marketing",
        //     icon: marketing,
        //     path: "/marketing"
        // },
        {
            name: "Post your project requirments",
            icon: requirment,
            path: "/login"
        },
        {
            name: "Start as service provider",
            icon: tech,
            path: "/login"
        }
        // {
        //     name:"Product",
        //     icon:product,
        //     path:"/Product"
        // },
        // {
        //     name:"Buisness",
        //     icon:buisness,
        //     path:"/buisness"
        // },
    ]



export const sp_portfolio_items = [
    {
        projectName: "google cloud",
        gitHubLink: "https://github.com/shyam999/Hot-Food/blob/master/src/orders/models.py",
        youtubeLink: "https://www.youtube.com/watch?v=A7qjjkjjZu0",
        description: "We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
    },
    {
        projectName: "google cloud ",
        gitHubLink: "https://github.com/shyam999/Hot-Food/blob/master/src/orders/models.py",
        youtubeLink: "https://www.youtube.com/watch?v=A7qjjkjjZu0",
        description: "We provided the google cloud"
    },
    {
        projectName: "software development",
        gitHubLink: "https://github.com/shyam999/Hot-Food/blob/master/src/orders/models.py",
        youtubeLink: "https://www.youtube.com/watch?v=Fi3_BjVzpqk",
        description: "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        projectName: "app development",
        gitHubLink: "https://github.com/shyam999/Hot-Food/blob/master/src/orders/models.py",
        youtubeLink: "https://www.youtube.com/watch?v=cRy06OuccBI",
        description: "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        projectName: "AWS service",
        gitHubLink: "https://github.com/shyam999/Hot-Food/blob/master/src/orders/models.py",
        youtubeLink: "https://www.youtube.com/watch?v=qz2BnuyACzE",
        description: "We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms.We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms.We provided Google a team of QA engineers that identified and converted manual testing into automated testing processes. Our work improved scalability while maintaining a consistent user experience across all devices and platforms."
    }
]

export const chart_data = [
    { "name": "Jan", "data1": 200, "data2": 400 },
    { "name": "Feb", "data1": 300, "data2": 500 },
    { "name": "Mar", "data1": 250, "data2": 450 },
    { "name": "Apr", "data1": 320, "data2": 480 },
    { "name": "May", "data1": 210, "data2": 470 },
    { "name": "Jun", "data1": 290, "data2": 520 },
    { "name": "Jul", "data1": 340, "data2": 530 },
    { "name": "Aug", "data1": 280, "data2": 510 },
    { "name": "Sep", "data1": 260, "data2": 490 },
    { "name": "Oct", "data1": 300, "data2": 550 },
    { "name": "Nov", "data1": 330, "data2": 560 },
    { "name": "Dec", "data1": 310, "data2": 600 }
]

export const brand_image = [
    {
        logo: cs4learn,
        name: "cs4learn"
    },
    {
        logo: Mapetit,
        name: "Mapetit"
    },
    {
        logo: mindInc,
        name: "Mind Inc"
    }
]

export const company_list = [
    {
        logo: organization,
        name: "inkqubee",
        noOfEmp: 100,
        mailId: "comp@gmail.com",
        founded: 'Founded,2001',
        services: "Software Development",
        location: "Banglore",
        verified: true,
        charts: [
            { "name": "Jan", "data1": 300, "data2": 100 },
            { "name": "Feb", "data1": 500, "data2": 500 },
            { "name": "Mar", "data1": 250, "data2": 450 },
            { "name": "Apr", "data1": 320, "data2": 400 },
            { "name": "May", "data1": 610, "data2": 470 },
            { "name": "Jun", "data1": 290, "data2": 520 },
            { "name": "Jul", "data1": 100, "data2": 330 },
            { "name": "Aug", "data1": 280, "data2": 510 },
            { "name": "Sep", "data1": 260, "data2": 490 },
            { "name": "Oct", "data1": 300, "data2": 550 },
            { "name": "Nov", "data1": 330, "data2": 560 },
            { "name": "Dec", "data1": 310, "data2": 600 }
        ]
    },
    {
        logo: GoogleImg,
        name: "Google",
        noOfEmp: 10000,
        mailId: "google@gmail.com",
        founded: 'Founded,2000',
        services: "Advertising&Marketing",
        location: "India",
        verified: true,
        charts: [
            { "name": "Jan", "data1": 100, "data2": 300 },
            { "name": "Feb", "data1": 100, "data2": 100 },
            { "name": "Mar", "data1": 250, "data2": 150 },
            { "name": "Apr", "data1": 320, "data2": 300 },
            { "name": "May", "data1": 610, "data2": 470 },
            { "name": "Jun", "data1": 290, "data2": 220 },
            { "name": "Jul", "data1": 200, "data2": 430 },
            { "name": "Aug", "data1": 280, "data2": 210 },
            { "name": "Sep", "data1": 260, "data2": 490 },
            { "name": "Oct", "data1": 400, "data2": 550 },
            { "name": "Nov", "data1": 630, "data2": 560 },
            { "name": "Dec", "data1": 310, "data2": 100 }
        ]
    },
    {
        logo: GoogleImg,
        name: "TCS",
        noOfEmp: 1000,
        mailId: "tcs@gmail.com",
        founded: 'Founded,2000',
        services: "Advertising&Marketing",
        location: "India",
        verified: false,
        charts: [
            { "name": "Jan", "data1": 200, "data2": 400 },
            { "name": "Feb", "data1": 300, "data2": 500 },
            { "name": "Mar", "data1": 250, "data2": 450 },
            { "name": "Apr", "data1": 320, "data2": 480 },
            { "name": "May", "data1": 210, "data2": 470 },
            { "name": "Jun", "data1": 290, "data2": 520 },
            { "name": "Jul", "data1": 340, "data2": 530 },
            { "name": "Aug", "data1": 280, "data2": 510 },
            { "name": "Sep", "data1": 260, "data2": 490 },
            { "name": "Oct", "data1": 300, "data2": 550 },
            { "name": "Nov", "data1": 330, "data2": 560 },
            { "name": "Dec", "data1": 310, "data2": 600 }
        ]
    },
    {
        logo: abc,
        name: "Abc",
        noOfEmp: 1001,
        mailId: "tcs@gmail.com",
        founded: 'Founded,2000',
        services: "Advertising&Marketing",
        location: "India",
        verified: true,
        charts: [
            { "name": "Jan", "data1": 400, "data2": 400 },
            { "name": "Feb", "data1": 500, "data2": 500 },
            { "name": "Mar", "data1": 550, "data2": 450 },
            { "name": "Apr", "data1": 320, "data2": 400 },
            { "name": "May", "data1": 610, "data2": 470 },
            { "name": "Jun", "data1": 290, "data2": 520 },
            { "name": "Jul", "data1": 100, "data2": 330 },
            { "name": "Aug", "data1": 280, "data2": 610 },
            { "name": "Sep", "data1": 260, "data2": 490 },
            { "name": "Oct", "data1": 300, "data2": 550 },
            { "name": "Nov", "data1": 330, "data2": 560 },
            { "name": "Dec", "data1": 310, "data2": 600 }

        ]
    }
]

export const basic_plans = [
    "Essential features for individuals or small businesses.",
    "Priority listing for your services.",
    "Verified badge for authenticity.",
    "Limited portfolio and case study display.",
    "Client feedback and reviews.",
    "Reliable, cost-effective, and provides essential features!"
]

export const Premium_plan = [
    "Premium plan with unlimited portfolio and advanced features.",
    "Priority listing with top visibility.",
    "Verified badge for authenticity.",
    "Unlimited portfolio and case study display.",
    "Client feedback and reviews.",
    "Advanced verification options (CWTIN, etc.).",
    "Social media integration.",
    "No competitor profiles shown when viewing your profile.",
    "Ideal for businesses seeking maximum visibility, reach, and exposure!"
]

export const inkqubee_plans = [
    {
        id: 1,
        name: "Basic Plan",
        price: "0",
        features: [
            "Post Projects"
        ]
    },
    {
        id: 2,
        name: "Premium Plan",
        price: "9999",
        features: [
            "Essential features for individuals or small businesses.",
            "Priority listing for your services.",
            "Verified badge for authenticity.",
            "Limited portfolio and case study display.",
            "Client feedback and reviews.",
            "Reliable, cost-effective, and provides essential features!"
        ]
    },
    ,
    {
        id: 3,
        name: "Sponsored Plan",
        price: "19999",
        features: [
            "Premium plan with unlimited portfolio and advanced features.",
            "Priority listing with top visibility.",
            "Verified badge for authenticity.",
            "Unlimited portfolio and case study display.",
            "Client feedback and reviews.",
            "Advanced verification options (CWTIN, etc.).",
            "Social media integration.",
            "No competitor profiles shown when viewing your profile.",
            "Ideal for businesses seeking maximum visibility, reach, and exposure!"
        ]
    },
]

export const service_providers = [
    {
        id: 1,
        logo: abcCompany,
        legalEntityName: "abc fgjhgffghjfdhfghgfh hfgghgfhgf",
        isVerified: true,
        location: "banglore,India",
        employees: "10-50",
        founded: 2020,
        projectHighlight: [
            {
                title: "Mobile App Dev for WellTech Product Company",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },
    {
        id: 2,
        logo: cba,
        legalEntityName: "cba",
        isVerified: true,
        location: "chennai,India",
        employees: "100-500",
        founded: 2010,
        projectHighlight: [
            {
                title: "web Dev for WellTech Product Company",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },
    {
        id: 3,
        logo: someCompany,
        legalEntityName: "some",
        isVerified: true,
        location: "Hydrabad,India",
        employees: "100-200",
        founded: 2018,
        projectHighlight: [
            {
                title: "software Developed for WellTech Product Company",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },
    {
        id: 4,
        logo: someCompany,
        legalEntityName: "some",
        isVerified: true,
        location: "Hydrabad,India",
        employees: "100-200",
        founded: 2018,
        projectHighlight: [
            {
                title: "software Developed for WellTech Product Company",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },
    {
        id: 5,
        logo: someCompany,
        legalEntityName: "some",
        isVerified: true,
        location: "Hydrabad,India",
        employees: "100-200",
        founded: 2018,
        projectHighlight: [
            {
                title: "software Developed for WellTech Product Company",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },
    {
        id: 6,
        logo: someCompany,
        legalEntityName: "some",
        isVerified: true,
        location: "Hydrabad,India",
        employees: "100-200",
        founded: 2018,
        projectHighlight: [
            {
                title: "software Developed for WellTech Product Company",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },
    {
        id: 7,
        logo: abcCompany,
        legalEntityName: "abc",
        isVerified: true,
        location: "banglore,India",
        employees: "10-50",
        founded: 2020,
        projectHighlight: [
            {
                title: "Mobile App Dev for WellTech Product Company",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },
    {
        id: 8,
        logo: abcCompany,
        legalEntityName: "abc",
        isVerified: true,
        location: "banglore,India",
        employees: "10-50",
        founded: 2020,
        projectHighlight: [
            {
                title: "Mobile App Dev for WellTech Product Company",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },
    {
        id: 9,
        logo: abcCompany,
        legalEntityName: "abc limted",
        isVerified: true,
        location: "banglore,India",
        employees: "10-50",
        founded: 2020,
        projectHighlight: [
            {
                title: "Mobile App Dev for WellTech Product Company",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },
    {
        id: 10,
        logo: abcCompany,
        legalEntityName: "abc limted",
        isVerified: true,
        location: "banglore,India",
        employees: "10-50",
        founded: 2020,
        projectHighlight: [
            {
                title: "Mobile App Dev for WellTech Product Company",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },
    {
        id: 11,
        logo: abcCompany,
        legalEntityName: "abc limted",
        isVerified: true,
        location: "banglore,India",
        employees: "10-50",
        founded: 2020,
        projectHighlight: [
            {
                title: "Mobile App Dev for WellTech Product Company",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },
    {
        id: 12,
        logo: abcCompany,
        legalEntityName: "abc limted",
        isVerified: true,
        location: "banglore,India",
        employees: "10-50",
        founded: 2020,
        projectHighlight: [
            {
                title: "Mobile App Dev for WellTech Product Company",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },
]

export const compProfile={
    companyId: 1,
    companyType: "values.companyType",
    cin: "values.CIN",
    pan: "values.PAN",
    gstin: "values.gstNumber",
    legalEntityName: "values.legalEntityName",
    officialMailId: "values.officialMailId",
    companyBrandName: "values.companyBrandName",
    businessDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    phoneNumber: "phoneNumber",
    communicationAddress: [],
    billingAddress: [],
    noOfEmployees: "values.noOfEmployees",
    companyUrl: "values.companyUrl",
    establishmentYear: "values.established",
    services: "values.Industries",
    linkedInUrl:"https://www.facebook.com/people/",
    facebookUrl:"https://www.facebook.com/people/",
    instagramUrl: "values.instagram",
    youtubeUrl:"values.youtube",
    twitterUrl: "values.xMedia",
    paymentReferenceId: "values.paymentRefrence"
}

export const notVerrified_orgs = [
    {
        id: 1,
        logo: abcCompany,
        legalEntityName: "abc another",
        isVerified: false,
        location: "banglore,India",
        employees: "10-50",
        founded: 2020,
        projectHighlight: [
            {
                title: "Mobile App Dev for WellTech Product Company",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },
    {
        id: 2,
        logo: cba,
        legalEntityName: "cba788",
        isVerified: true,
        location: "chennai,India",
        employees: "100-500",
        founded: 2010,
        projectHighlight: [
            {
                title: "web Dev for WellTech Product Company",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },
    {
        id: 3,
        logo: someCompany,
        legalEntityName: "some678",
        isVerified: true,
        location: "Hydrabad,India",
        employees: "100-200",
        founded: 2018,
        projectHighlight: [
            {
                title: "software Developed for WellTech Product Company",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor"
            }
        ]
    },

]



export const temp_investors = [
    {
        id: 1,
        firstName: "jhon",
        lastName: "doe",
        address: "Banglore,Karnataka",
        phone: "992893829",
        investmentCapacity: 70000,
        sourceOfFunds: "Salary",
        priorInvestments: [
            {
                company: "abc",
                amount: 7000,
                document: null
            },
            {
                company: "xyz",
                amount: 70001,
                document: null
            }
        ],
        typeOfInvestment: [
            "Equity",
            "debt"
        ],
        industryPreference: ["IT software", "technology"],
        profile: "dgrgerbbtrjtryjy jrtj yjynb jrrt yjeyjrttrrtyy u yt uty u yjkjtkj kjhkjtkjkjtrhkj j kjhtkr"
    },
    {
        id: 2,
        firstName: "Ranav",
        lastName: "math",
        address: "chennai,Tamilnadu",
        phone: "834938974",
        investmentCapacity: 70900,
        sourceOfFunds: "Buisness",
        priorInvestments: [
            {
                company: "yout",
                amount: 700045,
                document: null
            },
            {
                company: "xyzth",
                amount: 70001,
                document: null
            }
        ],
        typeOfInvestment: [
            "Equity",
            "debt"
        ],
        industryPreference: ["IT software", "technology"],
        profile: "dgrgerbbtrjtryjy jrtj yjynb jrrt yjeyjrttrrtyy u yt uty u yjkjtkj kjhkjtkjkjtrhkj j kjhtkr"
    },
    {
        id: 3,
        firstName: "Varun",
        lastName: "mahadev",
        address: "mysore,Karnataka",
        phone: "65786588",
        investmentCapacity: 90000,
        sourceOfFunds: "Buisness",
        priorInvestments: [
            {
                company: "xyz limited",
                amount: 57547,
                document: null
            },
            {
                company: "uhu",
                amount: 6788,
                document: null
            }
        ],
        typeOfInvestment: [
            "Equity",
            "debt"
        ],
        industryPreference: ["IT software", "technology"],
        profile: "dgrgerbbtrjtryjy jrtj yjynb jrrt yjeyjrttrrtyy u yt uty u yjkjtkj kjhkjtkjkjtrhkj j kjhtkr"
    },
    {
        id: 4,
        firstName: "Rghu",
        lastName: "mathth",
        address: "chennai,Karnataka",
        phone: "834938974",
        investmentCapacity: 70900,
        sourceOfFunds: "Buisness",
        priorInvestments: [
            {
                company: "yout",
                amount: 700045,
                document: null
            },
            {
                company: "xyzth",
                amount: 70001,
                document: null
            }
        ],
        typeOfInvestment: [
            "Equity",
            "debt"
        ],
        industryPreference: ["IT software", "technology"],
        profile: "dgrgerbbtrjtryjy jrtj yjynb jrrt yjeyjrttrrtyy u yt uty u yjkjtkj kjhkjtkjkjtrhkj j kjhtkr"
    },
    {
        id: 5,
        firstName: "Rghu",
        lastName: "mathth",
        address: "chennai,Karnataka",
        phone: "834938974",
        investmentCapacity: 70900,
        sourceOfFunds: "Buisness",
        priorInvestments: [
            {
                company: "yout",
                amount: 700045,
                document: null
            },
            {
                company: "xyzth",
                amount: 70001,
                document: null
            }
        ],
        typeOfInvestment: [
            "Equity",
            "debt"
        ],
        industryPreference: ["IT software", "technology"],
        profile: "dgrgerbbtrjtryjy jrtj yjynb jrrt yjeyjrttrrtyy u yt uty u yjkjtkj kjhkjtkjkjtrhkj j kjhtkr"
    }
]

export const temp_Projects = [
    {
        id: 1,
        title: "project1",
    },
    {
        id: 2,
        title: "project2",
    },
    {
        id: 3,
        title: "project3",
    },
    {
        id: 4,
        title: "project4",
    },
    {
        id: 5,
        title: "project5",
    },
    {
        id: 5,
        title: "project6",
    },
    {
        id: 5,
        title: "project7",
    },
    {
        id: 5,
        title: "project8",
    },
    {
        id: 5,
        title: "project8",
    },
    {
        id: 5,
        title: "project8",
    },
    {
        id: 5,
        title: "project8",
    },
    {
        id: 5,
        title: "project8",
    },
    {
        id: 5,
        title: "project8",
    }
]