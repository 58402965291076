import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { investorProfile } from '../../../../helper/investors/investorRequestCall'
import { useLocation, useNavigate } from 'react-router-dom'

function InvestorProfile() {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const [investorDetail, setDetail] = useState(null)
  const navigate=useNavigate()
  const location = useLocation();
  const investorId = location.state?.investor_id;

  const getInvestorProfile = () => {
    // console.log("profile")
    investorProfile(investorId)
      .then((res) => {
        if (res.status === 200) {
          setDetail(res.data.data)
          // setVerified(res.data.verified)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    getInvestorProfile()
  }, [])

  const handleNavigateUpdate=(id)=>{
    navigate('/investor/update-profile',{state:{investorId:id}})
  }
  return (
    <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'} p-5`}>
      <div className={`w-full h-full max-w-7xl max-h-full justify-center ${!isDark ? 'bg-white' : 'bg-black'} rounded-lg py-2 mt-14 px-2`}>
        {investorDetail && (
          <>
            <div className='flex flex-col px-14'>
              <div className='flex flex-col w-full border'>
                <div className='flex justify-end items-end p-2'>
                <button className='btn' onClick={()=>handleNavigateUpdate(investorDetail.investorId)}>Update Profile</button>
                </div>
                <h1 className='text-center text-lg text-blue-400'>Personal Info</h1>
                <div className="flex flex-col w-full justify-start items-start bg-white p-6 rounded-lg  text-black space-y-4 mt-4">
                  <div className="flex justify-between w-full">
                    <label className="text-gray-800 font-semibold">Name:</label>
                    <span className="text-gray-600 ml-4">{investorDetail.firstName} {investorDetail.lastName}</span>
                  </div>
                  <div className="flex justify-between w-full">
                    <label className="text-gray-800 font-semibold">Investment Size:</label>
                    <span className="text-gray-600 ml-4">{investorDetail.investmentSize.minInvestment}-{investorDetail.investmentSize.maxInvestment}</span>
                  </div>

                </div>

                <h1 className='text-center text-lg text-blue-400'>Location Info</h1>
                <div className="flex flex-col w-full justify-start items-start bg-white p-6 rounded-lg  text-black space-y-4 mt-4">
                  <div className="flex justify-between w-full">
                    <label className="text-gray-800 font-semibold">Address Line 1:</label>
                    <span className="text-gray-600 ml-4">{investorDetail.location.addressLine1}</span>
                  </div>
                  <div className="flex justify-between w-full">
                    <label className="text-gray-800 font-semibold">Address Line 2:</label>
                    <span className="text-gray-600 ml-4">{investorDetail.location.addressLine2}</span>
                  </div>
                  <div className="flex justify-between w-full">
                    <label className="text-gray-800 font-semibold">Pin Code:</label>
                    <span className="text-gray-600 ml-4">{investorDetail.location.postalCode}</span>
                  </div>
                  <div className="flex justify-between w-full">
                    <label className="text-gray-800 font-semibold">Country:</label>
                    <span className="text-gray-600 ml-4">{investorDetail.location.country.name}</span>
                  </div>
                  <div className="flex justify-between w-full">
                    <label className="text-gray-800 font-semibold">State:</label>
                    <span className="text-gray-600 ml-4">{investorDetail.location.state.name}</span>
                  </div>
                  <div className="flex justify-between w-full">
                    <label className="text-gray-800 font-semibold">City:</label>
                    <span className="text-gray-600 ml-4">{investorDetail.location.city.name}</span>
                  </div>
                </div>

                <h1 className='text-center mt-2 text-lg text-blue-400'>Investment Preference</h1>
                <div className='flex flex-col w-full justify-start items-start bg-white p-6 rounded-lg  text-black space-y-4 mt-4'>
                  <div className="flex justify-between  w-full">
                    <label className="text-gray-800 font-semibold">Types Of Investment:</label>
                    <ul className='flex p-1'>
                      <li className=' text-gray-600'>{investorDetail.investmentType}</li>
                    </ul>
                  </div>

                  <div className="flex flex-col justify-between w-full">
                    <label className="text-gray-800 font-semibold">Industry Preference:</label>
                    <div className='grid grid-cols-2 p-2'>
                      {investorDetail.industryPreferences.map((industries, index) => (
                        <ul key={index} className='flex p-1'>
                          <li className='p-2 rounded-full border text-gray-600'>{industries}</li>
                        </ul>
                      ))}
                    </div>
                  </div>

                  <div className="flex flex-col justify-between w-full">
                    <label className="text-gray-800 font-semibold">Location Preference:</label>
                    <div className='grid grid-cols-2 p-2'>
                      {investorDetail.preferredLocations.map((location, index) => (
                        <ul key={index} className='flex p-1'>
                          <li className='p-2 rounded-full border text-gray-600'>{location}</li>
                        </ul>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col w-full h-auto ml-2 justify-start bg-white p-6 rounded-lg border text-black space-y-4 mt-4'>
                <h1 className='text-center text-lg text-blue-400'>About</h1>
                <p className='text-gray-600'>{investorDetail.profileDescription}</p>
              </div>
            </div>
          </>
        )}
      </div>
    </div >
  )
}

export default InvestorProfile