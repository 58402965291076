import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import defaultLogo from '../../../assets/logo/defaultCompanyLogo.png'
import { MdVerified } from 'react-icons/md'
import { SiWebmoney } from "react-icons/si";
import { companyProfile, getCompanyLogo, orgReviews } from '../../../helper/organization/orgRequestCall'
import { useParams } from 'react-router-dom'
import CompanyPortfolio from '../../../components/private/serviceProvider/CompanyPortfolio'
import { TiMessages } from "react-icons/ti";
import Reviews from '../../../components/private/organization/Reviews'


function ServiceProviderDetail() {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const [profileDetail, setProfileDetail] = useState(null)
  const [reviews, setReviews] = useState([])
  const [openAbout, setOpenAbout] = useState(true)
  const [openReview, setOpenReview] = useState(false)
  const [openPortfolio, setOpenPortfolio] = useState(false)
  const [openLocation, setOpenLocation] = useState(false)
  const { org_id } = useParams()
  const [logo, setLogo] = useState(null)

  const handleAbout = () => {
    setOpenAbout(true)
    setOpenLocation(false)
    setOpenPortfolio(false)
    setOpenReview(false)
  }

  const handleReview = () => {
    setOpenAbout(false)
    setOpenLocation(false)
    setOpenPortfolio(false)
    setOpenReview(true)
  }

  const handlePortfolio = () => {
    setOpenAbout(false)
    setOpenLocation(false)
    setOpenPortfolio(true)
    setOpenReview(false)
  }

  const handleLocation = () => {
    setOpenAbout(false)
    setOpenLocation(true)
    setOpenPortfolio(false)
    setOpenReview(false)
  }
  // const navigate = useNavigate()
  const rating = 4
  const totalStars = 5
  const companyProfileDetail = "TempX Solutions specializes in providing businesses with reliable, flexible, and skilled temporary staffing solutions. With a commitment to excellence and efficiency, we bridge the gap between employers and talented professionals, ensuring seamless operations across industries.  Founded on the principles of innovation and adaptability, TempX Solutions leverages cutting-edge technology to match the right candidates with the right opportunities. From short-term projects to seasonal hiring needs, we pride ourselves on delivering customized staffing solutions that empower businesses to thrive in dynamic market conditions."

  const fetchReview = () => {
    orgReviews(org_id)
      .then((res) => {
        if (res.status === 200) {
          setReviews(res.data.data)
        }
      })
      .catch((err) => console.error("error", err))
  }

  useEffect(() => {
    fetchReview()
  }, [])

  const getOrgLogo = () => {
    getCompanyLogo(org_id)
      .then((res) => {
        const url = URL.createObjectURL(res.data);
        setLogo(url)
        console.log("data", url)
      })
      .catch((err) => {
        console.error("errpr", err)
      })
  }
  const getCompanyProfile = () => {
    companyProfile(org_id)
      .then((res) => {
        if (res.status === 200) {
          setProfileDetail(res.data.data)
          // setVerified(res.data.verified)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    getCompanyProfile()
    // console.log("profile",profile).
    getOrgLogo()
  }, [])
  return (
    <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'} p-5`}>
      {profileDetail &&
        <div className={`w-full h-full max-w-7xl max-h-full justify-center ${!isDark ? 'bg-white' : 'bg-black'} rounded-lg py-2 mt-14 px-2`}>
          {/* </div> */}
          <div className='border p-2'>
            <div className='flex justify-between  max-w-7xl  p-2 '>
              <div className='flex '>
                <img
                  src={logo ? logo : defaultLogo}
                  alt='logo'
                  className='relative h-32 w-44 rounded-xl shadow-lg ml-24'
                />
                <div>
                  <h1 className='text-4xl font-bold ml-4 '>{profileDetail.legalEntityName}</h1>
                  <div>
                    <span className='ml-4 text-gray-700'>{profileDetail.services}.</span>
                    <span className='text-gray-700'>Started from {profileDetail.establishmentYear}</span>
                  </div>
                  <div>
                    <span className='ml-4 text-gray-600'>{profileDetail.noOfEmployees} employees.</span>
                    <span className='text-gray-700'>{profileDetail.communicationAddress.city.name}</span>
                  </div>
                  <div>
                    <span className='text-gray-700 ml-4'>Brand {profileDetail.companyBrandName}</span>
                  </div>
                  <div className='flex'>
                    <div className='flex ml-4 bg-green-300 p-2 rounded-md shadow-md h-9'>
                      <MdVerified className='mt-1 text-green-600' />
                      <span className='font-semibold'>Verified by inqubee</span>
                    </div>
                  </div>
                </div>

              </div>
              <div className={`flex flex-col justify-end ${isDark ? 'bg-slate-200' : 'bg-white'} p-2 mb-8 mt-8 rounded-xl shadow-lg border-t-4 border-r-2 border-blue-300 h-20`}>
                <h1 className='text-black text-xl'>Total Reviews</h1>
                <span className='mt-2 text-blue-600'>{reviews && reviews.length}</span>
              </div>
            </div>
            <div className='flex justify-end ml-28'>
              <a
                href={profileDetail.companyUrl}
                target='_blank'
                rel="noopener noreferrer"
                className='flex bg-blue-800 hover:bg-blue-400 p-2 me-4 ml- rounded-lg text-white hover:shadow-lg'
              >
                Visit Website <SiWebmoney className='mt-1 ml-1' />
              </a>
              <button className='flex bg-blue-800 hover:bg-blue-400 p-2 me-4 ml- rounded-lg text-white hover:shadow-lg'>Connect <TiMessages className='ml-1 mt-1' /></button>
            </div>
          </div>

          <div className='flex justify-between p-3 border-b'>
            <button className={`${openAbout ? 'text-blue-600 underline' : 'text-gray-700 '} `} onClick={() => handleAbout()}>About</button>
            <button className={`${openReview ? 'text-blue-600 underline' : 'text-gray-700 '} `} onClick={() => handleReview()}>Reviews</button>
            <button className={`${openPortfolio ? 'text-blue-600 underline' : 'text-gray-700 '} `} onClick={() => handlePortfolio()}>Portfolio</button>
            <button className={`${openLocation ? 'text-blue-600 underline' : 'text-gray-700 '} `} onClick={() => handleLocation()}>Location</button>
          </div>


          <div className=' p-4 bg-gray-100 h-full'>
            {openAbout && <div className='border shadow-md p-4 bg-white'>
              <p>{profileDetail.businessDescription}</p>
            </div>}
            {openReview && <Reviews reviews={reviews} />}
            {openPortfolio && <CompanyPortfolio org_id={org_id} />}
            {openLocation && <div className=' p-4 '>
              <div className="p-8 min-h-screen">
                <div className="space-y-4">
                  {profileDetail.communicationAddress && (
                    <div  className="flex  shadow-md rounded-lg p-4 bg-white">
                      {/* Left Side: Location Info */}
                      <div className="flex-1">
                        <h2 className="font-bold text-gray-800">{profileDetail.legalEntityName}</h2>
                        <p className="text-gray-600 mt-1">
                          {profileDetail.communicationAddress.addressLine1}, {profileDetail.communicationAddress.addressLine2}
                        </p>
                        <p className="text-gray-600">
                        {profileDetail.communicationAddress.city.name}, {profileDetail.communicationAddress.state.name} - {profileDetail.communicationAddress.postalCode}
                        </p>
                        <p className="text-gray-600">{profileDetail.communicationAddress.country.name}</p>
                      </div>

                      {/* Right Side: Map Link */}
                    </div>
                  )}
                </div>
              </div>
            </div>}
          </div>

        </div>}
    </div>
  )
}

export default ServiceProviderDetail