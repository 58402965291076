import React from 'react'
import { countryCodes } from '../../../../utils/tempJson/_optionsJson'
import SelectBox from '../../../../utils/selectbox/SelectBox'

function FirstStep({ formik, onFileChange, onIdProofChange, onPANProofChange, countries, provinces, cities }) {


    const countryMaped = countries.map((country) => ({
        value: country.countryId,
        label: country.name
    }))

    const provinceMaped = provinces.map((state) => ({
        value: state.provinceId,
        label: state.name
    }))

    const cityMaped = cities.map((city) => ({
        value: city.cityId,
        label: city.name
    }))

    return (
        <div className='flex flex-col justify-center items-center '>
            <div className='flex flex-col justify-center items-center'>
                <div className='relative w-full mb-4 '>
                    <input
                        type='text'
                        id='firstName'
                        name='firstName'
                        className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg border-1 focus:border-blue-500 focus:border-2 placeholder:text-sm ${formik.touched.firstName && formik.errors.firstName ? 'border-red-600' : ' border-gray-400 '} `}
                        placeholder='Enter First Name'
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.firstName && formik.errors.firstName ?
                        <div className='text-red-600 ml-1 placeholder:text-sm '>
                            {formik.errors.firstName}
                        </div> : null
                    }
                    <label
                        htmlFor='firstName'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                    >
                        First Name <span className='text-red-600 ml-1'>*</span>
                    </label>
                </div>
                <div className='relative w-full mb-4'>
                    <input
                        type='text'
                        id='lastName'
                        name='lastName'
                        className={`p-2 border w-full focus:outline-none focus:border-blue-500 focus:border-2 placeholder-slate-600 text-black rounded-lg border-1 placeholder:text-sm  ${formik.touched.lastName && formik.errors.lastName ? 'border-red-600' : ' border-gray-400 '}`}
                        placeholder='Enter Last Name'
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.lastName && formik.errors.lastName ?
                        <div className='text-red-600 ml-1 placeholder:text-sm '>
                            {formik.errors.lastName}
                        </div> : null
                    }
                    <label
                        htmlFor='lastName'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Last Name <span className='text-red-600 ml-1'>*</span>
                    </label>
                </div>

                <div className='relative w-full mb-4'>
                    <input
                        type='text'
                        id='addressLine1'
                        name='addressLine1'
                        className={`p-2 border w-full focus:outline-none focus:border-blue-500 focus:border-2 placeholder-slate-600 text-black rounded-lg border-1  placeholder:text-sm  ${formik.touched.addressLine1 && formik.errors.addressLine1 ? 'border-red-600' : ' border-gray-400 '}`}
                        placeholder='Enter Address Line 1'
                        onChange={formik.handleChange}
                        value={formik.values.addressLine1}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.addressLine1 && formik.errors.addressLine1 ?
                        <div className='text-red-600 ml-1 placeholder:text-sm '>
                            {formik.errors.addressLine1}
                        </div> : null
                    }
                    <label
                        htmlFor='addressLine1'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Address Line 1<span className='text-red-600 ml-1'>*</span>
                    </label>
                </div>

                <div className='relative w-full mb-4'>
                    <input
                        type='text'
                        id='addressLine2'
                        name='addressLine2'
                        className={`p-2 border w-full focus:outline-none focus:border-blue-500 focus:border-2 placeholder-slate-600 text-black rounded-lg border-1  placeholder:text-sm  ${formik.touched.addressLine2 && formik.errors.addressLine2 ? 'border-red-600' : ' border-gray-400 '}`}
                        placeholder='Enter Address Line 2'
                        onChange={formik.handleChange}
                        value={formik.values.addressLine2}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.addressLine2 && formik.errors.addressLine2 ?
                        <div className='text-red-600 ml-1 placeholder:text-sm '>
                            {formik.errors.addressLine2}
                        </div> : null
                    }
                    <label
                        htmlFor='addressLine1'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Address Line 2<span className='text-red-600 ml-1'>*</span>
                    </label>
                </div>

                <div className='relative w-full mb-4'>
                    <input
                        type='text'
                        id='pinCode'
                        name='pinCode'
                        className={`p-2 border w-full focus:outline-none focus:border-blue-500 focus:border-2 placeholder-slate-600 text-black rounded-lg border-1  placeholder:text-sm  ${formik.touched.pinCode && formik.errors.pinCode ? 'border-red-600' : ' border-gray-400 '}`}
                        placeholder='Enter Pincode'
                        onChange={formik.handleChange}
                        value={formik.values.pinCode}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.pinCode && formik.errors.pinCode ?
                        <div className='text-red-600 ml-1 placeholder:text-sm '>
                            {formik.errors.pinCode}
                        </div> : null
                    }
                    <label
                        htmlFor='pinCode'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Pincode<span className='text-red-600 ml-1'>*</span>
                    </label>
                </div>

                <div className='flex justify-between gap-2 mb-4 w-full'>
                    <SelectBox
                        handleBlur={formik.handleBlur}
                        arrayValues={countryMaped}
                        placeholder={"Select Country"}
                        inputArgs="country"
                        formikValue={countryMaped.find((option) => option.value === formik.values.country)} // Adjust this if multi-selection is used
                        handleChange={(selectedOption) => {
                            formik.setFieldValue("country", selectedOption ? selectedOption.value : ""); // Ensure value is updated correctly
                        }}
                        label="Country"
                        required
                    />


                    {
                        formik.touched.country && formik.errors.country ?
                            <div className='text-red-600 ml-1 text-sm '>
                                {formik.errors.country}
                            </div> : null
                    }
                    {/* </div> */}

                    {/* <div className='flex'>
                        <label className='flex me-1 ml-3 mt-4 font-bold'>State: <span className='text-red-600 ml-1'>*</span></label> */}
                    <SelectBox
                        handleBlur={formik.handleBlur}
                        arrayValues={provinceMaped}
                        placeholder={"Select State"}
                        inputArgs="state"
                        formikValue={provinceMaped.find((option) => option.value === formik.values.state)}
                        handleChange={(selectedOption) => {
                            formik.setFieldValue("state", selectedOption ? selectedOption.value : "");
                        }}
                        label="State"
                        required
                    />
                    {
                        formik.touched.state && formik.errors.state ?
                            <div className='text-red-600 ml-1 text-sm '>
                                {formik.errors.state}
                            </div> : null
                    }

                    {/* </div> */}
                    <SelectBox
                        handleBlur={formik.handleBlur}
                        arrayValues={cityMaped}
                        placeholder={"Select City"}
                        inputArgs="city"
                        formikValue={cityMaped.find((option) => option.value === formik.values.city)}
                        handleChange={(selectedOption) => {
                            formik.setFieldValue("city", selectedOption ? selectedOption.value : "");
                        }}
                        label="City"
                        required
                    />
                    {
                        formik.touched.city && formik.errors.city ?
                            <div className='text-red-600 ml-1 text-sm '>
                                {formik.errors.city}
                            </div> : null
                    }
                </div>

                <div class={`flex relative mb-4 rounded-md w-full bg-white outline outline-1 -outline-offset-1 ${formik.touched.phone && formik.errors.phone ? 'outline-red-600' : ' outline-gray-400 '} has-[input:focus-within]:outline has-[input:focus-within]:outline-1 has-[input:focus-within]:-outline-offset-2 has-[input:focus-within]:outline-indigo-600`}>
                    <div class=" grid shrink-0 grid-cols-1 focus-within:relative">
                        <select
                            id="countryCode"
                            name="countryCode"
                            autocomplete="countryCode"
                            aria-label="countryCode"
                            value={formik.values.countryCode}
                            onChange={formik.handleChange}
                            class="col-start-1 row-start-1 w-full appearance-none focus:border-blue-500 focus:border-2 rounded-md py-2 pl-3.5 pr-7 text-base text-black placeholder:text-gray-400 focus:outline focus:outline-1 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm/6 placeholder:text-sm "
                        >
                            {countryCodes.map((codes) => (
                                <option key={codes.code} value={codes.code}>{codes.code}({codes.country})</option>
                            ))}
                        </select>
                        <svg class="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" data-slot="icon">
                            <path fill-rule="evenodd" d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <input
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                        onBlur={formik.handleBlur}
                        name="phone" id="phone"
                        class="block min-w-0 grow py-1.5 pl-1 pr-3 text-base focus:border-blue-500 focus:border-2 text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6 "
                        placeholder="123-456-7890"
                    />
                    <label
                        htmlFor='phone'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Phone<span className='text-red-600 ml-1'>*</span>
                    </label>

                </div>
                {/* {
                        formik.touched.phone && formik.errors.phone ?
                            <div className='text-red-600 ml-1'>
                                {formik.errors.phone}
                            </div> : null
                    } */}

                <div className='relative w-full mb-4'>
                    <input
                        type='email'
                        id='email'
                        name='email'
                        className={`p-2 border w-full focus:outline-none focus:border-blue-500 focus:border-2 placeholder-slate-600 text-black rounded-lg border-1 placeholder:text-sm   ${formik.touched.email && formik.errors.email ? 'border-red-600' : ' border-gray-400 '} `}
                        placeholder='example@mail.com'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email ?
                        <div className='text-red-600 ml-1 placeholder:text-sm '>
                            {formik.errors.email}
                        </div> : null
                    }
                    <label
                        htmlFor='email'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Email Id<span className='text-red-600 ml-1'>*</span>
                    </label>
                </div>

                <div className='flex flex-col justify-center items-center p-5 border w-full'>
                    <div className='flex w-full'>
                        <div className='relative mt-6 w-full'>
                            <input
                                type='text'
                                id='idProofNumber'
                                name='idProofNumber'
                                className={`p-2 border w-full  focus:outline-none focus:border-blue-500 focus:border-2 placeholder-slate-600 text-black rounded-lg border-1 placeholder:text-sm   ${formik.touched.adharCardNumber && formik.errors.adharCardNumber ? 'border-red-600' : ' border-gray-400 '} `}
                                placeholder='Enter Any ID Proof Number'
                                onChange={formik.handleChange}
                                value={formik.values.idProofNumber}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.idProofNumber && formik.errors.idProofNumber ?
                                <div className='text-red-600 ml-1'>
                                    {formik.errors.idProofNumber}
                                </div> : null
                            }
                            <label
                                htmlFor='idProofNumber'
                                className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                            >
                                ID Proof Number
                            </label>
                        </div>

                        {/* <div className="w-full mb-7 mt-0.5 ml-2">
                            <label className="block font-bold  ml-3">
                                Upload ID Proof
                            </label>
                            <input
                                type="file"
                                multiple
                                accept=".pdf,.doc,.docx"
                                onChange={onIdProofChange}
                                className={`p-1 border w-96  focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1  ${formik.touched.adharCardNumber && formik.errors.adharCardNumber ? 'border-red-600' : ' border-gray-400 '} `}
                            />
                        </div> */}
                    </div>
                    <span className='p-1.5 bg-blue-100 text-gray-600 rounded-full mt-2'><b>Note:</b>Claim Trust Shield by verifying your identity!</span>
                </div>

                {/* <div className='flex justify-center items-center p-5 border mt-4 mb-4 w-full'>
                    <div className='relative w-full mb-2'>
                        <input
                            type='text'
                            id='panNumber'
                            name='panNumber'
                            className={`p-2 border w-full focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1 placeholder:text-sm   ${formik.touched.panNumber && formik.errors.panNumber ? 'border-red-600' : ' border-gray-400 '} `}
                            placeholder='Enter PAN Number'
                            onChange={formik.handleChange}
                            value={formik.values.panNumber}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.panNumber && formik.errors.panNumber ?
                            <div className='text-red-600 ml-1 placeholder:text-sm '>
                                {formik.errors.panNumber}
                            </div> : null
                        }
                        <label
                            htmlFor='panNumber'
                            className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                        >
                            PAN Number <span className='text-red-600 ml-1'>*</span>
                        </label>
                    </div>

                    <div className="w-full mb-8 ml-2">
                        <label className="block font-bold  ml-3">
                            Upload PAN Document
                        </label>
                        <input
                            type="file"
                            multiple
                            accept=".pdf,.doc,.docx"
                            onChange={onPANProofChange}
                            title='ID Proof'
                            className="file-input   px-4 py-1 rounded-md border border-gray-300 focus:outline-none focus:ring-2 "
                        />
                    </div>
                </div> */}

                <div className='relative w-full mb-4 mt-4'>
                    <input
                        type='text'
                        id='panNumber'
                        name='panNumber'
                        className={`p-2 border w-full focus:outline-none focus:border-blue-500 focus:border-2 placeholder-slate-600 text-black rounded-lg border-1 placeholder:text-sm   ${formik.touched.panNumber && formik.errors.panNumber ? 'border-red-600' : ' border-gray-400 '} `}
                        placeholder='Enter PAN Number'
                        onChange={formik.handleChange}
                        value={formik.values.panNumber}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.panNumber && formik.errors.panNumber ?
                        <div className='text-red-600 ml-1 placeholder:text-sm '>
                            {formik.errors.panNumber}
                        </div> : null
                    }
                    <label
                        htmlFor='panNumber'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        PAN Number <span className='text-red-600 ml-1'>*</span>
                    </label>
                </div>

                {/* <div className='flex flex-col justify-center items-center p-5 border mt-4 w-full mb-4'>
                    <div className="w-full mb-4">
                        <label className="block font-bold mb-2 ml-3">
                            Upload Income Proof
                        </label>
                        <input
                            type="file"
                            multiple
                            accept=".pdf,.doc,.docx"
                            onChange={onFileChange}
                            className="file-input file-input-bordered w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <span className='p-1.5 bg-blue-100 text-gray-600 rounded-full'><b>Note:</b>Claim Credibility Badge by verifying your Income!</span>
                </div> */}

                <div className='relative w-full mb-4'>
                    <textarea
                        id='profile'
                        name='profile'
                        className='p-3 w-full border border-gray-600 focus:outline-none focus:border-blue-500 focus:border-2 placeholder-slate-600 text-black rounded-lg placeholder:text-sm '
                        placeholder='Enter About Your Profile'
                        rows='5'
                        onChange={formik.handleChange}
                        value={formik.values.profile}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.profile && formik.errors.profile ?
                        <div className='text-red-600 ml-1 placeholder:text-sm '>
                            {formik.errors.profile}
                        </div> : null
                    }
                    <label
                        htmlFor='profile'
                        className='absolute top-0 left-4 font-semibold -mt-2 px-1 text-sm transition-all text-black bg-white'
                    >
                        Profile <span className='text-red-600 ml-1'>*</span>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default FirstStep