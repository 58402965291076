import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { usePortfolioCreate } from '../../../../helper/serviceProvider/portfolioFormHook';
import { createPortfolio } from '../../../../helper/serviceProvider/serviceProviderRequestCall';
import { message } from 'antd';
import { servicesOptions } from '../../../../utils/tempJson/_optionsJson';
import { jwtDecode } from 'jwt-decode';
import { postReview, userOrganization } from '../../../../helper/organization/orgRequestCall';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import CreatePortfolio from '../../../../components/private/serviceProvider/CreatePortfolio';
import Review from '../../../../components/private/serviceProvider/Review';
import { IoIosArrowBack } from "react-icons/io";
import { usePostReview } from '../../../../helper/organization/orgFormHook';

function ServicEProviderRegister() {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const [companies, setCompanies] = useState([])
  const [openPortfolioForm, setOpenPortfolioForm] = useState(false)
  const [openReviewForm, setOpenReviewForm] = useState(false)
  const location = useLocation();
  const orgId = location.state?.companyId;
  // console.log("org",id)

  // const filterVerify = companies && companies.filter(company => company.isVerified)

  const token = localStorage.getItem('authToken')
  const decoded = jwtDecode(token)
  const userId = decoded.userId
  const navigate = useNavigate()
  const handleBack = () => {
    navigate(-1)
  }

  const fetchCompany = () => {
    userOrganization(userId)
      .then((res) => {
        if (res.status === 200) {
          setCompanies(res.data)
        }
      })
      .catch((err) => { console.error("Error", err) })
  }

  useEffect(() => {
    fetchCompany()
  }, [])

  const handleCreatePortfolio = (values) => {
    const { name, service, description } = values
    createPortfolio(userId, name, orgId, service, description)
      .then((res) => {
        // console.log("data is", res)
        if (res.status === 200) {
          message.success("portfolio created successfully...")
          formik.setFieldValue("name", "")
          formik.setFieldValue("companyId", "")
          formik.setFieldValue("service", "")
          formik.setFieldValue("description", "")
        } else {
          message.error("Portfolio creation failed")
        }
      })
      .catch((err) => {
        console.error("error occurd:", err)
      })

  }

  const handlePostReview = (values) => {
    const { name, phone, email, company, comment } = values
    const payLoad = {
      reviewerName: name,
      companyId: company,
      reviewerEmail: email,
      reviewerPhone: phone,
      reviewComment: comment
    }
    console.log("review", payLoad)
    postReview(payLoad)
      .then((res) => {
        if (res.status === 200) {
          message.success("Review Posted Successfully")
          formikReview.resetForm()
        } else {
          message.error("Review Failed")
        }
      })
      .catch((err) => console.error("error", err))
  }

  const formik = usePortfolioCreate(handleCreatePortfolio)
  const formikReview = usePostReview(handlePostReview)


  const handleRemoveTag = (service) => {
    const updatedService = formik.values.service.filter((t) => t !== service);
    formik.setFieldValue('service', updatedService);
  };

  const serviceMaped = servicesOptions.map((service) => ({
    value: service,
    label: service
  }))

  // console.log("user",decoded)
  return (

    <div className={`flex flex-col md:flex-row lg:flex-row min-h-screen  w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
      <div className={`flex flex-col w-full max-w-7xl max-h-full h-auto ${!isDark ? 'bg-white' : 'bg-black'} space-y-6 rounded-lg p-8 mt-4 px-2`}>
        <div className={`w-full mt-1  space-y-6 rounded-lg px-6 p-2`} >
          <li
            onClick={() => setOpenPortfolioForm(!openPortfolioForm)}
            className='w-full flex justify-between text-xl cursor-pointer bg-blue-800 p-2 text-white'
          >
            Create Portfolio
            {openPortfolioForm ?
              <FaMinus className='cursor-pointer'
                onClick={() => setOpenPortfolioForm(false)}
              /> :
              <FaPlus
                className='cursor-pointer'
                onClick={() => setOpenPortfolioForm(true)}
              />}
          </li>
          {openPortfolioForm && <div>
            <CreatePortfolio formik={formik} servicesOptions={serviceMaped} />
          </div>}
        </div>
        <div className={`w-full mt-1 space-y-6 rounded-lg px-6 p-2`} >
          <li
            onClick={() => setOpenReviewForm(!openReviewForm)}
            className='flex justify-between text-xl  cursor-pointer bg-blue-800 p-2 text-white'
          >
            Review
            {openReviewForm ?
              <FaMinus className='cursor-pointer'
                onClick={() => setOpenReviewForm(!openReviewForm)}
              /> :
              <FaPlus
                className='cursor-pointer'
                onClick={() => setOpenReviewForm(!openReviewForm)}
              />}
          </li>
          {openReviewForm && <div>
            <Review formikReview={formikReview} />
          </div>}
        </div>
        <div className='flex justify-center items-center'>
          <button className='flex btn ' type='button' onClick={() => handleBack()}><IoIosArrowBack className='mt-0.5 ' />Back</button>
        </div>
      </div>

    </div>
  )
}

export default ServicEProviderRegister