import { useFormik } from "formik"
import {  addUsersSchema, orgEditSchema, orgRegsiterSchema, reviewSchema } from "../../validation";

export const useOrgForm=(handleSubmit)=>{
    return useFormik({
        initialValues: {
            companyType:"",
            CIN: "",
            PAN: "",
            gstNumber:"",
            legalEntityName: "",
            officialMailId: "",
            verifyMailId: "",
            companyBrandName: "",
            description:"",
            countryCode:"+1",
            phoneNo:"",
            addressLine1:"",
            addressLine2:"",
            pinCode:"",
            landmark:"",
            locationId:null,
            country:"",
            province:"",
            city:"",
            noOfEmployees: "",
            companyUrl: "",
            established:"",
            // logo:[],
            // registerdDocument:null,
            Industries:"",
            LinkedIn:"",
            faceBook:"",
            instagram:"",
            xMedia:"",
            youtube:"",
            paymentRefrence:"",
            privacyPolicy:false,
            termsAndCondition:false,
            refundPolicy:false
        },
        validationSchema: orgRegsiterSchema,
        onSubmit: values => {
            console.log("Formik Values Before Submit:", values);
            handleSubmit(values)
        },
    })
}

export const useOrgEditForm=(handleSubmit)=>{
    return useFormik({
        initialValues: {
            companyType:"",
            CIN: "",
            PAN: "",
            gstNumber:"",
            legalEntityName: "",
            officialMailId: "",
            verifyMailId: "",
            companyBrandName: "",
            description:"",
            countryCode:"+1",
            phoneNo:"",
            addressLine1:"",
            addressLine2:"",
            pinCode:"",
            // landmark:"",
            country:"",
            province:"",
            city:"",
            noOfEmployees: "",
            companyUrl: "",
            established:"",
            // logo:[],
            // registerdDocument:null,
            Industries:"",
            LinkedIn:"",
            faceBook:"",
            instagram:"",
            xMedia:"",
            paymentRefrence:"",
            privacyPolicy:false,
            termsAndCondition:false,
            refundPolicy:false
        },
        // validationSchema: orgEditSchema,
        onSubmit: values => {
            // console.log("values",values)
            handleSubmit(values)
        },
    })
}

export const useAddUser=(handleSubmit)=>{
    return useFormik({
        initialValues: {
            userName:"",
            firstName:"",
            lastName:"",
            isSuperAdmin:false,
            companyId:""
        },
        validationSchema:addUsersSchema ,
        onSubmit: values => {
            handleSubmit(values)
        },
    })
}


export const usePostReview=(handleSubmit)=>{
    return useFormik({
        initialValues: {
            name:"",
            company:"",
            email:"",
            phone:"",
            comment:""
        },
        validationSchema:reviewSchema ,
        onSubmit: values => {
            handleSubmit(values)
        },
    })
}