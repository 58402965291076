import React from 'react'
import Select from 'react-select'

function LastSteps({ formik,industries,types,locations }) {

    const customStyles = {
        control: (base) => ({
            ...base,
            width: "100%",
            minWidth: "300px",
        }),
        menu: (base) => ({
            ...base,
            width: "100%", 
            minWidth: "300px",
        }),
        control: (base) => ({
            ...base,
            width: '100%', 
            minWidth: '100%', 
            borderColor: '#9ca3af',
            borderRadius: '0.5rem', 
            height: '44px', 
        }),
        placeholder: (base) => ({
            ...base,
            color: '#475569',
            fontSize: '0.875rem', 
        })
    };

    const industriesMaped = industries.map((industry) => ({
        value: industry,
        label: industry
    }))

    const typesMaped = types.map((types) => ({
        value: types,
        label: types
    }))



    // Convert the object into an array of objects with the key as value and the corresponding label
    const locationMapped = Object.keys(locations).map((key) => ({
        //   value: key,
        value: locations[key],
        label: locations[key]
    }));
    return (
        <div className='flex flex-col justify-center items-center '>
            <div className='flex flex-col justify-center items-center'>

                <div className='relative w-[600px] mt-4'>
                    <Select
                        id='typesOfInvestment'
                        options={typesMaped}
                        value={typesMaped.filter((option) =>
                            formik.values.typesOfInvestment.includes(option.value)
                        )}
                        // onChange={(selected) => {
                        //     formik.setFieldValue("typesOfInvestment", selected.map((option) => option.value))
                        // }}
                        onChange={(selectedOption) => {
                            formik.setFieldValue("typesOfInvestment", selectedOption ? selectedOption.value : "");
                          }}
                        placeholder="Choose The Types"
                        styles={customStyles}
                        onBlur={formik.handleBlur}
                    />
                    <label
                        htmlFor='typesOfInvestment'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Choose Types<span className='text-red-600 ml-1'>*</span>
                    </label>
                    {formik.touched.typesOfInvestment && formik.errors.typesOfInvestment ?
                        <div className='text-red-600 ml-3'>
                            {formik.errors.typesOfInvestment}
                        </div> : null
                    }
                </div>

                <div className='relative w-[600px] mt-4'>
                    <Select
                        id='industryPreference'
                        options={industriesMaped}
                        value={industriesMaped.filter((option) =>
                            formik.values.industryPreference.includes(option.value)
                        )}
                        onBlur={formik.handleBlur}
                        onChange={(selected) => {
                            formik.setFieldValue("industryPreference", selected.map((option) => option.value))
                        }}
                        placeholder="Choose The Industries"
                        isMulti
                        styles={customStyles}
                    />
                    <label
                        htmlFor='industryPreference'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Choose Industries<span className='text-red-600 ml-1'>*</span>
                    </label>
                    {formik.touched.industryPreference && formik.errors.industryPreference ?
                        <div className='text-red-600 ml-3'>
                            {formik.errors.industryPreference}
                        </div> : null
                    }
                </div>

                <div className='relative w-[600px] mt-4'>
                    <Select
                        id='locationPreference'
                        options={locationMapped}
                        value={locationMapped.filter((option) =>
                            formik.values.locationPreference.includes(option.value)
                        )}
                        onChange={(selected) => {
                            formik.setFieldValue("locationPreference", selected.map((option) => option.value))
                        }}
                        placeholder="Choose The Locations"
                        isMulti
                        styles={customStyles}
                        onBlur={formik.handleBlur}
                    />
                    <label
                        htmlFor='locationPreference'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Choose Locations<span className='text-red-600 ml-1'>*</span>
                    </label>
                    {formik.touched.locationPreference && formik.errors.locationPreference ?
                        <div className='text-red-600 ml-3'>
                            {formik.errors.locationPreference}
                        </div> : null
                    }
                </div>
            </div>
        </div>
    )
}

export default LastSteps