import React from 'react'

function OrgProfileMenu() {

  const navigateToSection = (sectionId) => {
    const section = document.getElementById(sectionId)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }
  return (
    <div className="fixed top-32 w-full z-10 px-4 overflow-y-auto">
      <nav className="flex flex-row w-full" id="profile_navigation_block">
        {/* <button onClick={() => navigateToSection('companyInfo')} className='p-2 border bg-blue-800 w-full text-white'>Company Info</button> */}
        <a href='#companyInfo' title='Navigation companyInfo' className='p-2 border bg-blue-800 w-full text-white'>Company Info</a>
        <a href='#location' title='Navigation location'  className='p-2 border bg-blue-800 w-full text-white'>Location Info</a>
        <a href='#socialMediaInfo' title='Navigation socialMediaInfo' onClick={() => navigateToSection('socialMediaInfo')} className='p-2 border bg-blue-800 w-full text-white'>Social Media Info</a>
      </nav>
    </div>
  )
}

export default OrgProfileMenu