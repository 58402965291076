import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import userAvatar from "../../../assets/userAvatar3.jpg"
import { getUserProfile } from '../../../helper/auth/authRequestCall'
import { setUserDetails } from '../../../store/slices/authSlice'
import { jwtDecode } from 'jwt-decode'
import { useLocation, useNavigate } from 'react-router-dom'
import { getCompanyLogo, unverifiedCompanies, userOrganization } from '../../../helper/organization/orgRequestCall'
import ResponseModal from '../../../utils/Modal/ResponseModal'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import LeftSideSection from '../../../components/private/dashboard/LeftSideSection';
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Tooltip } from 'antd'
import { temp_investors } from '../../../utils/tempJson/_tempItems'
import UserOption from '../../../utils/Modal/UserOption'
import UserProfileLoad from '../../../utils/loadingEffect/UserProfileLoad'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { getAllInvestor } from '../../../helper/investors/investorRequestCall'


function UserProfile() {
    const { isDark, notVerifiedCompanies, verifiedCompanies, investor } = useSelector((state) => state.displaySettingsState)
    const { user } = useSelector((state) => state.authenticationState)
    const [investors, setInvestors] = useState([])
    const [warning, setWarning] = useState(false)
    const [warningMessage, setWarningMessage] = useState("")
    const [userLoad, setUserLoad] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [loadUnverified, setLoadUnverified] = useState(false)
    const [unverified, setUnverified] = useState([])
    const [companyLogos, setCompanyLogos] = useState([])
    const [logoMap, setLogoMap] = useState({});
    const token = localStorage.getItem('authToken')
    const decoded = jwtDecode(token)
    const userId = decoded.userId
    const user_id = user && user.id
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const navigatedUserId = location.state?.userId;
    const [animationParent] = useAutoAnimate()

    // console.log("user",navigatedUserId)

    const handleUnverified = (companyName) => {
        setWarning(true)
        setWarningMessage(`Your organization ${companyName && companyName} is under verification! Please wait until it's done...`)
    }

    const handleServiceProvider = (id, verified) => {
        navigate('/service-provider/option', { state: { org_id: id } })
    }

    const openInvestorProfile = (id) => {
        navigate('/investor/profile', { state: { investor_id: id } })
    }

    const handleProject = (id, verified) => {

        navigate('/clients/post-project', { state: { org_id: id } })
    }

    const handleSellCompany = (verified) => {
        if (verified) {
            navigate('/company/sell')
        } else {
            setWarning(true)
            setWarningMessage("Your organization is under verification! Once it's done, you will be able to access this feature.")
        }
    }


    const handleGoToId = (path, id) => {
        navigate('/user-profile/update', { state: { user_Id: id } })
    }

    const openProfile = (companyId) => {
        navigate('/organization/profile', { state: { org_id: companyId } })
    }

    const fetchUnverified = () => {
        setLoadUnverified(true)
        unverifiedCompanies(userId)
            // console.log(unverifiedCompanies(userId))
            .then((res) => {
                console.log("res", res)
                if (res.status === 200) {
                    setUnverified(res.data.data)
                } else {
                    console.log("error")
                }
            })
            .catch((err) => {
                console.error("error", err)
            })
            .finally(() => setLoadUnverified(false))
    }

    const getUser = () => {
        setUserLoad(true)
        const token = localStorage.getItem('authToken')
        const decoded = jwtDecode(token)
        const userId = decoded.userId
        getUserProfile(userId)
            .then(data => {
                const userProfile = data.data.data
                dispatch(setUserDetails(userProfile))
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setUserLoad(false))
    }

    const fetchCompany = () => {
        setLoading(true)
        userOrganization(userId)
            .then((res) => {
                if (res.status === 200) {
                    setCompanyLogos(res.data.data)
                }
            })
            .catch((err) => { console.error("Error", err) })
            .finally(() => setLoading(false))
    }

    const fetchLogo = async (companyId) => {
        await getCompanyLogo(companyId)
            .then((res) => {
                if (res.status === 200) {
                    const blob = res.data
                    const imageUrl = URL.createObjectURL(blob)
                    setLogoMap((prev) => ({ ...prev, [companyId]: imageUrl }));
                }
            })
            .catch((err) => {
                console.error("error:", err)
            })
    }

    const fetchInvestor=()=>{
        getAllInvestor()
        .then((res)=>{
          if(res.status===200){
            setInvestors(res.data.data)
          }
        })
        .catch((err)=>console.error("error",err))
      }
    
    useEffect(() => {
        fetchUnverified()
        fetchCompany()
        fetchInvestor()
    }, [])

    useEffect(() => {
        companyLogos.forEach((company) => {
            if (!logoMap[company.id]) {
                fetchLogo(company.companyId);
            }
        });
    }, [companyLogos]);

    useEffect(() => {
        getUser()
    }, [])



    return (
        <div
            className={`flex hide-scrollbar  min-h-screen overflow-auto justify-center items-center w-full ${!isDark ? 'bg-blue-50' : 'dark-mode'
                } py-10`}
        >
            <div ref={animationParent} className={`flex flex-col md:flex-row  ${isDark ? 'bg-slate-400' : 'bg-blue-50'} gap-x-3 h-full w-full p-6 rounded space-y-4 py-5`}>
                <div className=" space-y-6 rounded-lg px-2 mt-4">
                    <div>
                        {userLoad ? <UserProfileLoad /> :
                            <div className="flex flex-col justify-start w-60 items-start border bg-white p-6 rounded-lg shadow-md  space-y-4">
                                <div className="flex w-full justify-between">
                                    <img
                                        src={userAvatar}
                                        alt="User Avatar"
                                        className="w-24 h-24 rounded-full border-4 border-white shadow-md"
                                    />
                                    <Tooltip placement="bottom" title="Update Profile">
                                        <button onClick={() => handleGoToId('/user-profile/update', user && user.userId)} className='inline-flex items-center justify-center  rounded-full h-6 w-6 transition duration-500 ease-in-out text-white bg-gray-500 focus:outline-none'>
                                            <MdOutlineModeEditOutline />
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className="flex flex-col">
                                    <label className="font-semibold text-blue-600">First Name:</label>
                                    {/* <span className="ml-4">{user && user.firstName}</span> */}
                                    <span className="text-gray-500">{user && user.firstName}</span>
                                </div>
                                <div className="flex flex-col">
                                    <label className="font-semibold text-blue-600">Last Name:</label>
                                    {/* <span className="ml-4">{user && user.lastName}</span> */}
                                    <span className="text-gray-500">{user && user.lastName}</span>
                                </div>
                                <div className="flex flex-col">
                                    <label className="font-semibold text-blue-600">Username:</label>
                                    {/* <span className="ml-4">{user && user.username}</span> */}
                                    <span className="text-gray-500">{user && user.username}</span>
                                </div>
                                <div className="flex flex-col">
                                    <label className="font-semibold text-blue-600">Phone Number:</label>
                                    {/* <span className="ml-4">{user && user.phoneNumber}</span> */}
                                    <span className="text-gray-500">{user && user.phoneNumber}</span>
                                </div>
                            </div>}
                    </div>
                </div>

                <LeftSideSection
                    companyLogos={companyLogos}
                    unverified={unverified}
                    investors={investors}
                    logoMap={logoMap}
                    handleServiceProvider={handleServiceProvider}
                    handleProject={handleProject}
                    handleSellCompany={handleSellCompany}
                    openProfile={openProfile}
                    openInvestorProfile={openInvestorProfile}
                    loadingCompany={isLoading}
                    loadUnverified={loadUnverified}
                    handleUnverified={handleUnverified} />
            </div>
            <ResponseModal open={warning} message={warningMessage} onClose={() => setWarning(false)} resultColor={"#FFA500"} icon={<ReportProblemOutlinedIcon style={{ fontSize: 48 }} />} />
        </div>
    )
}

export default UserProfile