import React from 'react'
import { TiTick } from "react-icons/ti";

function PlansMap({features}) {
    return (
        <div>
            {features.map((feature, index) => (
                <ul key={index}>
                   <li className='flex text-gray-600'><TiTick className='mt-1' color='green'/>{feature}</li>
                </ul>
            ))}
        </div>
    )
}

export default PlansMap