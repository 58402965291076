import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { CAPTCHA_SITE_KEY } from '../../../utils/constant'
import Select from 'react-select'

function AuthLast({ formik, reCaptcha, onCaptchaChange, isDark }) {

  const customStyles = {
    control: (base) => ({
      ...base,
      width: '100%',
      minWidth: '200px',
      borderColor: '#6b7280',
      borderRadius: '0.5rem',
      height: '44px',
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
      maxHeight: '300px',
      overflowY: 'auto',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#3b82f6'
        : state.isFocused
          ? '#93c5fd'
          : 'white',
      color: state.isSelected ? 'white' : 'black',
    }),
    placeholder: (base) => ({
      ...base,
      color: '#475569',
      fontSize: '0.875rem',
    }),
  };

  const items = [
    {
      label: 'INVESTOR',
      key: '1',
    },
    {
      label: 'COMPANY',
      key: '2',
    },
    {
      label: 'BOTH',
      key: '3',
    },
  ];

  const optionMaped = items.map((option) => ({
    value: option.label,
    label: option.label
  }))

  return (
    <div >
      <div className='relative w-full mb-4'>
        <input
          type='password'
          id='password'
          placeholder='Enter Strong password'
          className={`w-full p-2 border placeholder:text-sm focus:border-blue-500 focus:border-2 border-gray-400 focus:outline-none placeholder-slate-600 text-black rounded-lg ${!isDark ? 'bg-white' : 'bg-slate-300'} ${formik.touched.password && formik.errors.password ? 'border-2 border-red-600' : ' border-gray-400'}`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}

          style={{
            WebkitTextSecurity: "disc", // Disables the native eye icon
            MozAppearance: "textfield", // For Firefox fallback
          }}
        />
        <label htmlFor='Password' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-white' : 'bg-slate-300'}`}>
          Password <span className='text-red-600 ml-1'>*</span>
        </label>
        {
          formik.touched.password && formik.errors.password ?
            <div className='text-red-600 ml-1 text-sm'>
              {formik.errors.password}
            </div> : null
        }
      </div>

      {/* <div className='relative w-full mb-4'>
        <input
          type='password'
          id='confirmPassword'
          placeholder='Enter Strong password'
          className={`w-full p-2 border placeholder:text-sm focus:border-blue-500 focus:border-2 border-gray-400 focus:outline-none placeholder-slate-600 text-black rounded-lg ${!isDark ? 'bg-white' : 'bg-slate-300'} `}
          style={{
            WebkitTextSecurity: "disc", 
            MozAppearance: "textfield", 
          }}
        />
        <label htmlFor='Password' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-white' : 'bg-slate-300'}`}>
         Confirm Password <span className='text-red-600 ml-1'>*</span>
        </label>
        {
          formik.touched.password && formik.errors.password ?
            <div className='text-red-600 ml-1 text-sm'>
              {formik.errors.password}
            </div> : null
        }
      </div> */}

      <div className='relative mt-1'>
        <Select
          id='userType'
          className={`p-2 w-full focus:outline-none placeholder-slate-600 placeholder:text-sm text-black rounded-lg`}
          options={optionMaped}
          isSearchable={false}
          value={optionMaped.filter((option) =>
            formik.values.userType.includes(option.value)
          )}
          onChange={(selectedOption) => {
            formik.setFieldValue("userType",selectedOption ? selectedOption.value : "");
          }}
          placeholder="Purpose Of Using Inkqubee"
          styles={customStyles}
          menuPortalTarget={document.body}
          menuPosition="absolute"
          menuPlacement="auto"
        />
      </div>

      <div className='justify-center mt-2 h-16  transform scale-90 origin-top-left'>
        <ReCAPTCHA
          sitekey={CAPTCHA_SITE_KEY}
          ref={reCaptcha}
          onChange={(value) => {
            onCaptchaChange(value)
          }}
        />

      </div>
      {
        formik.touched.captcha && formik.errors.captcha ?
          <div className='text-red-600 ml-1 mt-1'>
            {formik.errors.captcha}
          </div> : null
      }
    </div>
  )
}

export default AuthLast