import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { FaFileArrowUp } from "react-icons/fa6";
import { MdAddPhotoAlternate } from "react-icons/md";
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { org_url } from '../../../../utils/constant';
import { uploadLogo } from '../../../../helper/organization/orgRequestCall';

function UploadLogo() {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const location = useLocation();
  const org_id = location.state?.org_id;
  const navigate = useNavigate()

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      setUploadStatus('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await uploadLogo(formData,org_id);

      if (response.status === 200) {
        message.success("uploaded successfully")
        navigate(-1)
      } else {
        message.error("error to upload")
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      // setUploadStatus('Error occurred during image upload.');
    }
  };

  return (
    <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
      <div className={`w-full max-w-4xl max-h-full justify-center space-y-2 rounded-lg border border-gray-700 p-2`}>
        <h2 className="text-xl font-bold mb-4 text-center">Upload Image</h2>
        <form onSubmit={handleUpload} className="flex flex-col space-y-4">
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="file-input file-input-bordered w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Upload
          </button>
        </form>
        {uploadStatus && (
          <p
            className={`mt-4 text-center ${uploadStatus.includes('successfully')
                ? 'text-green-500'
                : 'text-red-500'
              }`}
          >
            {uploadStatus}
          </p>
        )}
      </div>

    </div>
  )
}

export default UploadLogo