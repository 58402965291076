import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { IoMdNotificationsOutline } from "react-icons/io";
import { HiOutlineBell } from "react-icons/hi";
import { private_routes } from '../../routes';
import { Badge, Tooltip } from 'antd';
import { RiMessage2Line } from "react-icons/ri";
import ProfileDropdown from '../../utils/dropdowns/ProfileDropdown';
import userAvatar from "../../assets/userAvatar.jpg"
import { MdPeople } from "react-icons/md";
import { GoBookmarkFill } from "react-icons/go";



function PrivateHeader({ fontSize }) {
  const location = useLocation()
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const activeRoute = private_routes.find(route => route.path === location.pathname);
  const { user } = useSelector((state) => state.authenticationState)
  const [profileDropdown, setProfileDropdown] = useState(false)
  const [openProfile, setOpenProfile] = useState(false)
  const [isPortfolio, setisPortfolio] = useState(false)
  const navigate = useNavigate()
  const portfolioRef = useRef(null)
  const profileDropdownRef = useRef(null)

  const dispatch = useDispatch()

  const handleDropDown = () => {
    setProfileDropdown(prevState => !prevState)
  }

  const handleOutsideClickPortfolio = (event) => {
    if (portfolioRef.current && !portfolioRef.current.contains(event.target)) {
      setisPortfolio(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClickPortfolio)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClickPortfolio)
    }
  }, [])

  const handleOutsideClick = (event) => {
    if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
      setProfileDropdown(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])
  return (
    <header className={`flex flex-col w-full pt-16 mt-2 z-20 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
      <header className='fixed w-full z-50' style={{ fontSize: `${fontSize + 6}px` }}>
        <div className={`flex flex-row  lg:flex-row justify-between p-2 py-2 ${!isDark ? 'bg-white' : 'dark-mode'} px-2 border-b-[1px] border-gray-500/[0.3]`}>
          <div className='flex flex-row justify-between'>
            <div className='flex items-center '>

            </div>
          </div>
          <div className={`lg:flex lg:items-center justify-between text-black`}>
            <span className="flex items-center space-x-5 cursor-pointer">



              <Tooltip placement="bottom" title="Book Marks">
                <button onClick={() => navigate('/bookmarked-Profiles')}>
                  <GoBookmarkFill size={25} className="text-gray-600  hover:text-blue-400" />
                </button>
              </Tooltip>

              <Tooltip placement="bottom" title="Connected Profiles">
                <button onClick={() => navigate('/connected-Profiles')}>
                  <MdPeople size={25} className="text-gray-600  hover:text-blue-400" />
                </button>
              </Tooltip>

              <Tooltip placement="bottom" title="Messages">
                <Badge dot offset={[-5, 4]} className="mt-1">
                  <button onClick={() => navigate('/send-message')} >
                    <RiMessage2Line size={25} className="text-gray-600 hover:text-blue-400" />
                  </button>
                </Badge>
              </Tooltip>

              <Tooltip placement="bottom" title="Notification">
                <Badge dot offset={[-4, 4]} className="mt-1">
                  <button onClick={() => navigate('/notifications')} >
                    <HiOutlineBell size={25} className="text-gray-600 hover:text-blue-400" />
                  </button>
                </Badge>
              </Tooltip>

              <div className="relative" ref={profileDropdownRef}>
                <div
                  className="flex items-center space-x-3 cursor-pointer mt-1"
                  onClick={handleDropDown}
                >
                  <img
                    src={userAvatar}
                    alt="avatar"
                    className="h-9 w-9 rounded-full"
                  />
                </div>
                {profileDropdown && (
                  <ProfileDropdown
                    profileDropdown={profileDropdown}
                    handleOpenProfile={() => setOpenProfile(!openProfile)}
                  />
                )}
              </div>
            </span>
          </div>

        </div>
      </header>
    </header>
  )
}

export default PrivateHeader