// import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import {  getAllCompanies } from '../../../helper/organization/orgRequestCall'

function Review({ formikReview }) {
    const [companies, setCompanies] = useState([])

    const fetchCompanies = () => {
        getAllCompanies("")
            .then((res) => {
                if (res.status === 200) {
                    setCompanies(res.data.data)
                } else {
                    console.log("error")
                }
            })
            .catch((err) => {
                console.error("err", err)
            })

    }

    useEffect(() => {
        fetchCompanies()
    }, [])

    const companyMaped = companies.map((org) => ({
        value: org.companyId,
        label: org.legalEntityName
    }))
    // console.log(companyMaped)
    return (
        <div>
            <h1 className='text-center text-xl text-gray-950 '>Review</h1>
            <form
                onSubmit={formikReview.handleSubmit}
                className='flex flex-col space-y-3 px-5 py-8'
            >
                <div className='relative w-full mb-1'>
                    <input
                        type='text'
                        id='name'
                        name='name'
                        className={`p-2 border w-full focus:outline-none focus:border-blue-500 focus:border-2 placeholder-slate-600 text-black rounded-lg border-1 placeholder:text-sm border-gray-400 `}
                        placeholder='Enter name'
                        onChange={formikReview.handleChange}
                        value={formikReview.values.name}
                        onBlur={formikReview.handleBlur}
                    />
                    <label
                        htmlFor='name'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Reviewer Name
                    </label>
                    {formikReview.touched.name && formikReview.errors.name ?
                        <div className='text-red-700 ml-2 mt-1'>
                            {formikReview.errors.name}
                        </div> : null}
                </div>

                <div className='relative w-full mt-4'>
                    <Select
                        id='company'
                        options={companyMaped}
                        styles={{
                            control: (base) => ({
                                ...base,
                                width: '100%', 
                                minWidth: '100%', 
                                borderColor: '#9ca3af',
                                borderRadius: '0.5rem', 
                                height: '44px', 
                            }),
                            placeholder: (base) => ({
                                ...base,
                                color: '#475569',
                                fontSize: '0.875rem', 
                            }),
                        }}
                        value={companyMaped.filter((option) =>
                            formikReview.values.company.includes(option.value)
                        )}
                        onChange={(selectedOption) => {
                            formikReview.setFieldValue("company", selectedOption ? selectedOption.value : "");
                        }}
                        placeholder="Choose The Company"
                        onBlur={formikReview.handleBlur}
                    />
                    <label
                        htmlFor='company'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Choose Company<span className='text-red-600 ml-1'>*</span>
                    </label>
                    {formikReview.touched.company && formikReview.errors.company ?
                        <div className='text-red-600 ml-3'>
                            {formikReview.errors.company}
                        </div> : null
                    }
                </div>
                <div className='relative w-full mb-4'>
                    <input
                        type='email'
                        id='email'
                        name='email'
                        className={`p-2 border w-full focus:outline-none placeholder:text-sm focus:border-blue-500 focus:border-2 placeholder-slate-600 text-black rounded-lg border-1  border-gray-400 `}
                        placeholder='Enter Email'
                        onChange={formikReview.handleChange}
                        value={formikReview.values.email}
                        onBlur={formikReview.handleBlur}
                    />
                    <label
                        htmlFor='email'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Reviewer Email
                    </label>
                    {formikReview.touched.email && formikReview.errors.email ?
                        <div className='text-red-700 ml-2 mt-1'>
                            {formikReview.errors.email}
                        </div> : null}
                </div>
                <div className='relative w-full mb-4'>
                    <input
                        type='text'
                        id='phone'
                        name='phone'
                        className={`p-2 border w-full focus:outline-none placeholder:text-sm focus:border-blue-500 focus:border-2 placeholder-slate-600 text-black rounded-lg border-1  border-gray-400 `}
                        placeholder='Enter Phone Number'
                        onChange={formikReview.handleChange}
                        value={formikReview.values.phone}
                        onBlur={formikReview.handleBlur}
                    />
                    <label
                        htmlFor='phone'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Reviewer Phone
                    </label>
                    {formikReview.touched.phone && formikReview.errors.phone ?
                        <div className='text-red-700 ml-2 mt-1'>
                            {formikReview.errors.phone}
                        </div> : null}
                </div>
                <div className='relative w-full mb-4'>
                    <textarea
                        id='comment'
                        name='comment'
                        className='p-3 w-full border border-gray-400 placeholder:text-sm focus:border-blue-500 focus:border-2 focus:outline-none placeholder-slate-600 text-black rounded-lg'
                        placeholder='Enter Comment'
                        rows='5'
                        onChange={formikReview.handleChange}
                        value={formikReview.values.comment}
                        onBlur={formikReview.handleBlur}
                    />
                    <label
                        htmlFor='comment'
                        className='absolute top-0 left-4 font-semibold -mt-2 px-1 text-sm transition-all text-black bg-white'
                    >
                        Reviewer Comment
                    </label>
                    {formikReview.touched.comment && formikReview.errors.comment ?
                        <div className='text-red-700 ml-2 mt-1'>
                            {formikReview.errors.comment}
                        </div> : null}
                </div>
                <div className='flex justify-center items-center'>
                    <input
                        type='submit'
                        className='btn max-w-md w-52 ml-2'
                        value='Submit'
                    />
                </div>
            </form>
        </div>
    )
}

export default Review