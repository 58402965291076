import React, { useState } from 'react';
import { ArrowRight, Briefcase, PieChart, Building2, CheckCircle, ArrowUpRight } from 'lucide-react';

const MarketingWebsite = () => {
  const [selectedFeature, setSelectedFeature] = useState('projects');

  const features = {
    projects: {
      title: "Project Marketplace",
      description: "Post requirements or find relevant projects. Connect directly with verified businesses and professionals.",
      stats: ["1000+ Active Projects", "24hr Average Response", "98% Success Rate"]
    },
    investment: {
      title: "Investment Hub",
      description: "Connect with investors or find promising ventures. Streamlined due diligence and secure communication.",
      stats: ["₹100Cr+ Deal Value", "500+ Investors", "200+ Funded Startups"]
    },
    services: {
      title: "Business Services",
      description: "Access comprehensive business services from M&A to talent acquisition. All verified service providers.",
      stats: ["50+ Service Categories", "1000+ Providers", "4.8/5 Avg Rating"]
    }
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-gradient-to-r from-blue-900 to-blue-700 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-6">
              Connect, Grow & Succeed
            </h1>
            <p className="text-xl md:text-2xl mb-8 text-blue-100">
              India's Premier Business Growth Platform
            </p>
            <div className="flex justify-center gap-4">
              <button className="bg-white text-blue-900 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition flex items-center">
                Get Started <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              <button className="border border-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-800 transition">
                Watch Demo
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 text-center">
          <div>
            <p className="text-4xl font-bold text-blue-900">1000+</p>
            <p className="text-gray-600">Registered Businesses</p>
          </div>
          <div>
            <p className="text-4xl font-bold text-blue-900">500+</p>
            <p className="text-gray-600">Active Projects</p>
          </div>
          <div>
            <p className="text-4xl font-bold text-blue-900">₹100Cr+</p>
            <p className="text-gray-600">Deal Value</p>
          </div>
          <div>
            <p className="text-4xl font-bold text-blue-900">98%</p>
            <p className="text-gray-600">User Satisfaction</p>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">Platform Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            <button 
              onClick={() => setSelectedFeature('projects')}
              className={`p-6 rounded-lg text-left transition ${
                selectedFeature === 'projects' ? 'bg-blue-900 text-white' : 'bg-white hover:bg-blue-50'
              }`}
            >
              <Briefcase className="h-8 w-8 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Project Marketplace</h3>
              <p className={selectedFeature === 'projects' ? 'text-blue-100' : 'text-gray-600'}>
                Find opportunities or post requirements
              </p>
            </button>
            <button 
              onClick={() => setSelectedFeature('investment')}
              className={`p-6 rounded-lg text-left transition ${
                selectedFeature === 'investment' ? 'bg-blue-900 text-white' : 'bg-white hover:bg-blue-50'
              }`}
            >
              <PieChart className="h-8 w-8 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Investment Hub</h3>
              <p className={selectedFeature === 'investment' ? 'text-blue-100' : 'text-gray-600'}>
                Connect with investors or find ventures
              </p>
            </button>
            <button 
              onClick={() => setSelectedFeature('services')}
              className={`p-6 rounded-lg text-left transition ${
                selectedFeature === 'services' ? 'bg-blue-900 text-white' : 'bg-white hover:bg-blue-50'
              }`}
            >
              <Building2 className="h-8 w-8 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Business Services</h3>
              <p className={selectedFeature === 'services' ? 'text-blue-100' : 'text-gray-600'}>
                Access comprehensive business solutions
              </p>
            </button>
          </div>
          <div className="bg-white p-8 rounded-lg">
            <h3 className="text-2xl font-bold mb-4">{features[selectedFeature].title}</h3>
            <p className="text-gray-600 mb-6">{features[selectedFeature].description}</p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {features[selectedFeature].stats.map((stat, index) => (
                <div key={index} className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
                  <span>{stat}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {[
            {
              step: "1",
              title: "Register",
              description: "Create your business profile"
            },
            {
              step: "2",
              title: "Verify",
              description: "Complete business verification"
            },
            {
              step: "3",
              title: "Connect",
              description: "Start exploring opportunities"
            },
            {
              step: "4",
              title: "Grow",
              description: "Scale your business"
            }
          ].map((item, index) => (
            <div key={index} className="text-center">
              <div className="bg-blue-100 text-blue-900 w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-4 text-xl font-bold">
                {item.step}
              </div>
              <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
              <p className="text-gray-600">{item.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-blue-900 text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Grow Your Business?</h2>
          <p className="text-xl text-blue-100 mb-8">Join thousands of successful businesses on our platform</p>
          <button className="bg-white text-blue-900 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition flex items-center mx-auto">
            Register Now <ArrowUpRight className="ml-2 h-5 w-5" />
          </button>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-xl font-bold mb-4">Platform</h3>
              <ul className="space-y-2">
                <li>Features</li>
                <li>Pricing</li>
                <li>Security</li>
                <li>Support</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Company</h3>
              <ul className="space-y-2">
                <li>About Us</li>
                <li>Careers</li>
                <li>Blog</li>
                <li>Press</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Resources</h3>
              <ul className="space-y-2">
                <li>Documentation</li>
                <li>Success Stories</li>
                <li>Events</li>
                <li>Newsletter</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Contact</h3>
              <ul className="space-y-2">
                <li>support@platform.com</li>
                <li>+91 XXX XXX XXXX</li>
                <li>Mumbai, India</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MarketingWebsite;
