import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import hiringAlert from '../../../assets/Hiringimage.jpeg'
import linkedin from '../../../assets/linkedin.jpeg'
function FirstSection() {
  const { isDark, fontSize } = useSelector((state) => state.displaySettingsState)
  const navigate = useNavigate()
  return (
    <div className={`flex flex-col w-full md:flex-row h-96 justify-between ${!isDark ? 'light-mode ' : 'dark-mode'} py-6 md:py-11`}>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-7 py-16">
        {/* <marquee className="text-4xl md:text-xl font-bold mb-4 text-white">Ideal for bussiness seeking maximum visibility, reach, and exposure..Lookinng for Projects, Investment or Business ..Contact Us Now</marquee> */}
        <div className="flex text-center">
          {/* <div className='flex flex-col justify-center items-center'>
            <h1 className='mb-2 text-white font-bold'>Follow Us LinkedIn</h1>
            <img
            src={linkedin}
            alt='linkedin'
            className='h-32'
            />
          </div> */}
          <div className='ml-4'>
            <h1 className="text-4xl md:text-6xl font-bold mb-6 text-white">
              Connect, Invest & Grow
            </h1>
            <p className="text-xl md:text-2xl mb-8 text-blue-100">
              India's Premier Business Growth Platform
            </p>
            <div className="flex justify-center gap-1 md:gap-4 lg:gap-4">
              <button onClick={() => navigate('/login')} className="bg-white text-blue-900 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition flex items-center">
                Get Started
              </button>
              <button className="border border-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-800 transition text-white">
                Watch Demo
              </button>
            </div>
          </div>
          {/* <img
          src={hiringAlert}
          className='w-60 h-32 ml-14 mt-4'
          /> */}
        </div>
      </div>
    </div>
  )
}

export default FirstSection