import React from 'react'
import avatar from '../../../assets/userAvatar3.jpg'

function Reviews({ reviews }) {
  return (
    <div className={`flex flex-col h-full p-0 overflow-auto  w-full  py-2 px-3 space-y-2`}>
      {reviews&&reviews.length > 0 ? reviews.map((review) => (
        <div key={review.id} className="flex bg-white shadow-md rounded-lg p-4">
          {/* Left Side: Reviewer Info */}
          <div className="flex-shrink-0">
            <img
              src={avatar}
              alt={review.name}
              className="w-12 h-12 rounded-full border"
            />
          </div>

          {/* Right Side: Comments */}
          <div className="ml-4">
            <h2 className="font-bold text-gray-800">{review.reviewerName}</h2>
            <p className="text-gray-600 mt-1">{review.reviewComment}</p>
          </div>
        </div>
      )) : <div className='flex flex-col justify-center items-center'>
            <span className='mt-6 text-gray-600'>
              No Reviews Found
            </span>
      </div>}
    </div>
  )
}

export default Reviews