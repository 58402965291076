import * as Yup from 'yup'

export const orgRegsiterSchema = Yup.object().shape({
    // companyType: Yup.string().required("Company type required"),
    // CIN: Yup.string().required("CIN required"),
    // // PAN: Yup.string().required("PAN required"),
    // legalEntityName: Yup.string().required("Legal entity name nequired"),
    // officialMailId: Yup.string().email("Invalid email").required("EmailId required"),
    // established: Yup.string().required('Mention the established year'),
    // verifyMailId: Yup.string().oneOf([Yup.ref('officialMailId'), null], "Email doesn't match").required("Email required"),
    // companyBrandName: Yup.string().required("BrandName required"),
    // phoneNo: Yup.string()
    //     .matches(/^\d{10}$/, "Invalid mobile number")
    //     .required("Mobile number is required"),
    addressLine1: Yup.string().required("Address line 1 required"),
    addressLine2: Yup.string().required("Address line 2 required"),
    pinCode: Yup.string().required("Pin code required"),
    // country: Yup.string().required("Country required"),
    // province: Yup.string().required("State name required"),
    // description: Yup.string().required("Business Description required"),
    // city: Yup.string().required("City name required"),
    // companyUrl: Yup.string().url("Wrong url format"),
    // noOfEmployees: Yup.string().required("Select how many employees are there"),
    // Industries: Yup.string().required("Select Industries"),
    privacyPolicy: Yup.bool().oneOf([true], 'Accept the privacy policy'),
    termsAndCondition: Yup.bool().oneOf([true], 'Accept the terms and condition'),
    refundPolicy: Yup.bool().oneOf([true], 'accept the refund policy'),
    LinkedIn: Yup.string().url("Wrong url format"),
    faceBook: Yup.string().url("Wrong url format"),
    instagram: Yup.string().url("Wrong url format"),
    xMedia: Yup.string().url("Wrong url format"),
    // description: Yup.string().required("Write a description")
})

export const reviewSchema = Yup.object().shape({
    name: Yup.string().required("Enter Your Name"),
    company: Yup.string().required("Select Company"),
    email: Yup.string().required("Mention Email"),
    phone: Yup.string()
        .matches(/^\d{10}$/, "Invalid mobile number")
        .required("Mobile number is required"),
    comment: Yup.string().required("Select Industries")
})

export const orgEditSchema = Yup.object().shape({
    CIN: Yup.string().required("CIN Required"),
    PAN: Yup.string().required("PAN Required"),
    legalEntityName: Yup.string().required("Legal Entity Name Required"),
    officialMailId: Yup.string().email("Invalid email").required("Email required"),
    // verifyMailId: Yup.string().oneOf([Yup.ref('officialMailId'), null], "Email doesn't match").required("Email required"),
    companyBrandName: Yup.string().required("company or BrandName required"),
    location: Yup.string().required("location required"),
    noOfEmployees: Yup.string().required("mention how many emplotyees are there")
})

export const signUpSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, "Too short")
        .max(50, "Too long")
        .required("First name required"),
    lastName: Yup.string()
        .required("Last name required"),
    email: Yup.string()
        .email("Invalid email")
        .required("Email required"),
    phoneNumber: Yup.string()
        .matches(/^\d{10}$/, "Invalid mobile number")
        .required("Mobile number is required"),
    addressLine1: Yup.string()
        .required("Mention Address Line 1"),
    addressLine2: Yup.string()
        .required("Mention Address Line 2"),
    postalCode: Yup.string()
        .required("Mention Postal Code"),
    // country: Yup.string()
    //     .required("Mention Country"),
    // state: Yup.string()
    //     .required("Mention State"),
    // city: Yup.string()
    //     .required("Mention City"),
    password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .max(20, "Password must be maximum 20 characters")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[0-9]/, "Password must contain at least one digit")
        .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character")
        .required("Secure your account with password!"),
    captcha: Yup.string().required('Captcha validation is required'),
    terms: Yup.bool().oneOf([true], 'accept the terms and conditions'),
})

//login validation
export const loginSchema = Yup.object().shape({
    userName: Yup.string()
        .email("Invalid email")
        .required("Email is required"),
    password: Yup.string()
        .required("Password required")
})

export const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email("invalid email")
        .required("email is required"),
    newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .max(20, "Password must be maximum 20 characters")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[0-9]/, "Password must contain at least one digit")
        .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character")
        .required("Password required")
})

export const portfolioCreateSchema = Yup.object().shape({
    // companyId: Yup.string()
    //     .required("Choose the company"),
    service: Yup.string().required('Choose the services')
})

export const portfolioUpdateSchema = Yup.object().shape({

    service: Yup.string()
        .required("service required")
})

export const courceContactSchema = Yup.object().shape({
    name: Yup.string()
        .required("Name required"),
    mobileNumber: Yup.string()
        .matches(/^[0-9]{10}$/, "please enter correct mobile number")
        .required('Mobile number required')
})

export const addUsersSchema = Yup.object().shape({
    userName: Yup.string()
        .required("user name required")
})

export const projectValidation = Yup.object().shape({
    projectTitle: Yup.string()
        .required("Name required"),
    projectDescription: Yup.string()
        .required("Name required"),
    objectiveAndGoals: Yup.string()
        .required("Name required"),
    projectTimeLine: Yup.string()
        .required("Name required"),
    budgetRange: Yup.string()
        .required("Name required"),
    skillsRequired: Yup.string()
        .required("Name required"),
    // numberOfResources: 0,
    preferredSubmissionFormat: Yup.string()
        .required("Name required"),
    deadline: Yup.string()
        .required("Name required"),
})

export const investmentRegister = Yup.object().shape({
    firstName: Yup.string()
        .required("First name required"),
    lastName: Yup.string()
        .required("First name required"),
    addressLine1: Yup.string()
        .required("Address Line 1 required"),
    addressLine2: Yup.string()
        .required("Address Line 2 required"),
    pinCode: Yup.string()
        .required("Pincode required"),
    country: Yup.string()
    .required("Country required"),
    state: Yup.string()
    .required("State required"),
    city: Yup.string()
    .required("City required"),
    phone: Yup.string()
        .required("Phone number required"),
    email: Yup.string()
        .email("Invalid Email")
        .required("Email ID Required"),
    profile: Yup.string()
        .required("Provide the profile detail"),
    // investmentCapacity:Yup.string()
    // .required("Mention investment capacity"),
    // adharCardNumber:Yup.string()
    // .matches(/^\d{12}$/, "Aadhar must be a 12-digit number"),
    panNumber: Yup.string()
        .required("PAN Number required"),
    // incomeProof: Yup.mixed()
    //     // .required('A file is required')
    //     .test('fileFormat', 'Unsupported file type (only PDF or Word)', (value) => {
    //         return value && ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type);
    //     }),
    // typesOfInvestment: Yup.array()
    //     .of(Yup.string().required("Invalid value"))
    //     .max(5, "You can select up to 5 types only.")
    //     .min(1, "Please select at least one Types.")
    //     .nullable(),
    typesOfInvestment: Yup.string()
        .required("Type Required"),
    industryPreference: Yup.array()
        .of(Yup.string().required("Invalid value"))
        .max(5, "You can select up to 5 industries only.")
        .min(1, "Please select at least one industry.")
        .nullable(),
    locationPreference: Yup.array()
        .of(Yup.string().required("Invalid value"))
        .max(5, "You can select up to 5 locations only.")
        .min(1, "Please select at least one location.")
        .nullable(),
})
