import React from 'react'
import { TiMessages } from "react-icons/ti";
import { IoIosRemoveCircle } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
function Organization({service_providers}) {
    const navigate=useNavigate()
    const handleSendMessage=(person)=>{
        navigate('/send-message',{
            state:{
                personId:person.id,
                personName:person.legalEntityName,
                dp:person.logo
            }
        })
    }
  return (
    <div>
        {service_providers.map((items, index) => (
                <div key={index} className='flex gap-5 mt-2 border rounded-lg p-3 '>
                    <img
                        src={items.logo}
                        alt='logo'
                        className='h-14 w-14 rounded-full'
                    />
                    <div className='flex flex-col mt-2'>
                        <span className='text-gray-700'>{items.legalEntityName}</span>
                        <span className='text-gray-400'>{items.location}</span>
                    </div>
                    <div className='flex mt-6  justify-between ml-auto'>
                        <button className='flex bg-blue-800 text-white px-4 py-1 rounded mb-2 me-2 h-8' onClick={()=>handleSendMessage(items)}>Message<TiMessages className='mt-1 ml-1'/></button>
                        <button className='flex bg-blue-800 text-white px-4 py-1 rounded h-8'>Remove<IoIosRemoveCircle className='mt-1 ml-1'/></button>
                    </div>  
                </div>
            ))}
    </div>
  )
}

export default Organization