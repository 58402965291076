import React, {  useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Alert, message, Spin } from 'antd';
import { useRegisterForm } from '../../../helper/auth/authFormHook';
import { signupUser } from '../../../helper/auth/authRequestCall';
import { TiTick } from "react-icons/ti";
import TermsAndConditionModal from '../../../utils/Modal/TermsAndConditionModal';
import PrivacyPolicyModal from '../../../utils/Modal/PrivacyPolicyModal';
import { getCities, getCountries, getProvince } from '../../../helper/organization/orgRequestCall';
// import TermsAndConditionModal from '../../../utils/Modal/TermsAndConditionModal';
import awsStartup from '../../../assets/aws-startup.jpeg'
import AuthFirst from '../../../components/public/auth/AuthFirst';
import AuthSecond from '../../../components/public/auth/AuthSecond';
import AuthLast from '../../../components/public/auth/AuthLast';
import { IoIosArrowBack } from 'react-icons/io';
import { MdNavigateNext } from 'react-icons/md';

function Register() {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const [registered, setRegistered] = useState(false)
  const [isWarning, setWarning] = useState(false)
  const [registerdSteps, setRegisterdSteps] = useState(0)
  const [loading, setLoading] = useState(false)
  const [OpenTerms, setOpenTerms] = useState(false)
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false)
  const [countries, setCountries] = useState([])
  const [province, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const reCaptcha = useRef(null)
  const navigate = useNavigate()

  const formTitles = ['First', 'Second', 'last']


  const countryMaped = countries.map((country) => ({
    value: country.countryId,
    label: country.name
  }))

  const provinceMaped = province.map((state) => ({
    value: state.provinceId,
    label: state.name
  }))

  const cityMaped = cities.map((city) => ({
    value: city.cityId,
    label: city.name
  }))


  const handleSubmit = (values) => {
    setLoading(true)
    const firstName = values.firstName
    const lastName = values.lastName
    const email = values.email
    const password = values.password
    const userType=values.userType 

    const payload = {
      emailId: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: values.phoneNumber,
      address: {
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        postalCode: values.postalCode,
        country: {
          id: values.country
        },
        state: {
          id: values.state
        },
        city: {
          id: values.city
        }
      },
      userType:userType
    }
    // const captcha = values.captcha

    signupUser(payload)
      .then((data) => {
        if (data && data.status === 201) {
          formik.resetForm()
          if (reCaptcha.current) {
            reCaptcha.current.reset()
          }
          setRegistered(true)
        } else {
          message.error("registration failed")
        }
      })
      .catch((err) => {
        console.error("error", err)
      })
      .finally(() => {
        setLoading(false)
        // setRegistered(false)
      })

  }

  const formik = useRegisterForm(handleSubmit)

  const pageDisplay = () => {
    if (registerdSteps === 0) {
      return <AuthFirst formik={formik} isDark={isDark} />
    } else if (registerdSteps === 1) {
      return <AuthSecond formik={formik} isDark={isDark} countries={countryMaped} province={provinceMaped} cities={cityMaped} />
    } else {
      return <AuthLast formik={formik} isDark={isDark} reCaptcha={reCaptcha} onCaptchaChange={onCaptchaChange} />
    }
  }
  useEffect(() => {
    const fetchCountry = () => {
      getCountries()
        .then((res) => {
          if (res.status === 200) {
            setCountries(res.data)
          }
          // console.log("res",res.data)
        })
        .catch((err) => console.error("Error", err))
    }
    fetchCountry()
  }, [])

  useEffect(() => {
    const fetchProvince = () => {
      if (formik.values.country) {
        getProvince(formik.values.country)
          .then((res) => {
            if (res.status === 200) {
              setProvinces(res.data)
            }
          })
          .catch((error) => console.error("Error fetching provinces:", error));
      }
    }
    fetchProvince()
  }, [formik.values.country])

  useEffect(() => {
    const fetchCity = () => {
      if (formik.values.state) {
        getCities(formik.values.state)
          .then((res) => {
            if (res.status === 200) {
              setCities(res.data)
            }
          })
          .catch((error) => console.error("Error fetching cities:", error));
      }
    }
    fetchCity()
  }, [formik.values.state])

  useEffect(() => {
    let timeout;
    if (reCaptcha.current) {
      timeout = setTimeout(() => {
        reCaptcha.current.reset()
        // console.log("recaptcha is expired please complete it again")
      }, 60000)
    }
    return () => clearTimeout(timeout)
  }, [])





  const onCaptchaChange = (value) => {
    formik.setFieldValue('captcha', value)
  };

  const handleNextStep = () => {
    const errors = formik.errors;
    // console.log(errors)
    // console.log(Object.keys(errors).length)
    if (registerdSteps === 0 && formik.values.firstName && !errors.firstName && !errors.lastName && !errors.email && !errors.phoneNumber) {
      setRegisterdSteps((prev) => prev + 1);
      setWarning(false)
    } else if (registerdSteps === 1
      && !errors.addressLine1 && !errors.addressLine2 && !errors.postalCode
    ) {
      setWarning(false)
      setRegisterdSteps((prev) => prev + 1);
    } else {
      setWarning(true)
    }
  }


  const handleRegister = () => {
    if (registerdSteps === formTitles.length - 1) {
      formik.handleSubmit()
    } else {
      handleNextStep()
    }
  }


  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      <div className={`flex flex-col justify-center items-center md:w-1/2 w-full ${!isDark ? 'bg-white' : 'dark-mode'} p-4`}>
        <div className="flex p-4 md:p-20 w-full">
          <div className="flex-1 bg-white py-6 md:py-10 flex flex-col justify-center items-center">
            {registered && (
              <Alert
                message="Registered successfully"
                description="Please check your mail and activate your account.."
                className="mb-2"
                type="success"
                closable
              />
            )}
            <div className="flex flex-col w-full px-4 md:px-5 py-6 md:py-7 border mt-10 md:mt-0 lg:mt-0">
              {isWarning && (
                <Alert
                  message="Fill in all the fields before moving to the next step...."
                  className="mb-2"
                  type="warning"
                  closable
                />
              )}
              <div className="flex flex-col justify-between px-4 md:px-5 py-2">
                <h1 className="text-black text-xl md:text-2xl font-bold text-center mb-4 p-2">Register</h1>
                {pageDisplay()}
              </div>
              <div className="flex justify-between mt-5 border-b p-4">
                {registerdSteps === 0 ? (
                  <button
                    className="flex btn"
                    onClick={() => navigate(-1)}
                  >
                    <IoIosArrowBack className="mt-0.5" /> Back
                  </button>
                ) : (
                  <button
                    className="flex btn"
                    onClick={() => setRegisterdSteps((curSteps) => curSteps - 1)}
                  >
                    <IoIosArrowBack className="mt-0.5" /> Back
                  </button>
                )}
                <button
                  type="submit"
                  className="btn ml-auto"
                  onClick={handleRegister}
                >
                  {!loading ? (
                    registerdSteps === formTitles.length - 1 ? (
                      "Sign Up"
                    ) : (
                      <div className="flex">
                        Next <MdNavigateNext className="mt-1" />
                      </div>
                    )
                  ) : (
                    <>Registering...<Spin className="custom-spinner" /></>
                  )}
                </button>
              </div>
              <div className="flex justify-between p-2 text-center mt-2">
                <span className="text-gray-500 text-sm">Already have an account?</span>
                <Link to="/login" className="text-blue-700 underline text-sm">Sign In</Link>
              </div>
              {registerdSteps === 2 && (
                <div className="mt-2">
                  <label className="flex font-semibold cursor-pointer">
                    <input
                      type="checkbox"
                      name="terms"
                      className="hidden peer"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.terms}
                    />
                    <div
                      className={`w-4 h-4 border-2 ${formik.touched.terms && formik.errors.terms
                        ? 'border-red-600'
                        : 'border-gray-700'
                        } mt-1 bg-white peer-checked:border-blue-700`}
                    >
                      {formik.values.terms && <TiTick className="h-3 w-3" color="black" />}
                    </div>
                    <span className="ml-2 mt-1 text-gray-600 text-sm">
                      I agree to the{' '}
                      <button
                        type="button"
                        className="text-blue-950 underline"
                        onClick={() => setOpenTerms(true)}
                      >
                        Terms and Conditions
                      </button>{' '}
                      and{' '}
                      <button
                        type="button"
                        className="text-blue-950 underline"
                        onClick={() => setOpenPrivacyPolicy(true)}
                      >
                        Privacy Policy
                      </button>.
                    </span>
                  </label>
                  {formik.touched.terms && formik.errors.terms && (
                    <div className="text-red-600 ml-1 mt-1 text-sm">
                      {formik.errors.terms}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <TermsAndConditionModal open={OpenTerms} handleClose={() => setOpenTerms(false)} />
        <PrivacyPolicyModal open={openPrivacyPolicy} handleClose={() => setOpenPrivacyPolicy(false)} />
      </div>
      <div className="flex-1 bg-blue-700 rounded-l-[170px] mt-6 text-white justify-center items-center p-8 hidden md:flex lg:flex flex-col w-full min-h-screen">
        <h2 className="text-5xl font-bold mb-4    relative">  inkqubee<span className='absolute text-sm text-white top-0 '>™</span></h2>
        <p className="text-lg ml-2">LEARN.CONNECT.INVEST</p>
        <div className='flex justify-center items-center p-5'>
          <img
            src={awsStartup}
            alt='aws startup'
            className=' w-56'
          />
        </div>
      </div>
    </div>

  )
}

export default Register
