import React from 'react'

function AuthFirst({ formik, isDark }) {
    return (
        <div className=''>
            <div className='flex gap-2'>
                <div className='relative w-full mb-4'>
                    <input
                        type='text'
                        placeholder='Enter your first name'
                        id='firstName'
                        className={`w-full p-2 border placeholder:text-sm focus:border-blue-500 focus:border-2 border-gray-200 focus:outline-none placeholder-slate-600 text-black rounded-lg  ${formik.touched.firstName && formik.errors.firstName ? 'border-2 border-red-600' : ' border-gray-400'}`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}

                    />
                    <label htmlFor='firstName' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-white' : 'bg-slate-300'}`}>
                        First Name <span className='text-red-600 ml-1'>*</span>
                    </label>
                    {
                        formik.touched.firstName && formik.errors.firstName ?
                            <div className='text-red-600 ml-1'>
                                {formik.errors.firstName}
                            </div> : null
                    }
                </div>
                <div className='relative w-full mb-4'>
                    <input
                        type='text'
                        placeholder='Enter your last name'
                        id='lastName'
                        className={`w-full p-2 border placeholder:text-sm focus:border-blue-500 focus:border-2 border-gray-200 focus:outline-none placeholder-slate-600 text-black rounded-lg ${!isDark ? 'bg-white' : 'bg-slate-300'} ${formik.touched.lastName && formik.errors.lastName ? 'border-2 border-red-600' : ' border-gray-400'}`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                    />
                    <label htmlFor='LastName' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-white' : 'bg-slate-300'}`}>
                        Last Name <span className='text-red-600 ml-1'>*</span>
                    </label>
                    {
                        formik.touched.lastName && formik.errors.lastName ?
                            <div className='text-red-600 ml-1'>
                                {formik.errors.lastName}
                            </div> : null
                    }
                </div>
            </div>
            <div className='relative w-full mb-4'>
                <input
                    type='email'
                    id='email'
                    placeholder='Enter your email'
                    className={`w-full p-2 border placeholder:text-sm focus:border-blue-500 focus:border-2 border-gray-200 focus:outline-none placeholder-slate-600 text-black rounded-lg ${!isDark ? 'bg-white' : 'bg-slate-300'} ${formik.touched.email && formik.errors.email ? 'border-2 border-red-600' : ' border-gray-400'}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                />
                <label htmlFor='Email' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-white' : 'bg-slate-300'}`}>
                    Email <span className='text-red-600 ml-1'>*</span>
                </label>
                {
                    formik.touched.email && formik.errors.email ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.email}
                        </div> : null
                }
            </div>
            <div className='relative w-full mb-4'>
                <input
                    type='text'
                    id='phoneNumber'
                    placeholder='123-456-7890'
                    className={`w-full p-2 border placeholder:text-sm focus:border-blue-500 focus:border-2 border-gray-200 focus:outline-none placeholder-slate-600 text-black rounded-lg ${!isDark ? 'bg-white' : 'bg-slate-300'} ${formik.touched.phoneNumber && formik.errors.phoneNumber ? 'border-2 border-red-600' : ' border-gray-400'}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                />
                <label htmlFor='Email' className={`absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black ${!isDark ? 'bg-white' : 'bg-slate-300'}`}>
                    Phone Number <span className='text-red-600 ml-1'>*</span>
                </label>
                {
                    formik.touched.phoneNumber && formik.errors.phoneNumber ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.phoneNumber}
                        </div> : null
                }
            </div>
        </div>
    )
}

export default AuthFirst