import React from 'react'
import Select from 'react-select'

function SelectBox({handleBlur,arrayValues,placeholder,inputArgs,handleChange,formikValue,label,required}) {
    const customStyles = {
        control: (base) => ({
            ...base,
            width: "100%", // Matches the parent width
            minWidth: "250px", // Ensures a minimum width
            maxWidth: "400px", // Optional: Sets a maximum width
        }),
        menu: (base) => ({
            ...base,
            width: "100%",
            minWidth: "250px",
        }),
        control: (base) => ({
            ...base,
            width: '100%',
            minWidth: '100%',
            borderRadius: '0.5rem',
            borderColor: '#9ca3af',
            height: '44px',
        }),
        placeholder: (base) => ({
            ...base,
            color: '#475569',
            fontSize: '0.875rem',
        }),
    };

    return (
        <div className='relative w-[250px] mt-4'>
            <Select
                id={inputArgs}
                // className={`p-2 w-full focus:outline-none placeholder-slate-600 text-black rounded-lg placeholder:text-sm `}
                options={arrayValues}
                value={formikValue}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={placeholder}
                styles={customStyles}
            />
            <label
                htmlFor={inputArgs}
                className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
            >
                {label} {required &&<span className='text-red-600 ml-1'>*</span>}
            </label>
        </div>
    )
}

export default SelectBox