import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import userAvatar from '../../../assets/userAvatar3.jpg'
import { countryCodes } from '../../../utils/tempJson/_optionsJson';
import { getUserProfile } from '../../../helper/auth/authRequestCall';
// import { userProfileFormHook } from '../../../helper/auth/authFormHook';

function UserUpdate() {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const [avatar, setAvatar] = useState(userAvatar)
  const [user, setUser] = useState([])
  const [phoneNUmber,setPhoneNumber]=useState("")
  const [address1,setAddress1]=useState("")
  const [address2,setAddress2]=useState("")
  const [postalCode,setPostalCode]=useState("")
  const [country,setCountry]=useState("")
  const [state,setState]=useState("")
  const [city,setCity]=useState("")
  const navigate = useNavigate()
  const location = useLocation();
  const userId = location.state?.user_Id;
  console.log("user",userId)

  const handleSubmit = (values) => {
    console.log("values", values)
  }
  const updateAvatar = (e) => {
    const file = e.target.files[0]
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      setAvatar(imageUrl)
    }
  }

  const getUser = () => {
    getUserProfile(userId)
      .then(res => {
        const userProfile = res.data.data
        setPhoneNumber(userProfile.phoneNumber)
      })
      .catch(err => {
        console.error(err)
      })
  }

  

  // const formik = userProfileFormHook(handleSubmit)

  useEffect(() => {
    getUser()
  }, [])

  return (
    <div className={`flex flex-col min-h-screen justify-center items-center w-full pt-5 ${!isDark ? 'bg-white' : 'dark-mode'}`}>
      <div className={`w-full max-w-4xl max-h-full justify-center space-y-2 rounded-lg border border-gray-700 p-2`}>
        <h1 className='text-center text-gray-600'>Update Profile</h1>
        {/* <ServiceProviderStep /> */}
        <form
          className='flex flex-col justify-center items-center space-y-3 px-5 py-8'
          
        >
          <div className='flex justify-center items-center'>
            <div className="relative w-24 h-24">
              <img
                src={avatar}
                alt="User Avatar"
                className="w-full h-full rounded-full object-cover"
              />
              <label
                htmlFor="imageUpload"
                className="absolute bottom-0 right-0 bg-blue-500 text-white rounded-full p-2 cursor-pointer"
                style={{ transform: "translate(25%, 25%)" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 2.487a2.25 2.25 0 113.182 3.182L7.073 18.64a4.5 4.5 0 01-1.591 1.06l-3.147 1.152 1.152-3.147a4.5 4.5 0 011.06-1.591l12.596-12.596z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 6.75L17.25 13.5"
                  />
                </svg>
              </label>
              <input
                id="imageUpload"
                type="file"
                accept="image/*"
                onChange={updateAvatar}
                className="hidden"
              />
            </div>
          </div>

          <div class={`flex relative mb-4 rounded-md w-96 bg-white outline outline-1 -outline-offset-1  outline-gray-400  has-[input:focus-within]:outline has-[input:focus-within]:outline-1 has-[input:focus-within]:-outline-offset-2 has-[input:focus-within]:outline-indigo-600`}>
            <input
              type="text"
              name="phoneNUmber"
              value={phoneNUmber}
              id="phoneNUmber"
              class="block min-w-0 grow py-1.5 pl-1 pr-3 text-base text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6 "
              placeholder="123-456-7890"
            />
            <label
              htmlFor='phoneNUmber'
              className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
            >
              Phone<span className='text-red-600 ml-1'>*</span>
            </label>
          </div>

          {/* <div className='relative w-96'>
            <input
              type='text'
              id='location'
              className={`p-2 border w-96 focus:outline-none placeholder-slate-600 text-black rounded-lg border-1  border-gray-400 `}
              placeholder='Enter Location'
            />
            <label
              htmlFor='location'
              className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
            >
              Location
            </label>
          </div> */}


          <div className='flex justify-center items-center px-9'>
            <button
              onClick={() => navigate(-1)}
              type='button'
              className='btn w-16'
            >Back</button>
            <button
              type='submit'
              className='btn  ml-2'
            >Submit</button>
          </div>
        </form>

      </div>
    </div>
  )
}

export default UserUpdate