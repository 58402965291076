import React from 'react'
import { notVerrified_orgs, service_providers } from '../../../utils/tempJson/_tempItems'
import verificationImage from '../../../assets/UnderVerification.webp'
import { MdPending } from "react-icons/md";
import { FaArrowRight } from 'react-icons/fa'
import { Tooltip } from 'antd'
import CompanyProfileLoad from '../../../utils/loadingEffect/CompanyProfileLoad';

function NotAprovedCompanies({ openProfile ,unverified,loadUnverified}) {
    return (
        <div className='grid md:grid-cols-3 lg:grid-cols-3 scrollable-container bg-blue-50 h-full rounded-md gap-4 hide-scrollbar px-3 py-2 '>
            {
            loadUnverified
            ? Array(1)
                .fill(0)
                .map((d, i) => <CompanyProfileLoad key={i} />)
            :
            unverified && unverified.map((item) => (
                <div className='flex flex-col justify-center items-center w-full h-48 border shadow-md md:p-2  rounded-md bg-white'>
                    <img
                        // src={logoMap[item.companyId] || defaultLogo}
                        src={verificationImage}
                        alt={item.legalEntityName}
                        // onClick={handleCompanyDetail}
                        onClick={() => openProfile(item.companyId)}
                        className={` rounded-full h-16 w-16 bg-white cursor-pointer`}
                    />
                    <div className='flex flex-col justify-center items-center mt-2'>
                        <h1 className='flex text-center text-xl text-blue-600'>
                            {item.legalEntityName}
                            <Tooltip title="Under Verification" >
                                <span className='mt-1.5 ml-1 text-yellow-600 cursor-pointer'>
                                    <MdPending />
                                </span>
                            </Tooltip>
                        </h1>
                        <span className='text-gray-400'>{item.location}</span>
                    </div>
                    {/* <button 
                    type='button' 
                    className='flex justify-between p-2 items-center w-full border text-gray-700 mt-2'
                    onClick={()=>handleUnverified(item.legalEntityName)}>
                        Get Started <FaArrowRight />
                    </button> */}
                     <button 
                    type='button' 
                    className='flex justify-between p-2 items-center w-full border text-gray-700 mt-2'
                    onClick={() => openProfile(item.companyId)}>
                        View Profile <FaArrowRight />
                    </button>
                </div>
            ))
            }
        </div >
    )
}

export default NotAprovedCompanies