import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import userAvatar from '../../../../assets/userAvatar3.jpg'
import { CiSearch } from "react-icons/ci";
import { FaSearch } from "react-icons/fa";
import { CiHeart } from "react-icons/ci";
import { BsSendFill } from "react-icons/bs";
import EmojiPicker from 'emoji-picker-react';
import { FaRegSmile } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { SiTicktick } from "react-icons/si";
import { useLocation } from 'react-router-dom';

function SendMessage() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const isTyping=false
    const [msgInput, setMsgInput] = useState("")
    const [allChats, setMessages] = useState([
        {

            userId: "user1",
            name: "John",
            image: "https://via.placeholder.com/50",
            messages: [
                { id: 1, text: "Hello, how are you?", sender: "user1", timestamp: "2024-12-19T10:45:23Z" },
                { id: 2, text: "I'm good, thanks!", sender: "user2", timestamp: "2024-12-19T10:46:30Z" }
            ]
        },
        {
            userId: "user2",
            name: "Alice",
            image: "https://via.placeholder.com/50",
            messages: [
                { id: 1, text: "Hey, are you free tomorrow?", sender: "user2", timestamp: "2024-12-19T11:00:00Z" },
                { id: 2, text: "Yes, I am!", sender: "user1", timestamp: "2024-12-19T11:05:00Z" }
            ]
        }
        ,
        {
            userId: "user3",
            name: "Mathiw",
            image: "https://via.placeholder.com/50",
            messages: [
                { id: 1, text: "Hey, are you free tomorrow?", sender: "user2", timestamp: "2024-12-19T11:00:00Z" },
                { id: 2, text: "Yes, I am!", sender: "user1", timestamp: "2024-12-19T11:05:00Z" }
            ]
        },
        {
            userId: "user4",
            name: "Antony",
            image: "https://via.placeholder.com/50",
            messages: [
                { id: 1, text: "Hey, are you free tomorrow?", sender: "user2", timestamp: "2024-12-19T11:00:00Z" },
                { id: 2, text: "Yes, I am!", sender: "user1", timestamp: "2024-12-19T11:05:00Z" }
            ]
        },
        {
            userId: "user5",
            name: "Mukesh",
            image: "https://via.placeholder.com/50",
            messages: [
                { id: 1, text: "Hey, are you free tomorrow?", sender: "user2", timestamp: "2024-12-19T11:00:00Z" },
                { id: 2, text: "Yes, I am!", sender: "user1", timestamp: "2024-12-19T11:05:00Z" }
            ]
        },
        {
            userId: "user6",
            name: "Jhony",
            image: "https://via.placeholder.com/50",
            messages: [
                { id: 1, text: "What u do?", sender: "user2", timestamp: "2024-12-19T11:00:00Z" },
                { id: 2, text: "Nothing!", sender: "user1", timestamp: "2024-12-19T11:05:00Z" }
            ]
        },
        {
            userId: "user7",
            name: "Nandini",
            image: "https://via.placeholder.com/50",
            messages: [
                { id: 1, text: "Hey, are you free tomorrow?", sender: "user2", timestamp: "2024-12-19T11:00:00Z" },
                { id: 2, text: "Yes, I am!", sender: "user1", timestamp: "2024-12-19T11:05:00Z" }
            ]
        },
        {
            userId: "user8",
            name: "Vignesh",
            image: "https://via.placeholder.com/50",
            messages: [
                { id: 1, text: "Hey, are you free tomorrow?", sender: "user2", timestamp: "2024-12-19T11:00:00Z" },
                { id: 2, text: "Yes, I am!", sender: "user1", timestamp: "2024-12-19T11:05:00Z" }
            ]
        },
        {
            userId: "user9",
            name: "Alice",
            image: "https://via.placeholder.com/50",
            messages: [
                { id: 1, text: "Hey, are you free tomorrow?", sender: "user2", timestamp: "2024-12-19T11:00:00Z" },
                { id: 2, text: "Yes, I am!", sender: "user1", timestamp: "2024-12-19T11:05:00Z" }
            ]
        },
        {
            userId: "user10",
            name: "anjali",
            image: "https://via.placeholder.com/50",
            messages: [
                { id: 1, text: "Hey, are you free tomorrow?", sender: "user2", timestamp: "2024-12-19T11:00:00Z" },
                { id: 2, text: "Yes, I am!", sender: "user1", timestamp: "2024-12-19T11:05:00Z" }
            ]
        },
        {
            userId: "user11",
            name: "Alice",
            image: "https://via.placeholder.com/50",
            messages: [
                { id: 1, text: "Hey, are you free tomorrow?", sender: "user2", timestamp: "2024-12-19T11:00:00Z" },
                { id: 2, text: "Yes, I am!", sender: "user1", timestamp: "2024-12-19T11:05:00Z" }
            ]
        },
        {
            userId: "user12",
            name: "Alice",
            image: "https://via.placeholder.com/50",
            messages: [
                { id: 1, text: "Hey, are you free tomorrow?", sender: "user2", timestamp: "2024-12-19T11:00:00Z" },
                { id: 2, text: "Yes, I am!", sender: "user1", timestamp: "2024-12-19T11:05:00Z" }
            ]
        }
    ]);

    const getLastMessage = (userName) => {
        const userChat = allChats.find(chat => chat.name === userName);
        if (userChat) {
            return userChat.messages && [userChat?.messages.length - 1].text || ''
        }
        return null;
    };
    const [newMessage, setNewMessage] = useState("");
    const [showEmojiPick, setEmojiPick] = useState(false)
    const [rows, setRows] = useState(1);
    const [selectedUser, setSelectedUser] = useState(null);

    const location = useLocation()
    const userName = location.state?.personName || null
    const userDp = location.state?.dp || "https://via.placeholder.com/50"
    const personId = location.state?.personId || null

    useEffect(() => {
        setSelectedUser(personId)
    }, [])

    useEffect(() => {
        setMessages((prev) => [...prev, {
            userId: personId,
            name: userName,
            image: userDp,
            messages: []
        }])
    }, [personId, userName, userDp])

    const sendMessage = () => {
        if (msgInput.trim()) {
            const newMessage = {
                id: selectedUserData.messages?.length + 1,
                text: msgInput,
                sender: "user1",
                timestamp: new Date().toISOString(),
            };

            const updatedChatData = allChats.map((chat) => {
                if (chat.userId === selectedUser) {
                    return {
                        ...chat,
                        messages: [...chat.messages, newMessage],
                    };
                }
                return chat;
            });
            setMessages(updatedChatData)
            setMsgInput("")
            setRows(1)
        }
    };

    const onEmojiClick = (emojiObject) => {
        setMsgInput((prevInput) => {
            const updatedInput = prevInput + emojiObject.emoji

            const lineBreaks = updatedInput.split("\n").length
            const newRows = Math.min(lineBreaks, 5)
            setRows(newRows)

            return updatedInput
        });
    };


    const handleInputChange = (e) => {
        const inputValue = e.target.value
        setMsgInput(inputValue)

        const lineBreaks = inputValue.split("\n").length
        const newRows = Math.min(lineBreaks, 5)
        setRows(newRows)
    }

    const handleUserClick = (userId) => {
        setSelectedUser(userId);
        console.log(selectedUser)
    };

    const selectedUserData = allChats?.find((chat) => chat.userId === selectedUser) || [];

    return (
        <div className={`hide-scrollbar flex flex-col min-h-screen overflow-auto w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-10 px-3`}>
            <div>
                <div className='relative min-h-screen flex flex-col bg-gray-50'>
                    {/* <div className='flex-shrink-0 bg-blue-600'>
                        <div className='max-w-7xl mx-auto px-2 sm:px-4 lg:px-8'>
                            <div className='relative flex items-center justify-between h-16'>
                                <div></div>
                                <div className='flex lg:hidden'>
                                    <button className='bg-blue-700 inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-600 focus:ring-white'>
                                        Menu bar
                                    </button>
                                </div>
                                <div className='hidden lg:block lg:w-80'>
                                    <div className='flex items-center justify-end'>
                                        <div className='flex'>
                                            <a href='#' className='px-3 py-2 rounded-md text-sm font-medium text-white hover:text-white'>
                                                Chat
                                            </a>
                                        </div>
                                        <div className='ml-4 relative flex-shrink-0'>
                                            <div>
                                                <button className='bg-blue-700 flex text-sm rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-700 focus:ring-white'>
                                                    <img
                                                        src={userAvatar}
                                                        className='h-8 w-8 rounded-full'
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className='flex-grow w-full   max-w-7xl mx-auto lg:flex'>
                        <div className='flex-1 min-w-0 bg-white xl:flex'>
                            <div className='border-b border-gray-200 xl:border-b-0 xl:flex-shrink-0 xl:w-64 xl:border-r xl:border-gray-200 bg-gray-50'>
                                <div className='h-full pl-4 pr-2 py-6 sm:pl-6 lg:pl-8 xl:pl-0'>
                                    <div className='h-full relative'>
                                        <div className='relative rounded-lg px-2 py-2 flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500 mb-4'>
                                            <div className='flex-shrink-0'>
                                                <img
                                                    className='h-12 w-12 rounded-full'
                                                    src={userAvatar}
                                                />
                                            </div>
                                            <div className='flex-1 min-w-0'>
                                                <a href='#' className='focus:outline-none'>
                                                    <span className='absolute inset-0' />
                                                    <p className='text-sm font-bold text-blue-600'>ABC limited</p>
                                                    <p className='text-sm  text-gray-500 truncate'>IT Consulting</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='mb-4'>
                                            <div className='relative'>
                                                <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                                                    <CiSearch />
                                                </div>
                                                <input
                                                    name='search'
                                                    placeholder='Search...'
                                                    className='focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm focus:outline-none border-gray-100 rounded-full p-2 border'
                                                />
                                            </div>
                                        </div>
                                        {/* search box end */}
                                        <div className='scrollable-container hide-scrollbar px-3 py-2'>
                                            {allChats.map((chats, index) =>
                                            (

                                                <div key={index} onClick={() => handleUserClick(chats.userId)} className='relative scrollable-container rounded-lg px-2 py-2 flex items-center space-x-3  focus-within:ring-2 mb-3 hover:bg-gray-200'>
                                                    <div className='flex-shrink-0'>
                                                        <img
                                                            className='h-10 w-10 rounded-full'
                                                            src={userAvatar}
                                                        />
                                                    </div>
                                                    <div className='flex-1 min-w-0'>
                                                        <a href='#' className='focus:outline-none'>
                                                            <div className='flex items-center justify-between'>
                                                                <p className='text-sm font-bold text-blue-600'>{chats.name}</p>
                                                                <div className='text-gray-400'>
                                                                    12:13pm
                                                                </div>
                                                            </div>
                                                            <div className='flex items-center justify-between'>
                                                                <p className='text-sm text-gray-500 truncate'>
                                                                    {getLastMessage(chats.name)}
                                                                </p>
                                                                <div className='text-white text-xs bg-blue-400 rounded-full px-1 py-0'>
                                                                    2
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* middle content */}
                            {selectedUserData && (

                                <div className='flex-1 p-2 sm:pb-6 justify-between flex flex-col h-screen hidden xl:flex'>
                                    <div className='flex items-center justify-between py-3 border-b border-gray-200 p-3'>
                                        <div className='flex items-center space-x-4'>
                                            <div className='flex items-center space-x-4'>
                                                <img
                                                    src={userAvatar || userDp}
                                                    className='w-10 sm:w-12 sm:h-12 rounded-full cursor-pointer'
                                                />
                                                <div className='flex flex-col leading-tight'>
                                                    <div className='text-1xl mt-1 flex items-center'>
                                                        <span className='text-gray-700 mr-3'>{selectedUserData.name || userName}</span>
                                                        <span className='text-green-500'>
                                                            <svg width={10} height={10}>
                                                                <circle cx={5} cy={5} r={5} fill='currentColor' />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex items-center space-x-2'>
                                            <button className='inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none'><FaSearch /></button>
                                            <button className='inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none'><CiHeart /></button>
                                        </div>
                                    </div>
                                    {/* Messages */}
                                    <div
                                        id='messages'
                                        className='flex flex-col  space-y-4 p-3 overflow-y-auto scrollbar scrollbar-thumb-blue-500 scrollbar-track-gray-200 scrollbar-thumb-rounded'>
                                        {/* first message */}
                                        {selectedUserData && selectedUserData.messages?.map((chat, index) => (
                                            <div className='chat-message'>
                                                <div className={`flex items-end ${chat.sender === "user1" ? 'justify-end' : 'justify-start'}`}>
                                                    <div className='flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start'>
                                                        <div className='flex'>
                                                            <span className={` px-4 py-2 rounded-lg inline-block rounded-bl-none ${chat.sender === "user1" ? 'bg-blue-400 text-white' : 'bg-gray-200 text-gray-600'} `}>
                                                                {chat.text}
                                                            </span>

                                                            {chat.sender === "user1" && <span className='flex justify-end items-end'>
                                                                <SiTicktick className='mb-1 ml-1 text-gray-800' />
                                                            </span>}
                                                        </div>
                                                        <div className={`text-xs mt-1 ${chat.sender === "user1" ? "text-right" : "text-left"} text-gray-500`}>
                                                            {chat.time}
                                                        </div>
                                                    </div>
                                                    <img
                                                        src={userAvatar}
                                                        className='w-6 h-6 rounded-full order-1'
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* messages end */}

                                   {isTyping&& <div className="flex items-center space-x-2 ml-3 bg-gray-200 p-3 w-14 rounded-lg  rounded-bl-none">
                                        <div className="dot bg-gray-600"></div>
                                        <div className="dot bg-gray-600"></div>
                                        <div className="dot bg-gray-600"></div>
                                    </div>}
                                    

                                    <div className='border-t-2 border-gray-200 px-4 pt-4  mb-24'>

                                        <div className='relative flex'>
                                            <span className='absolute inset-y-0 flex items-center'>
                                                <button onClick={() => setEmojiPick((prev) => !prev)} className='inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300'>
                                                    <FaRegSmile />
                                                </button>
                                            </span>
                                            {showEmojiPick && (
                                                <div className="absolute bottom-4  left-80 z-10 bg-white border border-gray-300 rounded-lg shadow-lg">
                                                    <div className='flex justify-end p-2'>
                                                        <button
                                                            onClick={() => setEmojiPick(false)}
                                                            className="text-gray-500 hover:text-red-500"
                                                        >
                                                            <IoMdClose />
                                                        </button>
                                                    </div>
                                                    <EmojiPicker onEmojiClick={onEmojiClick} />
                                                </div>
                                            )}
                                            <textarea
                                                value={msgInput}
                                                onChange={handleInputChange}
                                                rows={rows}
                                                placeholder='Write Something'
                                                className='focus:ring-blue-500 focus:outline-none focus:border-blue-500 w-full focus:placeholder-gray-400 text-gray-600 placeholder-gray-300 pl-12 bg-gray-100 rounded-full py-3 border border-gray-400 resize-none overflow-hidden'
                                            />

                                            <span className=' inset-y-0 flex items-center'>
                                                <button
                                                    disabled={!msgInput.trim()}
                                                    onClick={() => sendMessage()}
                                                    className={`inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 ${!msgInput.trim() ? 'cursor-not-allowed opacity-50' : ''} `}>
                                                    <BsSendFill />
                                                </button>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            )}

                            {/* <div className='bg-gray-50 pr-4 sm:pr-6 lg:pr-8 lg:flex-shrink-0 lg:border-l lg:border-gray-200 xl:block'>
                                <div className='h-full pl-6 py-6 lg:w-80'>
                                    <div className='h-full relative'>
                                        <div className='m-auto text-center mb-10'>
                                            <img
                                                className='w-36 h-36 rounded-full m-auto'
                                                src={userAvatar}
                                            />
                                            <h2 className='m-auto text-2xl mt-2'>Xyx public limited</h2>
                                        </div>
                                        <div className='mb-2'>
                                          <h4>Attachments</h4>
                                        </div>

                                        <div className='grid grid-cols-4 gap-2'>
                                          <div>
                                            <div className='cursor-pointer bg-gray-300 hover:bg-gray-400 h-14 w-full'></div>
                                          </div>

                                          <div>
                                            <div className='cursor-pointer bg-gray-300 hover:bg-gray-400 h-14 w-full'></div>
                                          </div>

                                          <div>
                                            <div className='cursor-pointer bg-gray-300 hover:bg-gray-400 h-14 w-full'></div>
                                          </div>

                                          <div>
                                            <div className='cursor-pointer bg-gray-300 hover:bg-gray-400 h-14 w-full'></div>
                                          </div>

                                          <div>
                                            <div className='cursor-pointer bg-gray-300 hover:bg-gray-400 h-14 w-full'></div>
                                          </div>

                                          <div>
                                            <div className='cursor-pointer bg-gray-300 hover:bg-gray-400 h-14 w-full'></div>
                                          </div>

                                          <div>
                                            <div className='cursor-pointer bg-gray-300 hover:bg-gray-400 h-14 w-full'></div>
                                          </div>

                                          <div>
                                            <div className='cursor-pointer bg-gray-300 hover:bg-gray-400 h-14 w-full'></div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SendMessage