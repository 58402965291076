import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useOrgEditForm } from '../../../../helper/organization/orgFormHook'
import { MdAddPhotoAlternate } from "react-icons/md";
import { companyProfile, companyTypes, getCities, getCountries, getEmployeeRange, getProvince, organizationUpdate } from '../../../../helper/organization/orgRequestCall';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { message } from 'antd';
import { jwtDecode } from 'jwt-decode';
import { servicesOptions } from '../../../../utils/tempJson/_optionsJson';
import { FaMinus, FaPlus } from 'react-icons/fa';
import CompanyInfo from '../../../../components/private/organization/organizationUpdate/CompanyInfo';
import LocationInfo from '../../../../components/private/organization/organizationUpdate/LocationInfo';
import SocialMediaInfo from '../../../../components/private/organization/organizationUpdate/SocialMediaInfo';
import { IoIosArrowBack } from 'react-icons/io';

function UpdateOrganization() {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const [previewLogo, setPreviewLogo] = useState("")
  const [selectedLogo, setselectedLogo] = useState(null)
  const [compTypes, setTypes] = useState([])
  const [countries, setCountries] = useState([])
  const [province, setProvince] = useState([])
  const [city, setCities] = useState([])
  const [employees, setEmployees] = useState([])
  const [companyInfo, setCompanyInfo] = useState(false)
  const [locationInfo, setLocationInfo] = useState(false)
  const [socialMedia, setSocialMedia] = useState(false)
  // const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation();
  const id = location.state?.org_id;


  const token = localStorage.getItem('authToken')
  const decoded = jwtDecode(token)
  const userId = decoded.userId

  const employeeRange = () => {
    getEmployeeRange()
      .then((res) => {
        if (res.status === 200) {
          setEmployees(res.data.data)
        }
      })
  }


  const getCompanyTypes = () => {
    companyTypes()
      .then((res) => {
        if (res.status === 200) {
          setTypes(res.data.data)
        }
      })
      .catch((err) => console.error("error", err))
  }

  useEffect(() => {
    employeeRange()
    getCompanyTypes()
  }, [])
  useEffect(() => {
    const fetchCountry = () => {
      getCountries()
        .then((res) => {
          if (res.status === 200) {
            setCountries(res.data)

          }
          // console.log("res",res.data)
        })
        .catch((err) => console.error("Error", err))
    }
    fetchCountry()
  }, [])

  const getSingleOrganization = () => {
    companyProfile(id)
      .then((res) => {
        if (res.status === 200) {
          formik.setFieldValue("companyType", res.data.data.companyType);
          formik.setFieldValue("established", res.data.data.establishmentYear);
          formik.setFieldValue("CIN", res.data.data.cin);
          formik.setFieldValue("PAN", res.data.data.pan);
          formik.setFieldValue("phoneNo", res.data.data.phoneNumber)
          formik.setFieldValue("legalEntityName", res.data.data.legalEntityName);
          formik.setFieldValue("officialMailId", res.data.data.officialMailId);
          formik.setFieldValue("companyBrandName", res.data.data.companyBrandName);
          formik.setFieldValue("noOfEmployees", res.data.data.noOfEmployees);
          formik.setFieldValue("gstNumber", res.data.data.gstin);
          formik.setFieldValue("companyUrl", res.data.data.companyUrl);
          formik.setFieldValue("description", res.data.data.businessDescription);
          formik.setFieldValue("LinkedIn", res.data.data.linkedInUrl);
          formik.setFieldValue("faceBook", res.data.data.facebookUrl);
          formik.setFieldValue("instagram", res.data.data.instagramUrl);
          formik.setFieldValue("twitterUrl", res.data.data.twitterUrl);
          formik.setFieldValue("locationId", res.data.data.communicationAddress.id)
          formik.setFieldValue("addressLine1", res.data.data.communicationAddress.addressLine1);
          formik.setFieldValue("addressLine2", res.data.data.communicationAddress.addressLine2);
          formik.setFieldValue("pinCode", res.data.data.communicationAddress.postalCode);
          formik.setFieldValue("country", res.data.data.communicationAddress.country.id);
          // console.log("id", res.data.data.communicationAddress.country.id)
          formik.setFieldValue("Industries", res.data.data.services);
          // console.log("counryid",res.data.location.country.code)
          formik.setFieldValue("province", res.data.data.communicationAddress.state.id);
          formik.setFieldValue("city", res.data.data.communicationAddress.city.id);
          // console.log("country", formik.values.country)
        }
      })
      .catch((err) => console.error("Error", err))
    // const cin = "738793";
    // const pan = "87487385";
    // const legalEntityName = "abc";
    // const officialMailId = "abc@mail.com";
    // const companyBrandName = "abc brand";
    // const noOfEmployees = "noOfEmployees";
    // const companyUrl = "https://inkqubee.com/";
    // const addressLine1 = "Address 1";
    // const addressLine2 = "Address 2";
    // const landmark = "landmark here";
    // const logo = "https://th.bing.com/th/id/OIP.afQdiNPi7rhMZnP6xqoyLwAAAA?rs=1&pid=ImgDetMain"
    // // const countryName = "India";
    // // const countryCode = "IN"
    // formik.setFieldValue("CIN", cin);
    // formik.setFieldValue("PAN", pan);
    // formik.setFieldValue("legalEntityName", legalEntityName);
    // formik.setFieldValue("officialMailId", officialMailId);
    // formik.setFieldValue("companyBrandName", companyBrandName);
    // formik.setFieldValue("noOfEmployees", noOfEmployees);
    // formik.setFieldValue("companyUrl", companyUrl);
    // formik.setFieldValue("addressLine1", addressLine1);
    // formik.setFieldValue("addressLine2", addressLine2);
    // formik.setFieldValue("landmark", landmark);
    // if (logo) {
    //   formik.setFieldValue("logo", logo);
    //   setselectedLogo(logo)
    //   setPreviewLogo(logo)
    // }

    // formik.setFieldValue("country", countryName);
    // formik.setFieldValue("location.country.countryCode", countryCode);
  }

  useEffect(() => {
    getSingleOrganization()
  }, [])

  const handleEdit = (values) => {
    // console.log("error",formik.errors)
    // console.log("values", values)

    const countryId = parseInt(values.country)
    const provinceId = parseInt(values.province)
    const cityId = parseInt(values.country)
    // console.log("id",countryId,provinceId,cityId)
    const location = {
      id: values.locationId,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      postalCode: values.pinCode,
      country: { id: countryId },
      state: { id: provinceId },
      city: { id: cityId }
    };


    const payLoad = {
      companyId: id,
      companyType: values.companyType,
      cin: values.CIN,
      phoneNumber: values.phoneNo,
      gstin: values.gstNumber,
      legalEntityName: values.legalEntityName,
      companyBrandName: values.companyBrandName,
      businessDescription: values.description,
      communicationAddress: location,
      officialMailId: values.officialMailId,
      // billingAddress: billingDetails,
      noOfEmployees: values.noOfEmployees,
      companyUrl: values.companyUrl,
      establishmentYear: values.established,
      userId: userId,
      services: values.Industries,
      linkedInUrl: values.LinkedIn,
      facebookUrl: values.faceBook,
      instagramUrl: values.instagram,
      youtubeUrl: values.youtube,
      selectedPlanId: 0,
      twitterUrl: values.xMedia,
      paymentReferenceId: values.paymentRefrence
    };

    // console.log("update payload",payLoad)

    organizationUpdate(payLoad)
      .then((res) => {
        if (res.status === 200) {
          message.success("Updated successfully")
        } else {
          message.error("Updation failed")
        }
      })
      .catch((err) => console.error("Error:", err))
  }

  const handleLogo = (e) => {
    const file = e.target.files[0];
    if (file) {
      setselectedLogo(file);
      setPreviewLogo(URL.createObjectURL(file));
    }
  }
  const formik = useOrgEditForm(handleEdit)

  useEffect(() => {
    const fetchProvince = () => {
      // console.log("country id", formik.values.country)
      if (formik.values.country) {
        // console.log("province id",formik.values.country)
        getProvince(formik.values.country)
          .then((res) => {
            // console.log("res", res.data)
            if (res.status === 200) {
              setProvince(res.data)
              // console.log(province)
            }
          })
          .catch((error) => console.error("Error fetching provinces:", error));
      }
    }
    fetchProvince()
  }, [formik.values.country])

  useEffect(() => {
    const fetchCity = () => {
      if (formik.values.province) {
        // console.log("province id",formik.values.province)
        getCities(formik.values.province)
          .then((res) => {
            if (res.status === 200) {
              setCities(res.data)
            }
          })
          .catch((error) => console.error("Error fetching cities:", error));
      }
    }
    fetchCity()
  }, [formik.values.province])

  return (
    <div className={`flex flex-col min-h-screen hide-scrollbar overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2`}>
      <div className={` w-full  max-h-full justify-center ${!isDark ? 'bg-white' : 'bg-slate-500'} space-y-6 rounded-lg h-full mt-11 p-2`}>
        <div className={`w-full mt-1  space-y-6 rounded-lg px-6 p-2`} >
          <li
            onClick={() => setCompanyInfo(!companyInfo)}
            className='w-full flex justify-between text-xl cursor-pointer bg-blue-800 p-2 text-white mt-14'
          >
            Update Company Information
            {companyInfo ?
              <FaMinus className='cursor-pointer'
                onClick={() => setCompanyInfo(false)}
              /> :
              <FaPlus
                className='cursor-pointer'
                onClick={() => setCompanyInfo(true)}
              />}
          </li>
          {companyInfo && <div className='border shadow-md p-3'>
            <CompanyInfo formik={formik} compTypes={compTypes} employees={employees} />
          </div>}
        </div>
        <div className={`w-full mt-1  space-y-6 rounded-lg px-6 p-2`} >
          <li
            onClick={() => setLocationInfo(!locationInfo)}
            className='w-full flex justify-between text-xl cursor-pointer bg-blue-800 p-2 text-white'
          >
            Update Location Information
            {locationInfo ?
              <FaMinus className='cursor-pointer'
                onClick={() => setLocationInfo(false)}
              /> :
              <FaPlus
                className='cursor-pointer'
                onClick={() => setLocationInfo(true)}
              />}
          </li>
          {locationInfo && <div className='border shadow-md p-3'>
            <LocationInfo formik={formik} countries={countries} province={province} city={city} />
          </div>}
        </div>

        <div className={`w-full mt-1  space-y-6 rounded-lg px-6 p-2`} >
          <li
            onClick={() => setSocialMedia(!socialMedia)}
            className='w-full flex justify-between text-xl cursor-pointer bg-blue-800 p-2 text-white'
          >
            Update Social Media Information
            {socialMedia ?
              <FaMinus className='cursor-pointer'
                onClick={() => setSocialMedia(false)}
              /> :
              <FaPlus
                className='cursor-pointer'
                onClick={() => setSocialMedia(true)}
              />}
          </li>
          {socialMedia && <div className='border shadow-md p-3'>
            <SocialMediaInfo formik={formik} />
          </div>}
        </div>
        <div className='flex justify-center items-center'>
          <button className='flex btn ' type='button' onClick={() => navigate(-1)}><IoIosArrowBack className='mt-0.5 ' />Back</button>
        </div>
      </div>
    </div>
  )
}

export default UpdateOrganization