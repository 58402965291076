import React from 'react'

function ServiceProviderLoad() {
    return (
        <div className="border border-gray-400 shadow rounded-md p-4  w-full mx-auto">
            <div className=" animate-pulse  flex space-x-4">
                {/* image */}
                <div className="rounded-full bg-slate-300 h-10 w-10"></div>
                <div className="flex-1 space-y-6 py-1">
                    {/* title */}
                    <div className="h-2 bg-slate-300 rounded"></div>
                    {/* discription */}
                    <div className=" space-y-3">
                        
                        <div className="grid grid-cols-4 gap-4">
                            <div className="h-2 bg-slate-300 rounded col-span-2"></div>
                            <div className="h-2 bg-slate-300 rounded col-span-1"></div>
                            <div className="h-2 bg-slate-300 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-slate-300 rounded"></div>
                        <div className="h-2 bg-slate-300 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceProviderLoad