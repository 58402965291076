import React, { useEffect, useState } from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { IoIosArrowBack } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PersonalInfo from '../../../../components/private/investment/investorUpdate/PersonalInfo'
import { useUpdateInvestmentForm } from '../../../../helper/investors/investmentFormHook'
import InvestmentRequirments from '../../../../components/private/investment/investorUpdate/InvestmentRequirments'
import InvestmentPreference from '../../../../components/private/investment/investorUpdate/InvestmentPreference'
import { getAgrigatedCity, getCities, getCountries, getProvince } from '../../../../helper/organization/orgRequestCall'
import { getIndustry, getTypes } from '../../../../helper/investors/investorRequestCall'
import { message } from 'antd'

function InvestorUpdate() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const [personalInfo, setPersonalInfo] = useState(false)
    const [requirements, setRequirements] = useState(false)
    const [preferences, setPrefreneces] = useState(false)
    const [countries, setCountries] = useState([])
    const [province, setProvinces] = useState([])
    const [cities, setCities] = useState([])
    const [locations, setAllLocation] = useState(["Banglore,KA,IN","Chennai,TN,IN"])
    const [industries, setIndustries] = useState(["IT software","IT consulting","Testing"])
    const [types, setTypes] = useState(["temp","Eqity","Debt"])


    const countryMaped = countries.map((country) => ({
        value: country.countryId,
        label: country.name
    }))

    const provinceMaped = province.map((state) => ({
        value: state.provinceId,
        label: state.name
    }))

    const cityMaped = cities.map((city) => ({
        value: city.cityId,
        label: city.name
    }))

    const navigate = useNavigate()
    const handleUpdate = (values) => {
        console.log("values", values)
        message.success("Form submited")
    }

    const formik = useUpdateInvestmentForm(handleUpdate)

    const fetchIndustreies = () => {
        getIndustry()
            .then((res) => {
                if (res.status === 200) {
                    setIndustries(res.data.data)
                }
            })
            .catch((err) => {
                console.error("err", err)
            })
    }

    const fetchTypes = () => {
        getTypes()
            .then((res) => {
                if (res.status === 200) {
                    setTypes(res.data.data)
                }
            })
            .catch((err) => console.error("error", err))
    }

    const getLocation = () => {
        getAgrigatedCity()
            .then((res) => {
                if (res.status === 200) {
                    setAllLocation(res.data.data)
                }
            })
            .catch((err) => {
                console.error("err", err)
            })
    }
    useEffect(() => {
        fetchIndustreies()
        fetchTypes()
        getLocation()
    }, [])


    useEffect(() => {
        const fetchCountry = () => {
            getCountries()
                .then((res) => {
                    if (res.status === 200) {
                        setCountries([])
                    }
                })
                .catch((err) => console.error("Error", err))
        }
        fetchCountry()
    }, [])

    useEffect(() => {
        const fetchProvince = () => {
            if (formik.values.country) {
                getProvince(formik.values.country)
                    .then((res) => {
                        if (res.status === 200) {
                            setProvinces(res.data)
                        }
                    })
                    .catch((error) => console.error("Error fetching provinces:", error));
            }
        }
        fetchProvince()
    }, [formik.values.country])

    useEffect(() => {
        const fetchCity = () => {
            if (formik.values.state) {
                getCities(formik.values.state)
                    .then((res) => {
                        if (res.status === 200) {
                            setCities(res.data)
                        }
                    })
                    .catch((error) => console.error("Error fetching cities:", error));
            }
        }
        fetchCity()
    }, [formik.values.state])


    const getSingleInvestor=()=>{
        const investor = {
            firstName: "Jhon",
            lastName: "doe",
            location: {
                addressLine1: "address1",
                addressLine2: "address2",
                postalCode: "postal code",
                // country: {
                //     id: values.country
                // },
                // state: {
                //     id: values.state
                // },
                // city: {
                //     id: values.city
                // }
            },
            phoneNumber: "9898888456",
            panNumber: "DBNHUU84598PAN",
            // panDocument: values.panDocument,
            email: "jhondoe@mail.com",
            aadharNumber: "ID39848JJF",
            // idProofDocument: values.idProofDocument,
            // incomeProof: values.incomeProof,
            profileDescription: "Its about my profile",
            investmentSizeRange: {
                minInvestment: 100,
                maxInvestment: 400
            },
            // priorInvestments: investments,
            investmentType: "Eqity",
            industryPreferences: ["IT software","IT consulting"],
            preferredLocation: ["Banglore,KA,IN","Chennai,TN,IN"]
        }
        formik.setFieldValue("firstName",investor.firstName)
        formik.setFieldValue("lastName",investor.lastName)
        formik.setFieldValue("addressLine1",investor.location.addressLine1)
        formik.setFieldValue("addressLine2",investor.location.addressLine2)
        formik.setFieldValue("pinCode",investor.location.postalCode)
        formik.setFieldValue("phone",investor.phoneNumber)
        formik.setFieldValue("panNumber",investor.panNumber)
        formik.setFieldValue("email",investor.email)
        formik.setFieldValue("idProofNumber",investor.aadharNumber)
        formik.setFieldValue("profile",investor.profileDescription)
        formik.setFieldValue("minimumInvestment",investor.investmentSizeRange.minInvestment)
        formik.setFieldValue("maximumInvestment",investor.investmentSizeRange.maxInvestment)
        formik.setFieldValue("typesOfInvestment",investor.investmentType)
        formik.setFieldValue("industryPreference",investor.industryPreferences)
        formik.setFieldValue("locationPreference",investor.preferredLocation)
    }

    useEffect(()=>{
        getSingleInvestor()
    },[])
    return (
        <div className={`flex flex-col min-h-screen hide-scrollbar overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2`}>
            <div className={` w-full  max-h-full justify-center ${!isDark ? 'bg-white' : 'bg-slate-500'} space-y-6 rounded-lg h-full mt-11 p-2`}>
                <div className={`w-full mt-1  space-y-6 rounded-lg px-6 p-2`} >
                    <li
                        onClick={() => setPersonalInfo(!personalInfo)}
                        className='w-full flex justify-between text-xl cursor-pointer bg-blue-800 p-2 text-white mt-14'
                    >
                        Update Personal Info
                        {personalInfo ?
                            <FaMinus className='cursor-pointer'
                                onClick={() => setPersonalInfo(false)}
                            /> :
                            <FaPlus
                                className='cursor-pointer'
                                onClick={() => setPersonalInfo(true)}
                            />}
                    </li>
                    {personalInfo && <div className='border shadow-md p-3'>
                        <PersonalInfo formik={formik} countryMaped={countryMaped} provinceMaped={provinceMaped} cityMaped={cityMaped} />
                    </div>}
                </div>
                <div className={`w-full mt-1  space-y-6 rounded-lg px-6 p-2`} >
                    <li
                        onClick={() => setRequirements(!requirements)}
                        className='w-full flex justify-between text-xl cursor-pointer bg-blue-800 p-2 text-white'
                    >
                        Update Investment Requirements
                        {requirements ?
                            <FaMinus className='cursor-pointer'
                                onClick={() => setRequirements(false)}
                            /> :
                            <FaPlus
                                className='cursor-pointer'
                                onClick={() => setRequirements(true)}
                            />}
                    </li>
                    {requirements && <div className='border shadow-md p-3'>
                        <InvestmentRequirments formik={formik} />
                    </div>}
                </div>

                <div className={`w-full mt-1  space-y-6 rounded-lg px-6 p-2`} >
                    <li
                        onClick={() => setPrefreneces(!preferences)}
                        className='w-full flex justify-between text-xl cursor-pointer bg-blue-800 p-2 text-white'
                    >
                        Update Investment Preferences
                        {preferences ?
                            <FaMinus className='cursor-pointer'
                                onClick={() => setPrefreneces(false)}
                            /> :
                            <FaPlus
                                className='cursor-pointer'
                                onClick={() => setPrefreneces(true)}
                            />}
                    </li>
                    {preferences && <div className='border shadow-md p-3'>
                        <InvestmentPreference formik={formik} industries={industries} types={types} locations={locations}/>
                    </div>}
                </div>
                <div className='flex justify-center items-center'>
                    <button className='flex btn ' type='button' onClick={() => navigate(-1)}><IoIosArrowBack className='mt-0.5 ' />Back</button>
                </div>
            </div>
        </div>
    )
}

export default InvestorUpdate