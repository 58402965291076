import React from 'react'
import Header from '../../components/public/Header'
import '../../App.css';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/public/Footer';
import AppSidebar from '../../components/AppSidebar';
import PrivateHeader from '../../components/private/PrivateHeader';
import { sidebarMenu } from '../../utils/tempJson/_menuItems';
import FilterBar from '../../components/FilterBar';
import { useSelector } from 'react-redux';
import OrgProfileMenu from '../../components/private/organization/OrgProfileMenu';

function PrivateLayout({
    auth,
    children,hover,
    setHover,}) {
    const location = useLocation()
    const { isSidebar } = useSelector((state) => state.sidebarState)
    return (
        <div className='flex flex-col h-auto'>
            <Header auth={auth} />
            <PrivateHeader />
            {/* <OrgProfileMenu/>   */}
            <div className='flex '>  
                <AppSidebar menuItems={sidebarMenu} hover={hover}
                  setHover={setHover}/>
                {/* {location.pathname === "/service-provider/list" && isSidebar && <FilterBar selectedItems={selectedItems} setSelectedItems={setSelectedItems}/>} */}
                {children}
            </div>
            <Footer />
        </div >
    )
}

export default PrivateLayout



