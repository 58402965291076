import React from 'react'
import Select from 'react-select'

function CreatePortfolio({ formik, servicesOptions }) {
    return (
        <div>
            <h1 className='text-center text-xl text-gray-950 '>Create Portfolio</h1>
            {/* <ServiceProviderStep /> */}
            <form
                onSubmit={formik.handleSubmit}
                className='flex flex-col space-y-3 px-5 py-8'
            >
                <div className='relative w-full '>
                    <input
                        type='text'
                        id='name'
                        className={`p-2 border w-full focus:outline-none placeholder:text-sm placeholder-slate-600 text-black rounded-lg border-1  border-gray-400 `}
                        placeholder='Enter name'
                        onChange={formik.handleChange}
                        value={formik.values.name}
                    // onBlur={formik.handleBlur}
                    />
                    <label
                        htmlFor='name'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Name
                    </label>
                </div>


                <div className='relative w-full mb-6'>
                    <Select
                        id='service'
                        options={servicesOptions}
                        styles={{
                            control: (base) => ({
                                ...base,
                                width: '100%',
                                minWidth: '100%',
                                borderColor: '#9ca3af',
                                borderRadius: '0.5rem',
                                color: 'black',
                                height: '44px',
                            }),
                            placeholder: (base) => ({
                                ...base,
                                color: '#475569',
                                fontSize: '0.875rem',
                            }),
                        }}
                        value={servicesOptions.filter((option) =>
                            formik.values.service.includes(option.value)
                        )}
                        onChange={(selectedOption) => {
                            formik.setFieldValue("service", selectedOption ? selectedOption.value : "");
                        }}
                        placeholder="Choose The Services"
                        onBlur={formik.handleBlur}
                    />
                    <label
                        htmlFor='service'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Choose Services<span className='text-red-600 ml-1'>*</span>
                    </label>
                    {formik.touched.service && formik.errors.service ?
                        <div className='text-red-700 ml-2 mt-1'>
                            {formik.errors.service}
                        </div> : null}
                </div>
                <div className='relative w-full mt-1'>
                    <textarea
                        id='description'
                        name='description'
                        className='p-2 w-full border border-gray-600 placeholder:text-sm focus:outline-none placeholder-slate-600 text-black rounded-lg'
                        placeholder='Enter Description'
                        rows='5'
                        onChange={formik.handleChange}
                        value={formik.values.description}
                    />
                    <label
                        htmlFor='description'
                        className='absolute top-0 left-4 font-semibold -mt-2 px-1 text-sm transition-all text-black bg-white'
                    >
                        Description
                    </label>
                </div>
                {/* <input
            type='file'
            className='max-w-md w-52 text-white'
          /> */}
                <div className='flex justify-center items-center'>
                    {/* <button className='btn max-w-md w-52' onClick={() => handleBack()}>
                        Back
                    </button> */}
                    <input
                        type='submit'
                        className='btn max-w-md w-52 ml-2'
                        value='Submit'
                    />
                </div>
            </form>
        </div>
    )
}

export default CreatePortfolio