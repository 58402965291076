import { useFormik } from "formik"
import { courceContactSchema, projectValidation } from "../../validation"


export const useAddProjectForm = (handleSubmit) => {
    return useFormik({
        initialValues: {
            projectTitle: "",
            projectDescription: "",
            objectiveAndGoals: "",
            projectTimeLine: "",
            companyId: "",
            companyName:"",
            budgetRange: "",
            skillsRequired: "",
            numberOfResources: 0,
            preferredSubmissionFormat: "",
            deadline: ""
        },
        validationSchema: projectValidation,
        onSubmit: values => {
            // console.log("values",values)
            handleSubmit(values)
        }
    })
}