import { FieldArray } from 'formik'
import React, { useState } from 'react'
import { useInvestmentForm } from '../../../../helper/investors/investmentFormHook'
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import { Button, Upload } from 'antd';
import { UploadOutlined } from "@ant-design/icons";

function SecondStep({ investments, setInvestments, touchedMaximim, handleMaximumBlur, stateError, handleChange, handleFileChange, minimum, setMinimum, maximum, setMaximum }) {
    const sorceOfFundsList = ["Salary", "Business", "Inheritance", "Savings"]


    const props = {
        accept: ".pdf,.doc,.docx", // Restrict file types
        beforeUpload: (file) => {
            const isAcceptedType = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "image/jpeg", "image/png"].includes(file.type);
            if (!isAcceptedType) {
                alert("You can only upload PDF, Word, or Image files!");
                return Upload.LIST_IGNORE;
            }
            return true;
        },
        onChange: (info) => {
            if (info.file.status === "done") {
                console.log("File uploaded:", info.file.originFileObj);
                // Submit the file here if needed
            } else if (info.file.status === "error") {
                console.error("File upload failed.");
            }
        },
    };




    const addInvestment = () => {
        setInvestments([...investments, { company: '', amount: '', document: null }]);
    };


    const removeInvestment = (index) => {
        const values = [...investments];
        values.splice(index, 1);
        setInvestments(values);
    };

    const handleSubmit = (values) => {
        console.log("values", values)
    }


    return (
        <div className='flex flex-col justify-center items-center '>
            <div className='flex flex-col justify-center items-center'>
                {/* <div className='flex flex-col border p-3'>
                    <label className='ml-2 font-semibold mt-2 text-center'>Investment Capacity <span className='text-red-600 ml-1'>*</span></label>
                    <input
                        type='range'
                        min="50000"
                        max="1000000"
                        step="50000"
                        id='investmentCapacity'
                        name='investmentCapacity'
                        onChange={formik.handleChange}
                        value={formik.values.investmentCapacity}
                        onBlur={formik.handleBlur}
                        className="p-2 border w-96 focus:outline-none rounded-lg border-1 accent-blue-600"
                    />
                    <span className='text-center border'>${formik.values.investmentCapacity.toLocaleString()}</span>
                </div> */}
                <div className='flex flex-col w-96'>
                    <label className='mb-3 text-center font-bold'>Investment Size</label>
                    <div className='flex w-96'>
                        <div className='relative w-full mb-4'>
                            <input
                                type='number'
                                id='minimum'
                                name='minimum'
                                className={`p-2 border focus:outline-none placeholder:text-sm focus:border-blue-500 focus:border-2 placeholder-slate-600 text-black rounded-lg border-1  border-gray-400 `}
                                placeholder='Mimimum'
                                value={minimum}
                                onChange={(e) => setMinimum(e.target.value)}
                            />
                            <label
                                htmlFor='minimum'
                                className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                            >
                                Minimum Amount
                            </label>
                        </div>
                        <span className='ml-1 me-1 mt-2 text-lg font-bold'>-</span>
                        <div className='relative w-full mb-4'>
                            <input
                                type='number'
                                id='maximum'
                                name='maximum'
                                className={`p-2 border focus:outline-none placeholder:text-sm focus:border-blue-500 focus:border-2 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400  `}
                                placeholder='Maximum'
                                value={maximum}
                                onChange={(e) => setMaximum(e.target.value)}
                                onBlur={handleMaximumBlur}
                            />
                            <label
                                htmlFor='maximum'
                                className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                            >
                                Maximum Amount
                            </label>
                            {
                              touchedMaximim.maximum &&  stateError.maximumError ?
                                    <div className='text-red-600 ml-1'>
                                        {stateError.maximumError}
                                    </div> : null
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SecondStep