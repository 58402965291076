import React, { useState } from 'react'
import PrivacyPolicyModal from '../../../../utils/Modal/PrivacyPolicyModal';
import TermsAndConditionModal from '../../../../utils/Modal/TermsAndConditionModal';
import PlansMap from '../plans/PlansMap';
import RefundModal from '../../../../utils/Modal/RefundModal';
import PaymentMethod from '../../../../utils/Modal/PaymentMethod';
import PremiumPaymentMethod from '../../../../utils/Modal/PremiumPaymentMethod';
import { inkqubee_plans } from '../../../../utils/tempJson/_tempItems';
import SelectBox from '../../../../utils/selectbox/SelectBox';

function LastStep(props) {
    const step1Data = JSON.parse(localStorage.getItem("formStep1")) || {};

    const {
        formik,
        billingName,
        setBillingName,
        billingAddress,
        setAddresss,
        billingSecondAddress,
        setSecondAddresss,
        billingPincode,
        setPincode,
        billingGst,
        setGst,
        countries,
        province,
        cities,
        billingCountry,
        setBillingCountry,
        billingProvince,
        setBillingProvince,
        billingCity,
        setBillingCity,
        billingPhoneNumber,
        setPhoneNumber,
        billingEmail,
        setEmail,
        openMethod,
        openPremiumMethod,
        handleSubmitBill,
        setOpenMethod,
        setOpenPremiumMethod,
        billingAddressDetail,
        setBillingDetail
    } = props


    const countryMaped = countries.map((country) => ({
        value: country.countryId,
        label: country.name
    }))

    const provinceMaped = province.map((state) => ({
        value: state.provinceId,
        label: state.name
    }))

    const cityMaped = cities.map((city) => ({
        value: city.cityId,
        label: city.name
    }))

    const [privacy, setOpenPrivacy] = useState(false)
    const [terms, setOpenTerms] = useState(false)
    const [refund, setOpenRefund] = useState(false)
    const [openBuyPlan, setOpenBuyPlan] = useState(false)
    const [openForm, setOpenForm] = useState(false)
    const [choosedPlan, setChoosedPlan] = useState(null);
    const [showFeatures, setShowFeatures] = useState(null);

    const handlePlanChange = (planId) => {
        setChoosedPlan(planId === choosedPlan ? null : planId);
        setShowFeatures(planId)
    }

    const handleBillingAddress = (items) => {
        setBillingDetail((prevState) => {
            if (prevState) {
                return null;
            } else {
                return items;
            }
        });
        setOpenMethod(true)
        // console.log("deatils", billingAddressDetail)
    }

    const handleKnowFeature = (planId) => {
        setShowFeatures(planId)
    }


    let basic_amount = 9999
    let Premium_amount = 19999


    const handleBuy = () => {
        setOpenBuyPlan(true)
        // console.log("plan",openBuyPlan)
    }

    return (
        <div className='flex flex-col'>
            <div className='bg-white border p-2'>
                <div className='border-b p-1'>
                    <h1 >Select Plan</h1>
                </div>
                <div className='py-3'>
                    {inkqubee_plans.map((plan, index) => (
                        <div key={index}>
                            <label className="flex items-center space-x-2 cursor-pointer">
                                <input
                                    type="checkbox"
                                    name='choosedPlan'
                                    id='choosedPlan'
                                    checked={choosedPlan === plan.id}
                                    onChange={() => handlePlanChange(plan.id)}
                                    className="hidden "
                                />
                                <div
                                    className={`w-4 h-4 mt-1 flex items-center justify-center border-2 rounded-sm ${choosedPlan === plan.id
                                        ? "bg-blue-500 border-blue-500"
                                        : "bg-white border-gray-400"
                                        }`}
                                >
                                    {choosedPlan === plan.id && (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-4 w-4 text-white"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    )}
                                </div>

                                <div className='w-full flex flex-row justify-between   ml-2'><span className='mt-1 font-semibold'>{plan.name}<button className='text-blue-600 ml-2' onClick={() => handleKnowFeature(plan.id)} type='button'>Know more</button></span><span className='text-lg text-slate-700'>Rs.{plan.price}</span></div>
                            </label>
                            {(showFeatures === plan.id || choosedPlan === plan.id) && <div className='flex  w-full justify-between p-2'>
                                <PlansMap features={plan.features} />
                                {choosedPlan && choosedPlan !== 1 &&
                                    <div className='mt-20'><button type='button' className='p-2 bg-blue-800 text-white rounded' onClick={() => handleBuy()}>Buy Now</button></div>
                                }
                            </div>}

                        </div>
                    ))}
                    {openBuyPlan &&
                        <div className='flex flex-col justify-center items-center p-2 gap-y-6'>
                            <h1 className='mt-2 mb-2 font-bold text-center'>Billing Address</h1>
                            {step1Data.addressLine1 && step1Data.addressLine2 && step1Data.pinCode &&
                                <div >
                                    <div className='flex flex-col justify-center items-center z-10 p-3 border w-[400px] border-gray-300 rounded-md'>
                                        <label className='flex  cursor-pointer'>
                                            <input
                                                type='checkbox'
                                                name='billingAddress'
                                                className='hidden peer'
                                                checked={billingAddressDetail !== null}
                                                onChange={() => handleBillingAddress({
                                                    addressLine1: step1Data.addressLine1,
                                                    addressLine2: step1Data.addressLine2,
                                                    postalCode: step1Data.pinCode,
                                                })}

                                            />
                                            <div
                                                className={`w-4 h-4 mt-1 flex items-center justify-center border-2   rounded-sm ${billingAddressDetail !== null
                                                    ? "bg-blue-500 border-blue-500"
                                                    : "bg-white border-gray-400"
                                                    } `}
                                            >
                                                {billingAddressDetail !== null && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-4 w-4 text-white"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                )}
                                            </div>
                                            <ul className=' ml-2 z-10'>
                                                <h1>Confirm The Address For Billing</h1>
                                                <div className="flex justify-between w-full mt-2">
                                                    <label className="font-semibold text-slate-800">Address Line 1:</label>
                                                    <span className="ml-4">{step1Data.addressLine1}</span>
                                                </div>
                                                <div className="flex justify-between w-full">
                                                    <label className="font-semibold text-slate-800">Address Line 2:</label>
                                                    <span className="ml-4">{step1Data.addressLine2}</span>
                                                </div>
                                                <div className="flex justify-between w-full">
                                                    <label className="font-semibold text-slate-800">Pin Code:</label>
                                                    <span className="ml-4">{step1Data.pinCode}</span>
                                                </div>
                                            </ul>
                                        </label>
                                    </div>
                                    <div class="flex items-center my-4">
                                        <div class="flex-grow border-t border-gray-300"></div>
                                        <span class="px-4 text-gray-500">OR</span>
                                        <div class="flex-grow border-t border-gray-300"></div>
                                    </div>
                                    <div className='flex justify-end items-end p-2'>
                                        <button className='btn' type='button' onClick={() => setOpenForm(true)}>Add Another Address</button>
                                    </div>
                                </div>

                            }
                            {openForm && <form onSubmit={handleSubmitBill}>
                                {/* <div className='relative w-full mb-4 '>
                                        <input
                                            type='text'
                                            id='billingName'
                                            name='billingName'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter Name'
                                            value={billingName}
                                            onChange={(e) => setBillingName(e.target.value)}
                                        />
                                        <label
                                            htmlFor='billingName'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            Billing Name <span className='text-red-600 ml-1'>*</span>
                                        </label>
                                    </div> */}
                                <div className='relative w-full mb-4 '>
                                    <input
                                        type='text'
                                        id='billingAddress'
                                        name='billingAddress'
                                        className={`p-2 border w-96 focus:outline-none placeholder:text-sm focus:border-blue-500 focus:border-2 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                        placeholder='Enter Address Line 1'
                                        value={billingAddress}
                                        onChange={(e) => setAddresss(e.target.value)}
                                    />
                                    <label
                                        htmlFor='billingAddress'
                                        className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                    >
                                        Address line1 <span className='text-red-600 ml-1'>*</span>
                                    </label>
                                </div>
                                <div className='relative w-full mb-4 '>
                                    <input
                                        type='text'
                                        id='billingSecondAddress'
                                        name='billingSecondAddress'
                                        className={`p-2 border w-96 focus:outline-none placeholder:text-sm focus:border-blue-500 focus:border-2 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                        placeholder='Enter Address'
                                        value={billingSecondAddress}
                                        onChange={(e) => setSecondAddresss(e.target.value)}
                                    />
                                    <label
                                        htmlFor='billingSecondAddress'
                                        className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                    >
                                        Address Line2<span className='text-red-600 ml-1'>*</span>
                                    </label>
                                </div>
                                <div className='relative w-full mb-4 '>
                                    <input
                                        type='text'
                                        id='billingPincode'
                                        name='billingPincode'
                                        className={`p-2 border w-96 focus:outline-none placeholder:text-sm focus:border-blue-500 focus:border-2 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                        placeholder='Enter Pincode'
                                        value={billingPincode}
                                        onChange={(e) => setPincode(e.target.value)}
                                    />
                                    <label
                                        htmlFor='billingPincode'
                                        className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                    >
                                        Pin Code<span className='text-red-600 ml-1'>*</span>
                                    </label>
                                </div>
                                <div className='flex flex-col'>
                                    {/* 
                                    <SelectBox
                                        handleBlur={formik.handleBlur}
                                        arrayValues={countryMaped}
                                        placeholder={"Select Country"}
                                        inputArgs="inputArgs"
                                        formikValue={countryMaped.find((option) => option.value === billingCountry)} // Adjust this if multi-selection is used
                                        handleChange={(selectedOption) => {
                                            setBillingCountry( selectedOption ? selectedOption.value : ""); // Ensure value is updated correctly
                                        }}
                                        label="billingCountry"
                                        required
                                    /> */}

                                    <select name='billingCountry'
                                        onChange={(e) => setBillingCountry(e.target.value)}
                                        value={billingCountry}
                                        className='shadow border rounded w-96 py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                                    >
                                        <option value=''>Country <span className='text-red-600 ml-1'>*</span></option>
                                        {countries.map((country) => (
                                            <option key={country.countryId} value={country.countryId} >
                                                {country.name}
                                            </option>
                                        ))}
                                    </select>

                                    <select name='billingProvince'
                                        onChange={(e) => setBillingProvince(e.target.value)}
                                        value={billingProvince}
                                        className='shadow border rounded w-96 py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                                    >
                                        <option value=''>Select state <span className='text-red-600 ml-1'>*</span></option>
                                        {province.map((state) => (
                                            <option key={state.provinceId} value={state.provinceId} >
                                                {state.name}
                                            </option>
                                        ))}
                                    </select>

                                    <select name='billingCity'
                                        onChange={(e) => setBillingCity(e.target.value)}
                                        value={billingCity}
                                        className='shadow border rounded w-96 mb-4 py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mt-2 border-1 border-gray-400'
                                    >
                                        <option value=''>Select city  <span className='text-red-600 ml-1'>*</span></option>
                                        {cities.map((city) => (
                                            <option key={city.cityId} value={city.cityId} >
                                                {city.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {/* <div className='relative w-full mb-4 '>
                                        <input
                                            type='text'
                                            id='billingGst'
                                            name='billingGst'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter GST Number'
                                            value={billingGst}
                                            onChange={(e) => setGst(e.target.value)}
                                        />
                                        <label
                                            htmlFor='billingGst'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            GST
                                        </label>
                                    </div> */}
                                {/* <div className='relative w-full mb-4 '>
                                        <input
                                            type='text'
                                            id='phoneNumber'
                                            name='phoneNumber'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter Phone Number'
                                            value={billingPhoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                                        <label
                                            htmlFor='phoneNumber'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            Phone <span className='text-red-600 ml-1'>*</span>
                                        </label>
                                    </div>
                                    <div className='relative w-full mb-4 '>
                                        <input
                                            type='email'
                                            id='email'
                                            name='email'
                                            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                                            placeholder='Enter Email Address'
                                            value={billingEmail}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <label
                                            htmlFor='billingEmail'
                                            className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                                        >
                                            Email <span className='text-red-600 ml-1'>*</span>
                                        </label>
                                    </div> */}
                                <div className='flex justify-center items-center'>
                                    <button className='bg-blue-950 p-2 text-white rounded' type='submit'>Submit</button>
                                    <button className='text-red-700 cursor-pointer ml-3' onClick={() => setOpenBuyPlan(false)}>Cancel</button>
                                </div>
                            </form>}
                        </div>}

                </div>
            </div>
            {choosedPlan !== 1 && <div className='relative w-full mb-4 mt-4'>
                <input
                    type='text'
                    id='paymentRefrence'
                    name='paymentRefrence'
                    className={`p-2 border w-full focus:outline-none focus:border-blue-500 placeholder:text-sm placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
                    placeholder='Enter Payment Refrence ID'
                    onChange={formik.handleChange}
                    value={formik.values.paymentRefrence}
                    onBlur={formik.handleBlur}
                />
                <label
                    htmlFor='paymentRefrence'
                    className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                >
                    Payment Refrence
                </label>
            </div>}
            <div className=''>
                <div className='mt-2 '>
                    <label className='flex font-semibold cursor-pointer'>
                        <input
                            type='checkbox'
                            name='privacyPolicy'
                            className='hidden peer'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.privacyPolicy}
                        />
                        <div
                            className={`w-4 h-4 mt-1 flex items-center justify-center border-2   rounded-sm ${formik.values.privacyPolicy
                                ? "bg-blue-500 border-blue-500"
                                : "bg-white border-gray-400"
                                } ${formik.touched.privacyPolicy && formik.errors.privacyPolicy && 'border-red-600'}`}
                        >
                            {formik.values.privacyPolicy && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 text-white"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            )}
                        </div>
                        <span className='ml-2'>By using this service, you agree to our <button type='button' className='text-blue-950 underline' onClick={() => setOpenPrivacy(true)}> Privacy Policy</button>.</span>
                    </label>
                    {
                        formik.touched.privacyPolicy && formik.errors.privacyPolicy ?
                            <div className='text-red-600 ml-1 mt-1'>
                                {formik.errors.privacyPolicy}
                            </div> : null
                    }
                </div>
                <div className='mt-2 '>
                    <label className='flex font-semibold cursor-pointer'>
                        <input
                            type='checkbox'
                            name='termsAndCondition'
                            className='hidden peer'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.termsAndCondition}
                        />
                        <div
                            className={`w-4 h-4 mt-1 flex items-center justify-center border-2   rounded-sm ${formik.values.termsAndCondition
                                ? "bg-blue-500 border-blue-500"
                                : "bg-white border-gray-400"
                                } ${formik.touched.termsAndCondition && formik.errors.termsAndCondition && 'border-red-600'}`}
                        >
                            {formik.values.termsAndCondition && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 text-white"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            )}
                        </div>
                        <span className='ml-2'>By using this service, you agree to our <button type='button' className='text-blue-950 underline' onClick={() => setOpenTerms(true)}>Terms and Condition</button>.</span>
                    </label>
                    {
                        formik.touched.termsAndCondition && formik.errors.termsAndCondition ?
                            <div className='text-red-600 ml-1 mt-1'>
                                {formik.errors.termsAndCondition}
                            </div> : null
                    }
                </div>
                <div className='mt-2 '>
                    <label className='flex font-semibold cursor-pointer'>
                        <input
                            type='checkbox'
                            name='refundPolicy'
                            className='hidden peer'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.refundPolicy}
                        />
                        <div
                            className={`w-4 h-4 mt-1 flex items-center justify-center border-2   rounded-sm ${formik.values.refundPolicy
                                ? "bg-blue-500 border-blue-500"
                                : "bg-white border-gray-400"
                                } ${formik.touched.refundPolicy && formik.errors.refundPolicy && 'border-red-600'}`}
                        >
                            {formik.values.refundPolicy && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 text-white"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            )}
                        </div>
                        <span className='ml-2'>By using this service, you agree to our <button type='button' className='text-blue-950 underline' onClick={() => setOpenRefund(true)}>Refund Policy</button>.</span>
                    </label>
                    {
                        formik.touched.refundPolicy && formik.errors.refundPolicy ?
                            <div className='text-red-600 ml-1 mt-1'>
                                {formik.errors.refundPolicy}
                            </div> : null
                    }
                </div>
            </div>
            <PrivacyPolicyModal open={privacy} handleClose={() => setOpenPrivacy(false)} />
            <TermsAndConditionModal open={terms} handleClose={() => setOpenTerms(false)} />
            <RefundModal open={refund} handleClose={() => setOpenRefund(false)} />
            <PaymentMethod openMethod={openMethod} handleToClose={() => setOpenMethod(false)} basic_amount={basic_amount} />
            <PremiumPaymentMethod handleToClose={() => setOpenPremiumMethod(false)} openMethod={openPremiumMethod} premium_amount={Premium_amount} />
        </div>
    )
}

export default LastStep