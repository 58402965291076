import React from 'react'
import { service_providers } from '../../../utils/tempJson/_tempItems'
import { MdVerified } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa";
import { Tooltip } from 'antd';
import defaultLogo from '../../../assets/logo/defaultCompanyLogo.png'
import CompanyProfileLoad from '../../../utils/loadingEffect/CompanyProfileLoad';

function ApprovedCompanies({ loadingCompany, openProfile, companyLogos,logoMap }) {
    
    return (
        <div className='grid  md:grid-cols-3 lg:grid-cols-3 scrollable-container h-full bg-blue-50 rounded-md gap-4 hide-scrollbar px-3 py-2 '>
            {
            //    loadingCompany
            //    ? Array(1)
            //        .fill(0)
            //        .map((d, i) => <CompanyProfileLoad key={i} />)
            //    :
            companyLogos && companyLogos.map((item) => (
                <div className='flex flex-col justify-center items-center w-full h-48 border shadow-md md:p-2  rounded-md bg-white'>
                    <img
                        src={logoMap[item.companyId] || defaultLogo}
                        // src={item.logo}
                        alt={item.legalEntityName}
                        // onClick={handleCompanyDetail}
                        onClick={() => openProfile(item.companyId)}
                        className={` rounded-full h-16 w-16 bg-white cursor-pointer`}
                    />
                    <div className='flex flex-col justify-center items-center mt-2'>
                        <button onClick={() => openProfile(item.id)} className='flex text-center text-xl text-blue-600'>
                            {item.legalEntityName.length > 10
                                ? (
                                    <>
                                        {item.legalEntityName.substring(0, 10)}....
                                    </>
                                )
                                : item.legalEntityName}
                            <Tooltip title="Inkqubee Verified" >
                                <span className='mt-1.5 ml-1 cursor-pointer'><MdVerified /></span>
                            </Tooltip>
                        </button>
                        <span className='text-gray-400'>{item.location}</span>
                    </div>
                    {/* <button type='button' onClick={() => handleStart(item.legalEntityName, item.logo)} className='flex justify-between p-2 items-center w-full border text-gray-700 mt-2'>
                        Get Started <FaArrowRight />
                    </button> */}
                     <button type='button' onClick={() => openProfile(item.companyId)} className='flex justify-between p-2 items-center w-full border text-gray-700 mt-2'>
                        View Profile <FaArrowRight />
                    </button>
                </div>
            ))}
            {/* {service_providers.map((item, index) => (
                <div className='md:flex mt-2 w-full border gap-5 md:p-2 md:flex-row rounded-md'>
                    <div className='flex md:flex-col gap-2 p-2' key={item.id}>

                        <img
                            // src={logoMap[item.companyId] || defaultLogo}
                            src={item.logo}
                            alt={item.legalEntityName}
                            // onClick={handleCompanyDetail}
                            onClick={() => openProfile(item.id)}
                            className={` rounded-full h-14 w-14 bg-white cursor-pointer`}
                        />

                        <h1 className='text-center text-gray-500'>{item.legalEntityName}</h1>
                    </div>
                    <div className='flex flex-col justify-center items-center p-4 md:p-0  md:px-0  md:ml-52 lg:ml-52'>
                        <button onClick={() => handleServiceProvider(item.id, item.isVerified)} className='bg-blue-600 p-2 md:w-48 lg:w-48 text-white rounded-full text-sm hover:bg-white border hover:border-blue-600 hover:text-black'>List as a Service Provider</button>
                        <button onClick={() => handleProject(item.id, item.isVerified)} className='bg-blue-600 p-2 md:w-48 text-center text-sm  mt-2 text-white rounded-full hover:bg-white border hover:border-blue-600 hover:text-black'>Post Project</button>
                        <button onClick={() => handleSellCompany(item.id)} className='bg-blue-600 p-2 md:w-48 text-center text-sm mt-2 text-white rounded-full hover:bg-white border hover:border-blue-600 hover:text-black'>Sell Your Company</button>
                    </div>
                </div>
            ))} */}
        </div>
    )
}

export default ApprovedCompanies