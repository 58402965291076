import { createSlice } from "@reduxjs/toolkit";

const displaySettingsSlice = createSlice({
    name: 'theme',
    initialState: {
        fontSize:10,
        isDark: JSON.parse(localStorage.getItem('isDark')) || false,
        openCompanies:JSON.parse(localStorage.getItem('isCompanies')) || false,
        verifiedCompanies: JSON.parse(localStorage.getItem('verifiedCompanies')) || false,
        notVerifiedCompanies: JSON.parse(localStorage.getItem('notVerifiedCompanies')) || false,
        investor: JSON.parse(localStorage.getItem('investor')) || false
    },
    reducers: {
        increaseFontSize(state, action) {
            state.fontSize += 2
        },
        decreaseFontSize(state, action) {
            state.fontSize -= 2
        },
        resetFontSize(state, action) {
            state.fontSize = 10
        },
        darkModeSwitcher(state, action) {
            state.isDark = !state.isDark
            localStorage.setItem('isDark', JSON.stringify(state.isDark));
        },
        swithchVerified(state, action) {
            state.verifiedCompanies = !state.verifiedCompanies
            localStorage.setItem('verifiedCompanies', JSON.stringify(state.verifiedCompanies));
        },
        switchNotVerified(state, action) {
            state.notVerifiedCompanies = !state.notVerifiedCompanies
            localStorage.setItem('notVerifiedCompanies', JSON.stringify(state.notVerifiedCompanies));
        },
        swithchVerified2(state, action) {
            state.verifiedCompanies = true
            state.notVerifiedCompanies = false
            localStorage.setItem('verifiedCompanies', JSON.stringify(state.verifiedCompanies));
            localStorage.setItem('notVerifiedCompanies', JSON.stringify(state.notVerifiedCompanies));
        },
        switchNotVerified2(state, action) {
            state.notVerifiedCompanies = true
            state.verifiedCompanies = false
            localStorage.setItem('notVerifiedCompanies', JSON.stringify(state.notVerifiedCompanies));
            localStorage.setItem('verifiedCompanies', JSON.stringify(state.verifiedCompanies));
        },
        switchInvestor(state, action) {
            state.investor = true
            state.openCompanies = false
            localStorage.setItem('investor', JSON.stringify(state.investor));
            localStorage.setItem('isCompanies', JSON.stringify(state.openCompanies));
        },
        switchInvestor2(state, action) {
            state.investor = !state.investor
            localStorage.setItem('investor', JSON.stringify(state.investor));
        },
        switchCompany(state, action) {
            state.openCompanies = true
            state.investor = false
            localStorage.setItem('isCompanies', JSON.stringify(state.openCompanies));
            localStorage.setItem('investor', JSON.stringify(state.investor));
        }
    }
});
const { actions, reducer } = displaySettingsSlice
export const { 
    darkModeSwitcher,
    increaseFontSize,
    decreaseFontSize,
    resetFontSize,
    switchNotVerified,
    swithchVerified,
    switchInvestor,
    switchCompany,
    switchNotVerified2,
    swithchVerified2,
    switchInvestor2
} = actions
export default reducer