import React from 'react'
import Select from 'react-select'

function ThirdStep({ formik }) {

  const format = [
    "JPG",
    "CSV",
    "YAML",
    "JSON",
    "PNG",
    "WORD",
    "STEP",
    "MARKDOWN",
    "MP4",
    "PRODUCT_PROTOTYPE",
    "BLUEPRINT",
    "DWG",
    "EXCEL",
    "SOURCE_CONTROL",
    "LAB_REPORT",
    "MATERIAL_SAMPLES",
    "PDF",
    "XML",
    "DXF"
  ];

  const formatMaped = format.map((f) => ({
    value: f,
    label: f
  }))

  const customStyles = {
    control: (base) => ({
      ...base,
      width: "100%", // Ensures full width of the Tailwind wrapper
      minWidth: "300px", // Ensures minimum width
    }),
    menu: (base) => ({
      ...base,
      width: "100%", // Matches the control width
      minWidth: "300px",
    }),
  };
  return (
    <div className='flex flex-col justify-center items-center '>
      <div>
        <div className='relative w-full mt-4 mb-4'>
          <Select
            id='preferredSubmissionFormat'
            className={`p-2 w-full focus:outline-none placeholder-slate-600 text-black rounded-lg`}
            options={formatMaped}
            value={formatMaped.filter((option) =>
              formik.values.preferredSubmissionFormat.includes(option.value)
            )}
            onChange={(selectedOption) => {
              formik.setFieldValue("preferredSubmissionFormat", selectedOption ? selectedOption.value : "");
            }}
            placeholder="Choose The Format"
            styles={customStyles}
            onBlur={formik.handleBlur}
          />
          <label
            htmlFor='preferredSubmissionFormat'
            className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
          >
            Submission Format<span className='text-red-600 ml-1'>*</span>
          </label>
          {/* {formik.touched.typesOfInvestment && formik.errors.typesOfInvestment ?
          <div className='text-red-600 ml-3'>
            {formik.errors.typesOfInvestment}
          </div> : null
        } */}
        </div>
        <div className='relative w-full mb-4'>
          <input
            type='date'
            id='deadline'
            name='deadline'
            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder:text-sm placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
            placeholder='Enter Deadline For Submission'
            onChange={formik.handleChange}
            value={formik.values.deadline}
            onBlur={formik.handleBlur}
          />
          {/* {formik.touched.lastName && formik.errors.lastName ?
                      <div className='text-red-600 ml-1'>
                          {formik.errors.lastName}
                      </div> : null
                  } */}
          <label
            htmlFor='deadline'
            className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
          >
            Deadline For Submission <span className='text-red-600 ml-1'>*</span>
          </label>
        </div>
      </div>
    </div>
  )
}

export default ThirdStep