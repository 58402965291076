import { combineReducers, configureStore } from "@reduxjs/toolkit";
import displayReducer from '../src/store/slices/displaySettingsSlice'
import sidebarReducer from "../src/store/slices/sidebarSlice";
import companyRegisterReducer from "../src/store/slices/companyRegisterSlice"
import authReducer from "../src/store/slices/authSlice"
const reducer=combineReducers({
   displaySettingsState:displayReducer,
   sidebarState:sidebarReducer,
   orgRegiterState:companyRegisterReducer,
   authenticationState:authReducer
})
const store=configureStore({
    reducer,
    //middleware:[thunk]
})

export default store