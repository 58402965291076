import React from 'react'
import SelectBox from '../../../../utils/selectbox/SelectBox';

function PersonalInfo({ formik,countryMaped,provinceMaped,cityMaped }) {
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className='grid grid-cols-2 justify-center items-center gap-4 p-3'>
                <div className='relative w-full mb-4 '>
                    <input
                        type='text'
                        id='firstName'
                        name='firstName'
                        className={`p-2 border w-full focus:outline-none placeholder-slate-600 text-black rounded-lg border-1 placeholder:text-sm  border-gray-400  `}
                        placeholder='Enter First Name'
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        onBlur={formik.handleBlur}
                    />
                    <label
                        htmlFor='firstName'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                    >
                        First Name <span className='text-red-600 ml-1'>*</span>
                    </label>
                    {formik.touched.firstName && formik.errors.firstName ?
                        <div className='text-red-600 ml-1 placeholder:text-sm '>
                            {formik.errors.firstName}
                        </div> : null
                    }
                </div>
                <div className='relative w-full mb-4'>
                    <input
                        type='text'
                        id='lastName'
                        name='lastName'
                        className={`p-2 border w-full focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1 placeholder:text-sm  ${formik.touched.lastName && formik.errors.lastName ? 'border-red-600' : ' border-gray-400 '}`}
                        placeholder='Enter Last Name'
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.lastName && formik.errors.lastName ?
                        <div className='text-red-600 ml-1 placeholder:text-sm '>
                            {formik.errors.lastName}
                        </div> : null
                    }
                    <label
                        htmlFor='lastName'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Last Name <span className='text-red-600 ml-1'>*</span>
                    </label>
                </div>

                <div className='relative w-full mb-4'>
                    <input
                        type='text'
                        id='addressLine1'
                        name='addressLine1'
                        className={`p-2 border w-full focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1  placeholder:text-sm  ${formik.touched.addressLine1 && formik.errors.addressLine1 ? 'border-red-600' : ' border-gray-400 '}`}
                        placeholder='Enter Address Line 1'
                        onChange={formik.handleChange}
                        value={formik.values.addressLine1}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.addressLine1 && formik.errors.addressLine1 ?
                        <div className='text-red-600 ml-1 placeholder:text-sm '>
                            {formik.errors.addressLine1}
                        </div> : null
                    }
                    <label
                        htmlFor='addressLine1'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Address Line 1<span className='text-red-600 ml-1'>*</span>
                    </label>
                </div>

                <div className='relative w-full mb-4'>
                    <input
                        type='text'
                        id='addressLine2'
                        name='addressLine2'
                        className={`p-2 border w-full focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1  placeholder:text-sm  ${formik.touched.addressLine2 && formik.errors.addressLine2 ? 'border-red-600' : ' border-gray-400 '}`}
                        placeholder='Enter Address Line 2'
                        onChange={formik.handleChange}
                        value={formik.values.addressLine2}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.addressLine2 && formik.errors.addressLine2 ?
                        <div className='text-red-600 ml-1 placeholder:text-sm '>
                            {formik.errors.addressLine2}
                        </div> : null
                    }
                    <label
                        htmlFor='addressLine1'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Address Line 2<span className='text-red-600 ml-1'>*</span>
                    </label>
                </div>

                <div className='relative w-full mb-4'>
                    <input
                        type='text'
                        id='pinCode'
                        name='pinCode'
                        className={`p-2 border w-full focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1  placeholder:text-sm  ${formik.touched.pinCode && formik.errors.pinCode ? 'border-red-600' : ' border-gray-400 '}`}
                        placeholder='Enter Pincode'
                        onChange={formik.handleChange}
                        value={formik.values.pinCode}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.pinCode && formik.errors.pinCode ?
                        <div className='text-red-600 ml-1 placeholder:text-sm '>
                            {formik.errors.pinCode}
                        </div> : null
                    }
                    <label
                        htmlFor='pinCode'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Pincode<span className='text-red-600 ml-1'>*</span>
                    </label>
                </div>

                <div className='relative w-full mb-4'>
                    <input
                        type='email'
                        id='email'
                        name='email'
                        className={`p-2 border w-full focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1 placeholder:text-sm   ${formik.touched.email && formik.errors.email ? 'border-red-600' : ' border-gray-400 '} `}
                        placeholder='example@mail.com'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email ?
                        <div className='text-red-600 ml-1 placeholder:text-sm '>
                            {formik.errors.email}
                        </div> : null
                    }
                    <label
                        htmlFor='email'
                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                    >
                        Email Id<span className='text-red-600 ml-1'>*</span>
                    </label>
                </div>

                <div className='flex flex-col justify-center items-center p-5 border'>
                    <div className='flex'>
                        <div className='relative mt-6'>
                            <input
                                type='text'
                                id='idProofNumber'
                                name='idProofNumber'
                                className={`p-2 border w-96  focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1 placeholder:text-sm   ${formik.touched.adharCardNumber && formik.errors.adharCardNumber ? 'border-red-600' : ' border-gray-400 '} `}
                                placeholder='Enter Any ID Proof Number'
                                onChange={formik.handleChange}
                                value={formik.values.idProofNumber}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.idProofNumber && formik.errors.idProofNumber ?
                                <div className='text-red-600 ml-1'>
                                    {formik.errors.idProofNumber}
                                </div> : null
                            }
                            <label
                                htmlFor='idProofNumber'
                                className='absolute top-0 left-4 -mt-2 px-1 text-sm focus:border-blue-500 font-semibold transition-all text-black bg-white'
                            >
                                ID Proof Number
                            </label>
                        </div>

                        {/* <div className="w-full mb-7 mt-0.5 ml-2">
                            <label className="block font-bold  ml-3">
                                Upload ID Proof
                            </label>
                            <input
                                type="file"
                                multiple
                                accept=".pdf,.doc,.docx"
                                onChange={onIdProofChange}
                                className={`p-1 border w-96  focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1  ${formik.touched.adharCardNumber && formik.errors.adharCardNumber ? 'border-red-600' : ' border-gray-400 '} `}
                            />
                        </div> */}
                    </div>
                    <span className='p-1.5 bg-blue-100 text-gray-600 rounded-full mt-2'><b>Note:</b>Claim Trust Shield by verifying your identity!</span>
                </div>

                <div className='flex justify-center items-center p-5 border mt-4 mb-4 w-full'>
                    <div className='relative w-full mb-2'>
                        <input
                            type='text'
                            id='panNumber'
                            name='panNumber'
                            className={`p-2 border w-full focus:outline-none focus:border-blue-500 placeholder-slate-600 text-black rounded-lg border-1 placeholder:text-sm   ${formik.touched.panNumber && formik.errors.panNumber ? 'border-red-600' : ' border-gray-400 '} `}
                            placeholder='Enter PAN Number'
                            onChange={formik.handleChange}
                            value={formik.values.panNumber}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.panNumber && formik.errors.panNumber ?
                            <div className='text-red-600 ml-1 placeholder:text-sm '>
                                {formik.errors.panNumber}
                            </div> : null
                        }
                        <label
                            htmlFor='panNumber'
                            className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                        >
                            PAN Number <span className='text-red-600 ml-1'>*</span>
                        </label>
                    </div>
                </div>

            </div>
            <div className='relative w-full  mt-4'>
                <textarea
                    id='profile'
                    name='profile'
                    className='p-3 w-full border border-gray-600 focus:outline-none placeholder-slate-600 text-black rounded-lg placeholder:text-sm '
                    placeholder='Enter About Your Profile'
                    rows='5'
                    onChange={formik.handleChange}
                    value={formik.values.profile}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.profile && formik.errors.profile ?
                    <div className='text-red-600 ml-1 placeholder:text-sm '>
                        {formik.errors.profile}
                    </div> : null
                }
                <label
                    htmlFor='profile'
                    className='absolute top-0 left-4 font-semibold -mt-2 px-1 text-sm transition-all text-black bg-white'
                >
                    Profile <span className='text-red-600 ml-1'>*</span>
                </label>
            </div>
            <div className='flex justify-between gap-2 mt-4 w-full'>
                <SelectBox
                    handleBlur={formik.handleBlur}
                    arrayValues={countryMaped}
                    placeholder={"Select Country"}
                    inputArgs="country"
                    formikValue={countryMaped.find((option) => option.value === formik.values.country)} 
                    handleChange={(selectedOption) => {
                        formik.setFieldValue("country", selectedOption ? selectedOption.value : ""); 
                    }}
                    label="Country"
                    required
                />


                {
                        formik.touched.country && formik.errors.country ?
                            <div className='text-red-600 ml-1 text-sm '>
                                {formik.errors.country}
                            </div> : null
                    }
                {/* </div> */}

                {/* <div className='flex'>
                        <label className='flex me-1 ml-3 mt-4 font-bold'>State: <span className='text-red-600 ml-1'>*</span></label> */}
                <SelectBox
                handleBlur={formik.handleBlur}
                arrayValues={provinceMaped}
                placeholder={"Select State"}
                inputArgs="state"
                formikValue={provinceMaped.find((option) => option.value === formik.values.state)} 
                handleChange={(selectedOption) => {
                    formik.setFieldValue("state", selectedOption ? selectedOption.value : ""); 
                }}
                label="State"
                required
                />
                {
                        formik.touched.state && formik.errors.state ?
                            <div className='text-red-600 ml-1 text-sm '>
                                {formik.errors.state}
                            </div> : null
                    }

                {/* </div> */}
                <SelectBox
                handleBlur={formik.handleBlur}
                arrayValues={cityMaped}
                placeholder={"Select City"}
                inputArgs="city"
                formikValue={cityMaped.find((option) => option.value === formik.values.city)} 
                handleChange={(selectedOption) => {
                    formik.setFieldValue("city", selectedOption ? selectedOption.value : ""); 
                }}
                label="City"
                required
                />
                {
                        formik.touched.city && formik.errors.city ?
                            <div className='text-red-600 ml-1 text-sm '>
                                {formik.errors.city}
                            </div> : null
                    }
            </div>
            <div className='flex justify-end items-end'>
                <button type='submit' className='btn'>Submit</button>
            </div>
        </form>
    )
}

export default PersonalInfo