import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { IoMdClose } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import companyDefaultImage from '../../../src/assets/logo/defaultCompanyLogo.png'
import { Dropdown, message, Space } from 'antd';
import Select from 'react-select'
import { userOrganization } from '../../helper/organization/orgRequestCall';
import { jwtDecode } from 'jwt-decode';

function UserOption({ onClose, open, companyName, companyImage }) {
    const [path, setPath] = useState("")
    const [companyId, setCompanyId] = useState("")
    const navigate = useNavigate()

    const [companies, setCompanies] = useState([])
    const token = localStorage.getItem('authToken') || ''
    const decoded = jwtDecode(token)
    const userId = decoded.userId

    const fetchCompanies = () => {
        userOrganization(userId)
            .then((res) => {
                if (res.status === 200) {
                    setCompanies(res.data.data)
                } else {
                    console.log("error")
                }
            })
            .catch((err) => {
                console.error("err", err)
            })

    }

    useEffect(() => {
        fetchCompanies()
    }, [])
    const customStyles = {
        control: (base) => ({
            ...base,
            width: '100%',
            minWidth: '200px',
            borderColor: '#6b7280',
            borderRadius: '0.5rem',
            height: '44px',
        }),
        menu: (base) => ({
            ...base,
            zIndex: 9999,
            maxHeight: '300px',
            overflowY: 'auto',
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? '#3b82f6'
                : state.isFocused
                    ? '#93c5fd'
                    : 'white',
            color: state.isSelected ? 'white' : 'black',
        }),
        placeholder: (base) => ({
            ...base,
            color: '#475569',
            fontSize: '0.875rem',
        }),
    };


    const companyMaped = companies.map((org) => ({
        value: org.companyId,
        label: org.legalEntityName
    }))

    const items = [
        {
            label: 'List as Service Provider',
            path: '/service-provider/option',
            key: '1',
        },
        {
            label: 'Post Project',
            path: '/clients/post-project',
            key: '2',
        },
        {
            label: 'Raise Fund',
            path: '/raise-fund',
            key: '3',
        },
    ];

    const optionMaped = items.map((option) => ({
        value: option.path,
        label: option.label
    }))

    const handleNavigate = () => {
        navigate(path, { state: { companyId: companyId } })
        // console.log("path", path)
    }
    return (
        <Dialog open={open} onClose={onClose} fullWidth >
            <DialogTitle className='flex justify-between text-blue-700'>Manage Profile<IoMdClose onClick={onClose} className='cursor-pointer text-black mt-1' /></DialogTitle>
            <DialogContent style={{ textAlign: 'center', padding: '2rem' }}>
                {/* <div className='flex flex-col justify-center items-center border gap-y-2 p-2'>
                    <img
                        src={companyImage ? companyImage : companyDefaultImage}
                        alt='company logo'
                        className='h-20 w-20 rounded-full'
                    />
                    <h1 className='text-xl font-bold'>{companyName}</h1>
                    <div className='flex flex-col p-2 space-y-2 w-full'>
                        <button onClick={() => navigate('/service-provider/option')} className='bg-blue-600 text-white rounded-full p-2'>List as Service Provider</button>
                        <button onClick={() => navigate('/clients/post-project')} className='bg-blue-600 text-white rounded-full p-2'>Post Project</button>
                        <div class="flex items-center my-4">
                            <div class="flex-grow border-t border-gray-300"></div>
                            <span class="px-4 text-gray-500">OR</span>
                            <div class="flex-grow border-t border-gray-300"></div>
                        </div>
                        <button onClick={() => navigate('/sell-company')} className='bg-blue-600 text-white rounded-full p-2'>{companyName ? `Sell ${companyName}` : 'Sell Your Company'}</button>
                    </div>
                </div> */}
                <div className='flex flex-col w-full justify-center items-center border gap-y-2 p-6'>
                    <div className='flex flex-col md:flex-row lg:flex-row gap-4 justify-center items-center '>
                        <div className='relative mt-1'>
                            <Select
                                id='path'
                                className={`p-2 w-full focus:outline-none placeholder-slate-600 placeholder:text-sm text-black rounded-lg`}
                                options={optionMaped}
                                isSearchable={false}
                                value={optionMaped.filter((option) =>
                                    path.includes(option.value)
                                )}
                                onChange={(selectedOption) => {
                                    setPath(selectedOption ? selectedOption.value : "");
                                }}
                                placeholder="Select Option"
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                menuPosition="absolute"
                                menuPlacement="auto"
                            />
                        </div>

                        <div className='relative mt-1'>
                            <Select
                                id='companyId'
                                className={`p-2 w-full focus:outline-none placeholder-slate-600 placeholder:text-sm text-black rounded-lg`}
                                options={companyMaped}
                                value={companyMaped.filter((option) =>
                                    companyId.includes(option.value)
                                )}
                                onChange={(selectedOption) => {
                                    setCompanyId(selectedOption ? selectedOption.value : "");
                                }}
                                placeholder="Choose The Company"
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                menuPosition="absolute"
                                menuPlacement="auto"
                            />
                            <label
                                htmlFor='companyId'
                                className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'
                            >
                                Choose Company<span className='text-red-600 ml-1'>*</span>
                            </label>
                        </div>
                    </div>
                    <div>
                        <button onClick={handleNavigate} className='btn'>Submit</button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default UserOption