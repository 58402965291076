import React, { useEffect, useState } from 'react'
import { countries } from '../../../../utils/tempJson/_tempItems'
import { getCities, getCountries, getProvince } from '../../../../helper/organization/orgRequestCall'
import SelectBox from '../../../../utils/selectbox/SelectBox';

function SecondStep({ formik, countries, province, cities }) {

        // useEffect(() => {
        //         fetchCounty()
        // },[])

        const countryMaped = countries.map((country) => ({
                value: country.countryId,
                label: country.name
            }))
        
            const provinceMaped = province.map((state) => ({
                value: state.provinceId,
                label: state.name
            }))
        
            const cityMaped = cities.map((city) => ({
                value: city.cityId,
                label: city.name
            }))

        return (
                <div className='flex flex-col '>
                        <div className='relative w-full mb-3'>
                                <input
                                        type='text'
                                        id='addressLine1'
                                        name='addressLine1'
                                        className={`p-2 border w-full focus:outline-none focus:border-blue-500 focus:border-2 placeholder:text-sm placeholder-slate-600 text-black rounded-lg border-1 ${formik.touched.addressLine1 && formik.errors.addressLine1 ? 'border-red-600' : 'border-gray-400'}`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.addressLine1}
                                        placeholder='Enter addressline 1'
                                />
                                {
                                        formik.touched.addressLine1 && formik.errors.addressLine1 ?
                                                <div className='text-red-600 ml-1 text-sm'>
                                                        {formik.errors.addressLine1}
                                                </div> : null
                                }
                                <label
                                        htmlFor='addressLine1'
                                        className='absolute top-0 left-4 -mt-2 px-1 text-sm  font-semibold transition-all text-black bg-white'
                                >
                                        Address Line 1 <span className='text-red-600 ml-1'>*</span>
                                </label>
                        </div>

                        <div className='relative w-full mb-4'>
                                <input
                                        type='text'
                                        id='addressLine2'
                                        name='addressLine2'
                                        className={`p-2 mt-2 w-full border focus:outline-none focus:border-blue-500 focus:border-2 placeholder:text-sm placeholder-slate-600 text-black rounded-lg border-1 border-gray-400 ${formik.touched.addressLine2 && formik.errors.addressLine2 ? 'border-red-600' : 'border-gray-400'}`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.addressLine2}
                                        placeholder='Apartment, suite, unit, etc.'
                                />
                                {
                                        formik.touched.addressLine2 && formik.errors.addressLine2 ?
                                                <div className='text-red-600 ml-1 text-sm'>
                                                        {formik.errors.addressLine2}
                                                </div> : null
                                }
                                <label
                                        htmlFor='addressLine2'
                                        className='absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'
                                >
                                        Address Line 2 <span className='text-red-600 ml-1'>*</span>
                                </label>
                        </div>
                        <div className='relative w-full mb-4'>
                                <input
                                        type='text'
                                        id='pinCode'
                                        name='pinCode'
                                        className={`p-2 border w-full focus:outline-none focus:border-blue-500 focus:border-2 placeholder:text-sm placeholder-slate-600 text-black rounded-lg border-1 ${formik.touched.pinCode && formik.errors.pinCode ? 'border-red-600' : 'border-gray-400'}`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.pinCode}
                                        placeholder='Enter Pin Code'
                                />
                                {
                                        formik.touched.pinCode && formik.errors.pinCode ?
                                                <div className='text-red-600 ml-1 text-sm'>
                                                        {formik.errors.pinCode}
                                                </div> : null
                                }
                                <label
                                        htmlFor='pinCode'
                                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                                >
                                        Pin Code<span className='text-red-600 ml-1'>*</span>
                                </label>
                        </div>
                        {/* <div className='relative w-full mb-4'>
                                <input
                                        type='text'
                                        id='landmark'
                                        name='landmark'
                                        className={`p-2 mt-2 w-full border focus:outline-none placeholder-slate-600 text-black rounded-lg border-1 border-gray-400 ${formik.touched.landmark && formik.errors.landmark ? 'border-red-600' : 'border-gray-400'}`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.landmark}
                                        placeholder='Enter the landmark'
                                />
                                 {
                                        formik.touched.landmark && formik.errors.landmark ?
                                                <div className='text-red-600 ml-1'>
                                                        {formik.errors.landmark}
                                                </div> : null
                                }
                                <label
                                        htmlFor='landmark'
                                        className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
                                >
                                        Landmark <span className='text-red-600 ml-1'>*</span>
                                </label>
                        </div> */}
                        {/* <div className='flex'>
                        <label className='flex ml-3 mt-4 me-1 font-bold'>country <span className='text-red-600 ml-1'>*</span></label> */}
                        <div className='flex flex-col md:flex-row lg:flex-row justify-between gap-2 mb-4 w-full'>
                                <SelectBox
                                        handleBlur={formik.handleBlur}
                                        arrayValues={countryMaped}
                                        placeholder={"Select Country"}
                                        inputArgs="country"
                                        formikValue={countryMaped.find((option) => option.value === formik.values.country)} // Adjust this if multi-selection is used
                                        handleChange={(selectedOption) => {
                                                formik.setFieldValue("country", selectedOption ? selectedOption.value : ""); // Ensure value is updated correctly
                                        }}
                                        label="Country"
                                        required
                                />


                                {
                                        formik.touched.country && formik.errors.country ?
                                                <div className='text-red-600 ml-1 text-sm '>
                                                        {formik.errors.country}
                                                </div> : null
                                }
   
                                <SelectBox
                                        handleBlur={formik.handleBlur}
                                        arrayValues={provinceMaped}
                                        placeholder={"Select State"}
                                        inputArgs="province"
                                        formikValue={provinceMaped.find((option) => option.value === formik.values.province)}
                                        handleChange={(selectedOption) => {
                                                formik.setFieldValue("province", selectedOption ? selectedOption.value : "");
                                        }}
                                        label="province"
                                        required
                                />
                                {
                                        formik.touched.province && formik.errors.province ?
                                                <div className='text-red-600 ml-1 text-sm '>
                                                        {formik.errors.province}
                                                </div> : null
                                }

                                <SelectBox
                                        handleBlur={formik.handleBlur}
                                        arrayValues={cityMaped}
                                        placeholder={"Select City"}
                                        inputArgs="city"
                                        formikValue={cityMaped.find((option) => option.value === formik.values.city)}
                                        handleChange={(selectedOption) => {
                                                formik.setFieldValue("city", selectedOption ? selectedOption.value : "");
                                        }}
                                        label="City"
                                        required
                                />
                                {
                                        formik.touched.city && formik.errors.city ?
                                                <div className='text-red-600 ml-1 text-sm '>
                                                        {formik.errors.city}
                                                </div> : null
                                }
                        </div>
                </div>
        )
}

export default SecondStep