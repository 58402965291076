import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FaUsersBetweenLines } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SiWebmoney } from "react-icons/si";
import { ImCancelCircle, ImProfile } from "react-icons/im";
import { getAgrigatedCity, getAllCompanies, getCompanyLogo } from '../../../helper/organization/orgRequestCall';
import { getServices } from '../../../helper/serviceProvider/serviceProviderRequestCall';
import defaultLogo from '../../../assets/logo/defaultCompanyLogo.png'
import { MdVerified } from 'react-icons/md';
import { Tooltip } from 'antd';
import { FaFilter } from "react-icons/fa";
import SelectBox from '../../../utils/selectbox/SelectBox';
import notFound from '../../../assets/no-result-found.avif'
import {useAutoAnimate} from '@formkit/auto-animate/react'
import ServiceProviderLoad from '../../../utils/loadingEffect/ServiceProviderLoad';




function ServiceProviderList() {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const locationState = useLocation();
  const navigate = useNavigate()
  const [loading,setLoading]=useState(false)
  const [services, setServices] = useState([])
  const [companies, setCompanies] = useState([])
  const [allLocation, setAllLocation] = useState([])
  const [companyName, setName] = useState("")
  const [service, setService] = useState("")
  const [isVerified, setVerification] = useState(false)
  const [location, setLocation] = useState(locationState.state?.location || "")
  const [queryMade, setQueryMade] = useState(false);
  const [logoMap, setLogoMap] = useState({});

  const [animationParent] =useAutoAnimate()

  const clearFilter = () => {
    setName("")
    setLocation("")
    setService("")
    setQueryMade(false)
  }


  const serviceMaped = services.map((service) => ({
    value: service,
    label: service
  }))

  const locationMapped = Object.keys(allLocation).map((key) => ({
    //   value: key,
    value: key,
    label: allLocation[key]
  }));

  const getAllServices = () => {
    getServices()
      .then((res) => {
        setServices(res.data.data)
      })
      .catch((err) => { console.error("Error", err) })
  }

  const getLocation = () => {
    getAgrigatedCity()
      .then((res) => {
        if (res.status === 200) {
          setAllLocation(res.data.data)
        }
      })
      .catch((err) => {
        console.error("err", err)
      })
  }

  useEffect(() => {
    getAllServices()
    getLocation()

  }, [])
  const buildQuery = () => {
    const params = new URLSearchParams()
    if (companyName) {
      params.append('companyName', companyName)
      setQueryMade(true)
    }
    if (location) {
      params.append('cityCode', location);
      setQueryMade(true)
    }
    if (service) {
      params.append('service', service)
      setQueryMade(true)
    }
    return params.toString()
  }

  const fetchCompanies = () => {
    const queryString = buildQuery()
    setLoading(true)
    getAllCompanies(queryString)
      .then((res) => {
        if (res.status === 200) {
          setCompanies(res.data.data)
        } else {
          setCompanies([])
        }
      })
      .catch((err) => {
        console.error("error:", err)
        setCompanies([])
      })
      .finally(()=>setLoading(false))
  }

  const fetchAllCompanies = () => {
    setLoading(true)
    console.log("loading",loading)
    getAllCompanies("")
      .then((res) => {
        if (res.status === 200) {
          setCompanies(res.data.data);
          console.log("loading",loading)
        }
      })
      .catch((err) => {
        console.error("error:", err);
        setCompanies([]);
        console.log("loading",loading)
      })
      .finally(()=>setLoading(false))
  };

  const fetchLogo = async (companyId) => {
    await getCompanyLogo(companyId)
      .then((res) => {
        if (res.status === 200) {
          const blob = res.data
          const imageUrl = URL.createObjectURL(blob)
          setLogoMap((prev) => ({ ...prev, [companyId]: imageUrl }));
        }
      })
      .catch((err) => {
        console.error("error:", err)
      })
  }

  useEffect(() => {
    if (companyName || location || service) {
      fetchCompanies()
    } else {
      fetchAllCompanies()
    }
  }, [companyName, location, service])

  useEffect(() => {
    companies.forEach((company) => {
      if (!logoMap[company.companyId]) {
        fetchLogo(company.companyId);
      }
    });
  }, [companies]);

  const handleNavigate = (companyId) => {
    // Pass the current search values in the state
    navigate(`/service-provider/detail/${companyId}`, {
      state: { companyName, service, isVerified, location },
    });
  }
  return (
    <div className={`hide-scrollbar flex flex-col min-h-screen overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-10 px-3`}>
      <div ref={animationParent} className={`flex flex-col ${isDark ? 'bg-slate-400' : 'bg-white'} h-full w-full p-6 rounded space-y-4`}>

        <div className='flex flex-col md:flex-row justify-between border p-3 shadow-md'>
          <h1 className='flex text-2xl font-bold mt-5 ml-2 text-gray-500'><FaFilter className='mt-0.5 me-2' />Filter</h1>
          <div className='flex flex-col md:flex-row p-2 gap-x-3'>
            <div className="relative flex flex-col mt-1">
              <input
                type='text'
                placeholder='Filter by name'
                className="p-1.5 focus:outline-none border h-[44px] border-gray-400 rounded mt-3 ml-2 w-64"
                value={companyName}
                onChange={(e) => setName(e.target.value)}
              />
              <label htmlFor='name' className='flex absolute top-0 left-4  px-1 text-sm font-semibold transition-all text-black bg-white'>
                Name
              </label>
            </div>

            <SelectBox
              arrayValues={locationMapped}
              placeholder={"Filter With Location"}
              inputArgs="location"
              formikValue={locationMapped.find((option) => option.value === location)}
              handleChange={(selectedOption) => {
                setLocation(selectedOption ? selectedOption.value : "");
              }}
              label="location"
              required
            />

            <SelectBox
              arrayValues={serviceMaped}
              placeholder={"Filter With Services"}
              inputArgs="service"
              formikValue={serviceMaped.find((option) => option.value === service)}
              handleChange={(selectedOption) => {
                setService(selectedOption ? selectedOption.value : "");
              }}
              label="Service"
            />

            {queryMade && <div className='flex justify-center items-center me-1 ml-2 mt-3'>
              <button className='flex text-blue-700' onClick={() => clearFilter()}><ImCancelCircle className='me-0.5 mt-0.5' />Clear Filter</button>
            </div>}
          </div>

        </div>
        <div>
          {/* <span className='ml-2 text-gray-600'>{companies.length} Results</span> */}
        </div>
        {
          loading
          ? Array(3)
              .fill(0)
              .map((d, i) => <ServiceProviderLoad key={i} />)
          :
          companies.length > 0 ? (
            companies?.map((company) => (
              <div className='h-52 w-full bg-white rounded-lg mt-4 border border-gray-400 shadow' key={company.id}>
                <div className='p-3' >
                  <div className='flex justify-between'>
                    <div className='flex'>

                      <img
                        src={logoMap[company.companyId] || defaultLogo}
                        alt='logo'
                        className='h-12 w-12 rounded-full me-2'
                      />
                      <div className='flex justify-between'>
                        <Link to={`/service-provider/detail/${company.companyId}`} className='mt-2 text-xl font-bold cursor-pointer hover:underline'>{company.companyBrandName} </Link>
                      </div>
                      <div>
                        <Tooltip placement='top' title='verified'>
                          <MdVerified color='green' size={30} className='mt-2 ml-5' />
                        </Tooltip>
                      </div>
                    </div>
                    <div className='flex mt-1 ml-5'>
                      <a href={company.companyUrl} target='_blank' rel="noopener noreferrer" className='md:flex hidden  bg-blue-600  p-2 text-white rounded border border-blue-800 hover:bg-blue-800 hover:text-white ml-2 me-4  hover:shadow-lg'>Visit Website <SiWebmoney className='mt-1 ml-1' /></a>
                      <Link state={{ companyName, service, isVerified, location }} className='hidden md:flex border border-blue-800 hover:bg-blue-800 hover:text-white ml-2 p-2 me-4  rounded-lg text-black hover:shadow-lg' to={`/service-provider/detail/${company.companyId}`} >View Profile <ImProfile className='mt-1 ml-1' /></Link>
                    </div>
                  </div>
                  <div className='flex gap-12 px-4 mt-2 py-3'>
                    <div className='mt-2'>
                      {/* <li className='flex text-gray-500'><CiLocationOn className=' me-1' /> {company.location.state.name},{company.location.country.name}</li> */}
                      <li className='flex text-gray-500'><FaUsersBetweenLines className='mt-1 me-1' /> {company.noOfEmployees}</li>
                    </div>

                    <div className='p-2'>
                      <h1 className='text-blue-500'>Service</h1>
                      <ul>
                        <li>{company.services}</li>
                      </ul>
                    </div>

                    <div className='hidden md:block  p-2'>
                      <h1 className='text-blue-500'>Founded</h1>
                      <ul>
                        <li>{company.establishmentYear}</li>
                      </ul>
                    </div>

                    <div className='hidden md:block  p-2'>
                      <h1 className='text-blue-500'>About</h1>
                      <p>{company.businessDescription.length > 60
                        ? (
                          <>
                            {company.businessDescription.substring(0, 60)}....
                            <button className='text-blue-500'>
                              View more
                            </button>
                          </>
                        )
                        : company.businessDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))) : (
            <div className='flex justify-center items-center w-screen h-full'>
              <img
                src={notFound}
                alt='No result found'
                className='h-72 w-56'
              />
            </div>
          )}
      </div>
    </div>
  )
}

export default ServiceProviderList