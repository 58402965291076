import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useForgotPasswordForm } from '../../../helper/auth/authFormHook'
import { forgotPassword } from '../../../helper/auth/authRequestCall'
import { Alert, Spin } from 'antd'
import awsStartup from '../../../assets/aws-startup.jpeg'
import startupImage from '../../../assets/Startup-India_Preview.png'

function ForgotPassword() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(false)
    const handleSubmit = (values) => {
        setLoading(true)
        const emailId = values.email
        const new_password = values.newPassword
        forgotPassword(emailId, new_password)
            .then((data) => {
                if (data && data.status === 200) {
                    formik.resetForm()
                    setSuccess("Password changed successfully")
                    setError(null)
                    setLoading(false)
                } else {
                    setError("Password reset failed")
                    setLoading(false)
                }
            })
            .catch((err) => { console.error("error occurd:", err) })
    }
    const formik = useForgotPasswordForm(handleSubmit)
    return (
        <div className='flex min-h-screen'>
            <div className={`flex flex-col justify-center items-center w-full md:w-1/2 lg:w-1/2 px-12  ${!isDark ? 'bg-white' : 'dark-mode'} `}>

                <div className='flex w-full  max-w-full'>
                    <div className={`flex-1 bg-white py-10 flex flex-col w-1/2 justify-center items-center `}>

                        <form onSubmit={formik.handleSubmit} className='flex flex-col border w-full md:w-3/4 lg:w-3/4 px-5 py-7' >
                            {error &&
                                <div className='px-4 w-full mt-4'>
                                    <Alert
                                        message={error}
                                        className='mb-2 '
                                        type='error'
                                        closable
                                    />
                                </div>
                            }
                            {success &&
                                <div className='px-4 w-full mt-4'>
                                    <Alert
                                        message={success}
                                        className='mb-2 '
                                        type='success'
                                        closable
                                    />
                                </div>
                            }
                            <h1 className='text-black text-2xl font-bold text-center mb-4'>Forgot Password</h1>
                            <div className='relative w-full mb-4'>
                                <input
                                    placeholder='enter your email'
                                    id='email'
                                    className={`w-full p-2 border border-gray-200 focus:outline-none bg-white placeholder-slate-600 text-black rounded-lg  ${formik.touched.email && formik.errors.email ? 'border-red-600' : 'border-gray-400'}`}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    onBlur={formik.handleBlur}
                                />
                                {
                                    formik.touched.email && formik.errors.email ?
                                        <div className='text-red-600 ml-1'>
                                            {formik.errors.email}
                                        </div> : null
                                }
                                <label htmlFor='email' className='absolute top-0 left-4 -mt-2 px-1 bg-white text-sm font-semibold transition-all text-black '>
                                    Email <span className='text-red-600 ml-1'>*</span>
                                </label>
                            </div>
                            <div className='relative w-full mb-4'>
                                <input
                                    placeholder='enter new password'
                                    type='password'
                                    id='newPassword'
                                    className={`w-full p-2 border border-gray-200 focus:outline-none placeholder-slate-600 text-black rounded-lg bg-white ${formik.touched.newPassword && formik.errors.newPassword ? 'border-red-600' : 'border-gray-400'}`}
                                    onChange={formik.handleChange}
                                    value={formik.values.newPassword}
                                    onBlur={formik.handleBlur}
                                />
                                {
                                    formik.touched.newPassword && formik.errors.newPassword ?
                                        <div className='text-red-600 ml-1'>
                                            {formik.errors.newPassword}
                                        </div> : null
                                }
                                <label htmlFor='newPassword' className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'>
                                    New Password <span className='text-red-600 ml-1'>*</span>
                                </label>
                            </div>
                            <div className='flex justify-end items-end p-2 border-b'>
                                {loading ? (
                                    <button className='p-2 bg-blue-500 w-full text-white rounded-3xl cursor-pointer' disabled>
                                        <Spin className='custom-spinner' />
                                    </button>
                                ) : (
                                    <input type='submit' value='Change Password' className='btn w-full' />
                                )}
                            </div>
                            <div className='flex justify-between p-2 text-center'>
                                <span className='text-gray-500'>Want to go back to login?</span>
                                <Link to={'/login'} className=' text-blue-700 underline'>signIn</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="flex-1 bg-blue-700 rounded-l-[170px] mt-6 text-white justify-center items-center p-8 hidden md:flex lg:flex flex-col w-full min-h-screen">
                <h2 className="text-5xl font-bold mb-4    relative">  inkqubee<span className='absolute text-sm text-white top-0 '>™</span></h2>
                <p className="text-lg ml-2">LEARN.CONNECT.INVEST</p>
                <div className='flex justify-center items-center p-5'>
                    <img
                        src={awsStartup}
                        alt='aws startup'
                        className=' w-56'
                    />
                     <img
                        src={startupImage}
                        alt='startup India'
                        className=' w-56'
                    />
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword