import React from 'react'

function UserProfileLoad() {
    return (
        <div className="flex flex-col  justify-start w-60 items-start border bg-white p-6 rounded-lg shadow-md  ">
           <div className='animate-pulse p-2 space-y-4'>
            <div className="flex  w-full justify-between">
                <div
                    className={` rounded-full bg-slate-300 h-16 w-16`}
                ></div>

            </div>
            <div className="flex flex-col">
                <label className="h-4 w-full bg-slate-300 rounded"></label>
                <span className="h-2 w-14 bg-slate-300 rounded mt-2"></span>
            </div>
            <div className="flex flex-col">
                <label className="h-4 w-full bg-slate-300 rounded"></label>
                <span className="h-2 w-14 bg-slate-300 rounded mt-2"></span>
            </div>
            <div className="flex flex-col">
                <label className="h-4 w-full bg-slate-300 rounded"></label>
                <span className="h-2 w-14 bg-slate-300 rounded mt-2"></span>
            </div>
            <div className="flex flex-col">
                <label className="h-4 w-full bg-slate-300 rounded"></label>
                <span className="h-2 w-14 bg-slate-300 rounded mt-2"></span>
            </div>
            </div>
        </div>
    )
}

export default UserProfileLoad