import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FaEye } from "react-icons/fa";
import PortfolioDetail from '../../../../../src/utils/Modal/PortfolioDetail';
import { Link } from 'react-router-dom';
import { RiDeleteBinFill } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { getCompanyPortfolios, getPortfolio } from '../../../../helper/serviceProvider/serviceProviderRequestCall';
import { Tooltip } from 'antd';
import { MdVerified } from "react-icons/md";
import { GoUnverified } from "react-icons/go";

function PortfolioList() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const [openDetail, setOpen] = useState(false);
    const [allPortfololios, setPortfolios] = useState([])
    const [portfolioDetail,setPortfolioDetail]=useState(null)
    const [loading,setLoading]=useState(false)

    const fetchPortfolios = () => {
        setLoading(true)
         getCompanyPortfolios()
            .then((res) => {
                // console.log("data",res)
                if (res.status === 200) {
                    setPortfolios(res.data)
                }
            })
            .catch((err) => {
                console.error("error", err)
            })
            .finally(()=>{
                setLoading(false)
                // console.log(loading)
            })
    }

    const handlePortfolioView=(id)=>{
        getPortfolio(id)
        .then((res)=>{
            if(res.status === 200){
                setPortfolioDetail(res.data)
                setOpen(true)
            }
        })
        .catch((err)=>console.error("error occurd:",err))
    }
    useEffect(() => {
        fetchPortfolios()  
    }, [])
   
    if(loading){
        return <h1 className='min-h-screen text-center font-bold'>Loading....</h1>
    }

    return (
        <div className={`flex flex-col min-h-screen w-full ${!isDark ? 'bg-white' : 'dark-mode'} p-5`}>
            <div className={`flex flex-col ${isDark ? 'bg-slate-400' : 'bg-slate-100'} h-full w-full py-11 px-7 rounded space-y-4 `}>
                {/* <div className='p-6'>
                    <PortfolioSearch />
                </div> */}
                {allPortfololios && allPortfololios.map((items) => (
                    <div key={items.id} className={` ${isDark ? 'bg-black' : 'bg-white'} border h-80 border-gray-300 shadow-lg w-full rounded-lg`}>
                        <div className='flex justify-between p-2 border-b border-gray-200'>
                            <span>
                                {
                                    items.active ?
                                        <Tooltip placement='top' title='Active'>
                                            <span className='cursor-pointer'><MdVerified className='mt-2' size={30} color='green' /></span>
                                        </Tooltip> :
                                        <Tooltip placement='top' title='Not active'>
                                            <span className='cursor-pointer'><GoUnverified color='yellow' size={30} className='mt-2' /></span>
                                        </Tooltip>
                                }
                            </span>
                            <h1 className={` ${isDark ? 'text-white' : 'text-black'} text-xl font-bold`}></h1>
                            <div className='flex mt-1'>

                                <Tooltip placement='top' title="View details">
                                    <button className='cursor-pointer me-2' onClick={() => handlePortfolioView(items.id)} ><FaEye size={35} className='hover:text-yellow-300' /></button>
                                </Tooltip>

                                <Tooltip placement='top' title='Update'>
                                    <Link to={`/service-provider/update-portfolio/${items.id}`} className='cursor-pointer me-2'><FaRegEdit size={35} className='hover:text-blue-600' /></Link>
                                </Tooltip>

                                <Tooltip placement='top' title='Delete'>
                                    <span className='cursor-pointer '><RiDeleteBinFill size={35} className='hover:text-red-600' /></span>
                                </Tooltip>
                            </div>
                        </div>

                        <div className='flex justify-between p-3'>
                            <div>
                                <span className='text-lg font-bold'>{items.service}</span>
                                <p className='text-gray-500'>
                                    {items.description.length > 150
                                        ? (
                                            <>
                                                {items.description.substring(0, 150)}....
                                                <button onClick={() => handlePortfolioView(items.id)} className='text-blue-500'>
                                                    View more
                                                </button>
                                            </>
                                        )
                                        : items.description}
                                </p>
                            </div>
                        </div>
                       {openDetail && portfolioDetail && (<PortfolioDetail
                            openDetail={openDetail}
                            service={portfolioDetail.service}
                            active={portfolioDetail.active}
                            handleToClose={() => setOpen(!openDetail)}
                            description={portfolioDetail.description}
                        />)} 
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PortfolioList