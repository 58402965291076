import React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import userAvatar from '../../assets/userAvatar3.jpg'

function InvestorProfile({ openProfile, investors }) {
    return (
        <div className='grid md:grid-cols-3 lg:grid-cols-3 scrollable-container bg-blue-50 h-full rounded-md gap-4 hide-scrollbar px-3 py-2 '>
            {investors && investors.map((item) => (
                <div className='flex flex-col justify-center items-center w-full h-48 border shadow-md md:p-2  rounded-md bg-white'>
                    <img

                        src={userAvatar}
                        alt={item.firstName}
                        onClick={() => openProfile(item.investorId)}
                        className={` rounded-full h-16 w-16 bg-white cursor-pointer`}
                    />
                    <div className='flex flex-col justify-center items-center mt-2'>
                        <h1 className='flex text-center text-xl text-blue-600'>
                            {item.firstName}
                        </h1>
                        <span className='text-gray-400'>{item.location.city.name},{item.location.country.name}</span>
                    </div>
                    <button type='button' onClick={() =>  openProfile(item.investorId)} className='flex justify-between p-2 items-center w-full border text-gray-700 mt-2'>
                        View Profile <FaArrowRight />
                    </button>
                </div>
            ))
            }
        </div >
    )
}

export default InvestorProfile