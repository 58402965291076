import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import userAvatar from "../../src/assets/userAvatar.jpg"
import { jwtDecode } from 'jwt-decode';
import { getUserProfile } from '../helper/auth/authRequestCall';
import { setUserDetails } from '../store/slices/authSlice';


function AppSidebar({ menuItems,hover,
    setHover }) {
    const location = useLocation();
    const [active, setActive] = useState(location.pathname);
    const { isSidebar, isFilter } = useSelector((state) => state.sidebarState)
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const { user } = useSelector((state) => state.authenticationState)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const getUser=()=>{
        const token = localStorage.getItem('authToken')
        const decoded=jwtDecode(token)
        const userId=decoded.userId
        getUserProfile(userId)
        .then(data=>{
         const userProfile = data.data
         dispatch(setUserDetails(userProfile))
        })
        .catch(err =>{
          console.error(err)
        })
  }

  useEffect(()=>{
     getUser()
  },[])

    useEffect(() => {
        setActive(location.pathname);
    }, [location.pathname]);


    const handleRouteActive = (path) => {
        setActive(path)
    }


    return (
        <aside className={`${isSidebar ? "hidden" : "block"} sticky top-0 ${hover ? "w-64" : "w-10"} h-screen ${isDark ? 'bg-slate-900' : 'bg-blue-700'} border-r-2 transition-all duration-300 border-gray-500`} onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <div className='flex flex-col  mt-4'>
                <ul className='space-y-4 px-1'>
                    {menuItems && menuItems.map((item, index) => ( 

                        <li key={index} className='mt-8 '>
                           {item.disabled ? <Link
                                onClick={() => handleRouteActive(item.to)}
                                className={`flex p-2 text-gray-300 font-bold '
                                    }`}
                            >
                                <span className='me-3 '>{item.icon}</span>
                                {hover && <span className="text-sm">{item.name}</span>}
                            </Link> : <Link
                                to={item.to}
                                onClick={() => handleRouteActive(item.to)}
                                className={`flex p-2 rounded-md font-bold ${active === item.to ? 'bg-blue-500  text-white' : 'text-gray-200 '
                                    }`}
                            >
                                <span className='me-3 '>{item.icon}</span>
                                {hover && <span className="text-sm">{item.name}</span>}
                            </Link>}
                        </li>
                    ))}
                </ul>
            </div>
        </aside>
    )
}

export default AppSidebar