import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import users from '../../../assets/icons/group_users.svg'
import project from '../../../assets/icons/project.svg'
import { useNavigate } from 'react-router-dom'
import events from '../../../assets/icons/events.svg'
import { MdOutlineNotificationsActive } from "react-icons/md";
import { Badge } from 'antd'
import { IoIosArrowForward } from "react-icons/io";
import { jwtDecode } from 'jwt-decode'
import { switchInvestor } from '../../../store/slices/displaySettingsSlice'
import { getAllProjects } from '../../../helper/project/projectRequestCalls'


function Dashboard() {
  const { isDark } = useSelector((state) => state.displaySettingsState)
  const [notifications, setNotifications] = useState([
    { id: 1, message: 'You have joined inkqubee', isRead: true,
      //  timestamp: new Date() 
      },
  ])
  const [userProjects, setUserProjects] = useState([])
  const navigate = useNavigate()
  const token = localStorage.getItem('authToken')
  const decoded = jwtDecode(token)
  const userId = decoded.userId
  const dispatch = useDispatch()

  const handleNavigateInvestor = () => {
    navigate('/user-profile', {
      state: {
        userId: userId
      }
    });
    dispatch(switchInvestor())
  };

  const fetchProjects = () => {
    getAllProjects()
      .then((res) => {
        if (res.status === 200) {
          setUserProjects(res.data.data)
        }
      })
      .catch((err) => console.error("error", err))
  }

  useEffect(()=>{
   fetchProjects()
  },[])

  return (
    <div className={`hide-scrollbar flex flex-col min-h-screen overflow-auto w-full ${!isDark ? 'bg-blue-50' : 'dark-mode'} py-10 px-3`}>
      <div className='flex  gap-2 p-4 mt-4'>
        <button onClick={() => navigate('/register-company/company-detail')} className='p-2 bg-blue-600 rounded-md text-white hover:bg-blue-500 mt-2'>Register Your Company</button>
        <button onClick={() => navigate('/investment/register')} className='p-2 bg-blue-600 rounded-md text-white hover:bg-blue-500 mt-2'>Register as Investor  </button>
      </div>
      <div className={`flex flex-col md:flex-row  ${isDark ? 'bg-slate-400' : 'bg-blue-50'} gap-x-2 h-full w-full p-2 rounded space-y-2 `}>
        <div className='flex flex-col w-full mt-2'>
          <div className="flex flex-col gap-3 gap-y-2 p-2">
            <div className="flex flex-col text-slate-700 justify-center items-center bg-white p-4 rounded-md w-full h-14 shadow-lg text-xl">
              Quick Links
            </div>
            <div className='grid grid-cols-2 gap-3 mt-2'>

              <div className="flex text-white justify-center items-center bg-white p-4 rounded-md w-72 h-20 shadow-lg">
                <img src={project} alt="project" className="h-24 w-10" />
                <div className='flex flex-col p-2'>
                  <h1 className='text-black font-semibold ml-5  text-3xl'>{userProjects.length}</h1>
                  <span className='text-gray-600 ml-5'>Posted projects</span>
                </div>
              </div>

              <div className="flex   justify-center items-center bg-white p-4 rounded-md w-72 h-20 shadow-lg">
                <img src={users} alt="users" className="h-12 w-10" />
                <div className='flex flex-col p-2'>
                  <h1 className='text-black font-semibold ml-4  text-3xl'>0</h1>
                  <span className='text-gray-600 ml-4'>Pending Connections</span>
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col gap-3 gap-y-2 p-2'>
            <div className="flex flex-col text-slate-700 justify-center items-center bg-white p-4 rounded-md w-full h-14 shadow-lg text-xl">
              Active Profiles
            </div>

            <div className='flex gap-3 mt-2 '>
              <button className="flex flex-col text-white justify-center items-center bg-white p-2 rounded-md w-full h-auto shadow-lg">
                <span className='flex text-gray-600  text-xl'>Agency <span className='mt-1.5 ml-1'><IoIosArrowForward /></span></span>
              </button>
              <button onClick={() => handleNavigateInvestor()} className="flex flex-col text-white justify-center items-center bg-white p-2 rounded-md w-full h-auto shadow-lg">
                <span className='flex text-gray-600  text-xl'>Investor <span className='mt-1.5 ml-1'><IoIosArrowForward /></span></span>
              </button>
              <div className="flex flex-col text-white justify-center items-center bg-white p-4 rounded-md w-full h-auto shadow-lg">
                <span className='flex text-gray-600  text-xl'>Fund Raiser <span className='mt-1.5 ml-1'><IoIosArrowForward /></span></span>
              </div>
            </div>
          </div>
        </div>

        {/* right side */}
        <div className='flex flex-col w-full  h-full'>
          <div className="flex flex-col  p-2">
            <div className="flex flex-col text-slate-700 justify-center items-center bg-white p-2 rounded-md w-full h-14 shadow-lg text-xl">
              Events
            </div>
            <div className='flex gap-3 mt-2'>
              <div className="flex text-gray-600 justify-center items-center bg-white p-4 rounded-md w-full h-24 shadow-lg">
                There is no upcoming events  <img src={events} alt="project" className="h-5 w-5 ml-2" />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3 gap-y-2 p-2">
            <div className="flex flex-col text-slate-700  justify-center items-center bg-white p-4 rounded-md w-full h-14 shadow-lg text-xl">
              Notifications
            </div>
            <div className='flex gap-3 mt-2'>
              <div className="flex flex-col hide-scrollbar scrollable-container z-10 text-gray-500 px-6 space-y-2 bg-white p-4 rounded-md h-48 w-full  shadow-lg">
                {notifications.map((notification) => (
                  <ul key={notification.id} className={`flex justify-between border p-2 rounded-md cursor-pointer ${!notification.isRead && 'bg-blue-100'}`}>
                    <li className={`flex `}><MdOutlineNotificationsActive className='mt-1 me-1' />{notification.message}</li>
                    <span className="text-sm text-gray-500 mt-1">{notification.timestamp} <Badge dot /></span>
                  </ul>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* <div className={`w-full max-w-7xl justify-center ${!isDark ? 'bg-slate-200' : 'bg-slate-500 '} rounded-lg h-80 px-6 py-2`}>
          <div className='flex flex-col md:flex-row justify-between'>
            <div className='w-full h-52 ml-2'>
              <h1 className='mb-2'>Activity</h1>
              <div className={`${isDark ? 'bg-black' : 'bg-white'} h-64 rounded-lg`}>
                {companyDetail?.charts && (
                  <ActivityChart items={companyDetail.charts} />
                )}
              </div>
            </div>
            <div className='w-full h-52 ml-2'>
              <h1 className='mb-2'>Chart</h1>
              <div className={`${isDark ? 'bg-black' : 'bg-white'} h-64 rounded-lg`}>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>

  )
}

export default Dashboard