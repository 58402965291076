import React from 'react'
import { useSelector } from 'react-redux'
import temp from '../../../assets/temp/cba.jpg'

function AgenciesList() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    return (
        <div className={`hide-scrollbar flex flex-col min-h-screen overflow-auto w-full ${!isDark ? 'bg-blue-50' : 'dark-mode'} py-10 px-3`}>
            <div className={`flex flex-col md:flex-row  ${isDark ? 'bg-slate-400' : 'bg-blue-50'} gap-x-2 h-full w-full py-5 px-10 rounded space-y-2 `}>
                <div className='flex flex-col w-full h-full  mt-4 rounded-lg shadow-md  bg-white  border transition-all p-2 space-y-2'>
                    <div className='flex w-full bg-blue-50 h-24 rounded-md  p-2'>
                        <div>
                        
                        </div>
                    </div>
                    <div className='flex w-full bg-blue-50 h-96 rounded-md'>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgenciesList