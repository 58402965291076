import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { IoMenu } from "react-icons/io5";
import { FaXmark } from "react-icons/fa6";
import logo from '../../assets/logo/InkqubeeLogo.png'
import { useSelector } from 'react-redux';
import { MdOutlineDashboard } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import {Auth, DisplaySettings, ServiceDropdown} from '../../components/public/header/index'
import TempLogo from '../../utils/TempLogo';


function Header({ auth }) {
    const location = useLocation()
    const { isDark,fontSize } = useSelector((state) => state.displaySettingsState)
    const [dropDown, setDropdown] = useState(false)
    const [mobileDropdown, setMobileDropdown] = useState(false)
    const [profileDropdown, setProfileDropdown] = useState(false)
    const [openProfile, setOpenProfile] = useState(false)
    const [editProfile, setEditProfile] = useState(false)
    const navigate=useNavigate() 

    const profileDropdownRef = useRef(null)

    const logoHiddn=['/']
    const loginBtnHidden = [
        '/login', 
        '/register', 
        '/login/forgot-password'
    ]
    const displayLogo = !logoHiddn.includes(location.pathname)
    const displayLoginBtn = !loginBtnHidden.includes(location.pathname)

    const [menu, togleMenu] = useState(false)
    const profileItems = [
        {
            type: 'route',
            icon: <MdOutlineDashboard />,
            name: "Dashboard",
            path: '/dashboard',
        },
        {
            type: 'action',
            icon: <CgProfile />,
            action: "Profile",
            onclick: () => setOpenProfile(!openProfile)
        }
    ]
    const handleDropDown = () => {
        setProfileDropdown(prevState => !prevState)
        // console.log(profileDropdown)
    }

    const handleOutsideClick = (event) => {
        if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
            setProfileDropdown(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [])
    return (
        <header className='fixed w-full  top-0 left-0 z-50 ' style={{ fontSize: `${fontSize + 6}px` }}>
            <div className={`flex flex-col lg:flex-row justify-between p-2 ${isDark ? 'bg-slate-900' : 'bg-blue-800'}`}>
                <div className='flex flex-row justify-between'>
                    <div className=''>
                        {/* <Link to={'/'} className='flex flex-col text-white text-lg font-bold ml-1'>Inkqubee
                            <span className='font-semibold text-[10px] '>Connect. Invest. Grow</span>
                        </Link> */}
                        <Link to={'/'}>
                        <img
                        src={logo}
                        className='relative h-14 w-28 cursor-pointer'
                        />
                        <span className='absolute top-1 left-28 text-white'>™</span>
                        </Link>
                    </div>
                    <div className='flex'>
                    {displayLoginBtn &&
                        <div className='block md:hidden me-2'>
                            <Auth
                                auth={auth}
                                profileDropdown={profileDropdown}
                                profileItems={profileItems}
                                setOpenProfile={setOpenProfile}
                                openProfile={openProfile}
                                handleDropDown={handleDropDown}
                                profileDropdownRef={profileDropdownRef}
                                setEditProfile={setEditProfile}
                                editProfile={editProfile}
                            />
                        </div>
                        }

                    <button className='text-white block lg:hidden me-7 md:me-6' onClick={() => togleMenu(!menu)}>
                        {menu ? <FaXmark /> : <IoMenu />}
                    </button>
                    </div>
                </div>

                <div className={`lg:flex lg:items-center ${menu ? 'block' : 'hidden'}`}>
                    <ul className='flex flex-col lg:flex-row lg:space-x-4'>

                        {/* font and dark mode settings */}
                        <div className='flex me-2 mt-1'>
                            <DisplaySettings />
                        </div>

                      {/* Nav menu list items */}
                        {/* {location.pathname === "/" &&
                            <li className='me-4 text-white cursor-pointer mt-2'><a href="#">Home</a></li>} */}

                        {/* {location.pathname === "/" && <ServiceDropdown
                            setMobileDropdown={setMobileDropdown}
                            mobileDropdown={mobileDropdown}
                            togleMenu={togleMenu}
                            menu={menu}
                            setDropdown={setDropdown}
                            dropDown={dropDown} />} */}

                       {/* login and user profile */}
                        {displayLoginBtn &&
                        <div className='hidden md:block'>
                            <Auth
                                auth={auth}
                                profileDropdown={profileDropdown}
                                profileItems={profileItems}
                                setOpenProfile={setOpenProfile}
                                openProfile={openProfile}
                                handleDropDown={handleDropDown}
                                profileDropdownRef={profileDropdownRef}
                                setEditProfile={setEditProfile}
                                editProfile={editProfile}
                            />
                        </div>
                        }
                    </ul>
                </div>
            </div>
        </header>
    )
}

export default Header
