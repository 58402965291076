import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MdAssignmentAdd } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { switchCompany, switchInvestor, switchNotVerified, switchNotVerified2, swithchVerified, swithchVerified2 } from '../../../store/slices/displaySettingsSlice';
import VerifiedCompanies from '../organization/VerifiedCompanies';
import ApprovedCompanies from '../organization/ApprovedCompanies';
import NotAprovedCompanies from '../organization/NotAprovedCompanies';
import InvestorProfile from '../InvestorProfile';
import UserOption from '../../../utils/Modal/UserOption';
import CompanyProfileLoad from '../../../utils/loadingEffect/CompanyProfileLoad';
import { useAutoAnimate } from '@formkit/auto-animate/react';

function LeftSideSection({ companyLogos, loadUnverified, loadingCompany, logoMap, handleServiceProvider, handleProject, handleSellCompany, openProfile,openInvestorProfile, handleUnverified, investors ,unverified}) {
    const { isDark, openCompanies, verifiedCompanies, notVerifiedCompanies, investor } = useSelector((state) => state.displaySettingsState)
    const [openOption, setOpenOption] = useState(false)
    const [companyName, setName] = useState('')
    const [companyLogo, setLogo] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [animationParent] = useAutoAnimate()


    const handleStart = (companyName, logo) => {
        setOpenOption(true)
        // setName(companyName)
        // setLogo(logo)
    }

    return (
        <div className=' w-full  mt-4 rounded-lg shadow-md h-full bg-white  border transition-all'>
            <div className='flex flex-col md:flex-row justify-end  lg:flex-row mt-1 p-2 border-b' ref={animationParent}>
                {/* <h1 className='text-gray-500 mt-2 text-xl'>Your Profiles</h1> */}
                <div className='flex gap-x-2'>
                    <button onClick={() => dispatch(switchCompany())} className={`${openCompanies ? 'bg-blue-700 text-white' : 'bg-white text-blue-700 border border-blue-700'} p-2 rounded-full  text-center   `}>Company Profiles</button>
                    <button onClick={() => dispatch(switchInvestor())} className={`${investor ? 'bg-blue-700 text-white' : 'bg-white text-blue-700 border border-blue-700'} p-2 rounded-full  text-center   `}>Investor Profile </button>
                </div>
            </div>
            <div className="flex justify-between items-center pb-2">
                {openCompanies &&
                    <div className="flex flex-col md:flex-row lg:flex-row  justify-between  w-full py-2 px-2">
                        <div className=' space-x-4'>
                            <button onClick={() => dispatch(swithchVerified2())} className={` ${verifiedCompanies ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-gray-800'}  font-semibold py-2`}>
                                Verified
                            </button>
                            <button onClick={() => dispatch(switchNotVerified2())} className={` ${notVerifiedCompanies ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-gray-800'}  font-semibold py-2`}>
                                Pending Verification
                            </button>
                        </div>
                        <div className='flex p-2 md:p-0 lg:p-0'>
                            <button className='bg-blue-700 p-1 rounded-md text-white me-2' onClick={handleStart}>Manage Profile</button>
                            <button className={`flex bg-blue-700 p-2 md:ml-2 lg:ml-2 rounded-lg text-white hover:bg-blue-500`} onClick={() => navigate('/register-company/company-detail')}>Register Your Organization <MdAssignmentAdd className='mt-1 ml-1' /></button>
                        </div>
                    </div>}

                {investor && <div className="flex justify-end  w-full py-2 px-2">
                    <div className='flex '>
                        <button onClick={()=>navigate('/investment/register')} className='flex bg-blue-700 p-2  md:ml-2 lg:ml-2 rounded-lg text-white hover:bg-blue-500'>Create Investor Profile <MdAssignmentAdd className='mt-1 ml-1' /></button>
                    </div>
                </div>}
            </div>
            {verifiedCompanies && openCompanies &&
                <div className='p-2 h-full'>
                    <ApprovedCompanies
                        companyLogos={companyLogos}
                        logoMap={logoMap}
                        handleServiceProvider={handleServiceProvider}
                        handleProject={handleProject}
                        handleSellCompany={handleSellCompany}
                        openProfile={openProfile}
                        handleUnverified={handleUnverified}
                        loadingCompany={loadingCompany}
                    />
                </div>
            }

            {notVerifiedCompanies && openCompanies &&
                <div className=' p-2 h-full'>

                    <NotAprovedCompanies
                        openProfile={openProfile}
                        handleUnverified={handleUnverified}
                        loadUnverified={loadUnverified}
                        unverified={unverified}
                    />
                </div>
            }

            {investor &&
                <div className=' p-2 h-full'>
                    <InvestorProfile
                        openProfile={openInvestorProfile}
                        investors={investors}
                    />
                </div>
            }

            <UserOption open={openOption} onClose={() => setOpenOption(false)} />
        </div>
    )
}

export default LeftSideSection