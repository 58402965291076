import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import tempOrg from "../../../../assets/temp/cba.jpg"
import { MdArrowOutward } from "react-icons/md";

function CompanyDetail() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const [openVerify, setOpenVerify] = useState(false)
    const [openBlock,setOpenBlock]=useState(false)

    const handleOpenVerify=()=>{
        setOpenVerify(true)
        setOpenBlock(false)
    }

    const handleOpenBlock=()=>{
        setOpenBlock(true)
        setOpenVerify(false)
    }

    const handleBlock=(e)=>{
        e.preventDefault()
        console.log("blocked")
    }
    const handleVerify=(e)=>{
        e.preventDefault()
        console.log("verified")
    }
    return (
        <div className={`flex flex-col min-h-screen hide-scrollbar overflow-auto justify-center items-center w-full ${!isDark ? 'bg-white' : 'dark-mode'} py-2`}>
            <div className={`w-full h-full max-w-7xl max-h-full justify-center ${!isDark ? 'bg-white' : 'bg-black'} items-center rounded-lg py-2 mt-14 px-2`}>
                <div className="w-full  relative space-y-6 rounded-lg px-6 py-6">
                    <div>
                        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                            <img
                                src={tempOrg}
                                alt="User Avatar"
                                className="w-24 h-24 rounded-full border-4 border-white shadow-md"
                            />
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-col justify-start items-start bg-blue-50 p-6 rounded-lg shadow-md text-gray-700 space-y-4">
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Company Type:</label>
                                <span className="ml-4">Private Limited</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">CIN:</label>
                                <span className="ml-4">XXXX78</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">PAN:</label>
                                <span className="ml-4">XXXX08</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Legal Entity Name:</label>
                                <span className="ml-4">abc limited</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Brand Name:</label>
                                <span className="ml-4">My brand</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Official MailID:</label>
                                <span className="ml-4">mail@mail.com</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Phone Number:</label>
                                <span className="ml-4">9098989990</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">GST Number:</label>
                                <span className="ml-4">5575hrht57</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Established:</label>
                                <span className="ml-4">2022</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Number Of Employees:</label>
                                <span className="ml-4">100-500</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Industries:</label>
                                <span className="ml-4">IT Consulting</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Website:</label>
                                <span className=" ml-4"><a href='' target='_blank' rel="noopener noreferrer" className='flex text-blue-500 underline'>Visit <MdArrowOutward className='mt-1 ml-1' /></a></span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Address Line 1:</label>
                                <span className="ml-4">addressLine1</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Address Line 2:</label>
                                <span className="ml-4">addressLine2</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Pincode:</label>
                                <span className="ml-4">642005</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">State and City:</label>
                                <span className="ml-4">Bengaluru,Karnataka</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Choosed Plan:</label>
                                <span className="ml-4">Premium Rs.9999</span>
                            </div>
                            <div className="flex justify-between w-full">
                                <label className="font-semibold">Status:</label>
                                <span className="ml-4">Payment Refrence</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='flex justify-between ml-6 me-5'>
                    <button type='button' className='bg-red-600 text-white p-2 rounded' onClick={()=>handleOpenBlock()}>Block</button>
                    <button type='button' className='bg-blue-600 text-white p-2 rounded' onClick={()=>handleOpenVerify()}>Verify and Publish</button>
                </div>

                {openBlock && <div className='flex  justify-center items-center '>
                    <form className='flex flex-col w-full max-w-lg border p-5' onSubmit={()=>handleBlock()}>
                    <h1 className='text-center font-bold p-2'>Block</h1>
                        <textarea
                            placeholder='Add Comment'
                            className='border border-gray-400 focus: outline-none p-2'
                        />
                        <div className='flex justify-center items-center gap-2 p-2'>
                            <button type='button' onClick={()=>setOpenBlock(false)} className='text-red-600 font-semibold'>Cancel</button>
                            <button type='submit' className='bg-blue-500 text-white p-1 rounded'>Confirm</button>
                        </div>
                    </form>
                </div>}
               {openVerify && <div className='flex  justify-center items-center '>
                    <form className='flex flex-col w-full max-w-lg border p-5' onSubmit={()=>handleVerify()}>
                        <h1 className='text-center font-bold p-2'>Verify</h1>
                        <textarea
                            placeholder='Add Comment'
                            className='border border-gray-400 focus: outline-none p-2'
                        />
                        <div className='flex justify-center items-center gap-2 p-2'>
                            <button type='button' onClick={()=>setOpenVerify(false)} className='text-red-600 font-semibold'>Cancel</button>
                            <button type='submit' className='bg-blue-500 text-white p-1 rounded'>Confirm</button>
                        </div>
                    </form>
                </div>}
            </div>
        </div>
    )
}

export default CompanyDetail