import { MdOutlineDashboard } from "react-icons/md";
import { GrNotes } from "react-icons/gr";
import { FaBuildingUser } from "react-icons/fa6";
import { TbZoomMoney } from "react-icons/tb";
import { IoMdHome } from "react-icons/io";
import { TbCalendarEvent } from "react-icons/tb";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { TfiNotepad } from "react-icons/tfi";
import { LiaUsersSolid } from "react-icons/lia";

export const sidebarMenu = [
    { type: 'title', title: 'Service Provider' },
    { type: 'item', name: 'Home', to: '/', icon: <IoMdHome/>,disabled: false },
    { type: 'item', name: 'Dashboard', to: '/dashboard', icon: <MdOutlineDashboard/>,disabled: false },
    { type: 'item', name: 'Service Providers', to: '/service-provider/list', icon: <FaBuildingUser/>,disabled: false },
    { type: 'item', name: 'Projects', to: '/other-projects/list', icon: <GrNotes/>,disabled: false },
    { type: 'item', name: 'Investors', to: '/investors/list', icon: <TbZoomMoney/>,disabled: false },
    { type: 'item', name: 'Investment Opportunities', to: '/investment', icon: <FaMoneyBillTrendUp/> ,disabled: false},
    { type: 'item', name: 'Events', to: '/events', icon: <TbCalendarEvent/> ,disabled: false},
    { type: 'item', name: 'Launch Pad',to: '/launch-pad', icon: <TfiNotepad/> ,disabled: true},
    { type: 'item', name: 'Influencers',to: '/influencers', icon: <LiaUsersSolid/> ,disabled: true},
  ];

  export const adminMenu = [
    { type: 'title', title: 'ADMIN' },
    { type: 'item', name: 'Home', to: '/', icon: <IoMdHome/> },
    { type: 'item', name: 'Dashboard', to: '/admin/dashboard', icon: <MdOutlineDashboard/> },
    { type: 'item', name: 'Organizations', to: '/admin/all-organizations', icon: <FaBuildingUser/> },
    { type: 'item', name: 'Investors', to: '/admin/all-investors', icon: <TbZoomMoney/> }
  ];