import React from 'react'

function ThirdSteps({formik}) {
    return (
        <div className='flex flex-col justify-center items-center'>
          <div className='relative w-full mb-4'>
                <input
                    type='url'
                    name='LinkedIn'
                    id='LinkedIn'
                    className={`p-2 border w-full focus:outline-none placeholder-slate-600 focus:border-blue-500 focus:border-2 placeholder:text-sm text-black rounded-lg mt-2 ${formik.touched.LinkedIn && formik.errors.LinkedIn ? 'border-red-600' : ' border-gray-400'}`}
                    placeholder='https://www.linkedin.com/in/your-profile'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.LinkedIn}
                />
                {formik.touched.LinkedIn && formik.errors.LinkedIn ?
                    <div className='text-red-600 ml-1 text-sm'>
                        {formik.errors.LinkedIn}
                    </div> : null
                }
                <label
                    htmlFor='LinkedIn'
                    className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all  text-black bg-white'
                >
                    LinkedIn
                </label>
            </div>

            <div className='relative w-full mb-4'>
                <input
                    type='url'
                    name='faceBook'
                    id='faceBook'
                    className={`p-2 border w-full focus:outline-none placeholder-slate-600 focus:border-blue-500 focus:border-2 text-black placeholder:text-sm rounded-lg mt-2 ${formik.touched.faceBook && formik.errors.faceBook ? 'border-red-600' : ' border-gray-400'}`}
                    placeholder='https://www.facebook.com/your-profile'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.faceBook}
                />
                {formik.touched.faceBook && formik.errors.faceBook ?
                    <div className='text-red-600 ml-1 text-sm'>
                        {formik.errors.faceBook}
                    </div> : null
                }
                <label
                    htmlFor='faceBook'
                    className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                >
                    Facebook
                </label>
            </div>

            <div className='relative w-full mb-4'>
                <input
                    type='url'
                    name='instagram'
                    id='instagram'
                    className={`p-2 border w-full focus:outline-none placeholder-slate-600 focus:border-blue-500 focus:border-2 text-black placeholder:text-sm rounded-lg mt-2 ${formik.touched.instagram && formik.errors.instagram ? 'border-red-600' : ' border-gray-400'}`}
                    placeholder='https://www.instagram.com/your-username'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.instagram}
                />
                {formik.touched.instagram && formik.errors.instagram ?
                    <div className='text-red-600 ml-1 text-sm'>
                        {formik.errors.instagram}
                    </div> : null
                }
                <label
                    htmlFor='instagram'
                    className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                >
                    Instagram
                </label>
            </div>

            <div className='relative w-full mb-4'>
                <input
                    type='url'
                    name='youtube'
                    id='youtube'
                    className={`p-2 border w-full focus:outline-none placeholder-slate-600 focus:border-blue-500 focus:border-2 text-black placeholder:text-sm rounded-lg mt-2 ${formik.touched.instagram && formik.errors.instagram ? 'border-red-600' : ' border-gray-400'}`}
                    placeholder='https://www.youtube.com/channel/your-channel-id'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.youtube}
                />
                {formik.touched.youtube && formik.errors.youtube ?
                    <div className='text-red-600 ml-1 text-sm'>
                        {formik.errors.youtube}
                    </div> : null
                }
                <label
                    htmlFor='youtube'
                    className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                >
                    Youtube
                </label>
            </div>

            <div className='relative w-full mb-4'>
                <input
                    type='url'
                    name='xMedia'
                    id='xMedia'
                    className={`p-2 border w-full focus:outline-none placeholder-slate-600 focus:border-blue-500 focus:border-2 text-black rounded-lg placeholder:text-sm mt-2 ${formik.touched.xMedia && formik.errors.xMedia ? 'border-red-600' : ' border-gray-400'}`}
                    placeholder='https://www.twitter.com/your-handle'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.xMedia}
                />
                {formik.touched.xMedia && formik.errors.xMedia ?
                    <div className='text-red-600 ml-1 text-sm'>
                        {formik.errors.xMedia}
                    </div> : null
                }
                <label
                    htmlFor='xMedia'
                    className='absolute top-0 left-4 -mt-1 px-1 text-sm font-semibold transition-all text-black bg-white'
                >
                    X(Twitter)
                </label>
            </div>        
        </div>
    )
}

export default ThirdSteps