import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Organization from '../../../components/private/dashboard/profileTypes/connected/Organization'
import Projects from '../../../components/private/dashboard/profileTypes/connected/Projects'
import Investor from '../../../components/private/dashboard/profileTypes/connected/Investor'
import FilterProfiles from '../../../components/private/dashboard/FilterProfiles'

function ConnectedProfiles() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const [openOrg, setOpenOrg] = useState(true)
    const [openProject, setOpenProject] = useState(false)
    const [openInvestors, setOpenInvestor] = useState(false)

    const handleOrgOpen = () => {
        setOpenOrg(true)
        setOpenInvestor(false)
        setOpenProject(false)
      }
    
      const handleProjectOpen = () => {
        setOpenProject(true)
        setOpenOrg(false)
        setOpenInvestor(false)
      }
    
      const handleInvestorOpen = () => {
        setOpenInvestor(true)
        setOpenOrg(false)
        setOpenProject(false)
      }
    return (
        <div className={`hide-scrollbar flex flex-col min-h-screen overflow-auto justify-center items-center w-full ${!isDark ? 'bg-blue-50' : 'dark-mode'} py-14 px-8`}>
            <div className={`flex flex-col md:flex-row  ${isDark ? 'bg-slate-400' : 'bg-blue-50'} gap-x-3 h-full w-full p-6 rounded space-y-4 py-5`}>
                <div className='flex flex-col w-full h-full  lg:w-full lg:max-w-full border px-2 lg:px-10 md:px-10 rounded-lg shadow-lg py-6 bg-white'>
                    <FilterProfiles handleOrgOpen={handleOrgOpen} handleProjectOpen={handleProjectOpen} handleInvestorOpen={handleInvestorOpen} openInvestors={openInvestors} openProject={openProject} openOrg={openOrg} />
                    <div className='p-1  h-full border rounded-md'>
                        <div className='scrollable-container hide-scrollbar h-full '>
                            {openOrg && <Organization  />}
                            {openProject && <Projects  />}
                            {openInvestors && <Investor />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConnectedProfiles