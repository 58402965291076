import React from 'react'
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { about } from '../../utils/tempJson/_footer';
import { useSelector } from 'react-redux';
import FooterIcon from '../../assets/Startup-India_Preview.png'
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { IoMdCall } from "react-icons/io";
import awsStartup from '../../assets/aws-startup.jpeg'

function Footer() {
  const { isDark, fontSize } = useSelector((state) => state.displaySettingsState)
  return (
    <div className='flex flex-col z-10'>
      <div className='flex flex-col md:flex-row lg:flex-row justify-between w-full bg-blue-700'>
        <ul className='flex flex-col me-10'>
          <div className='mt-2'>
            <Link to={'/'} className='flex flex-col text-white text-xl ml-4 mt-2 font-bold'>Inkqubee
              <span className='font-semibold text-[10px]'>Connect. Invest. Grow</span>
            </Link>
          </div>
          {/* <p className='ml-4'>address</p> */}

        </ul>
        <ul className='me-10 ml-6 mt-3 md:mt-0 lg:mt-0 md:ml-2 lg:ml-2'>
          <h1 className='my-2 font-bold text-white'>Head Office</h1>
          <div className='flex flex-col'>
            <li className='flex text-white'><FaLocationDot className='mt-1 me-1' /> Inkqubee, No 55, 3rd block, 5th main<br /> road, Ayyappa Nagar, K.R.Puram<br /> Bangalore 560036</li>
            <li className='flex mt-2 cursor-pointer text-white'><IoMdMail className='mt-1 me-1' />connect@inkqubee.com</li>
            <li className='flex mt-2 cursor-pointer text-white'><IoMdCall className='mt-1 me-1' />8310000320</li>
          </div>
        </ul>
        <ul className='ml-6 mt-3 md:ml-14 lg:ml-14 md:mt-0 lg:mt-0'>
          <h1 className='my-2 font-bold text-white'>About Inkqubee</h1>
          {about.map((items, index) => (
            <div className='flex' key={index}>
              <Link to={items.path} className='mt-2 cursor-pointer text-white' key={index}>
                {items.links}
              </Link>
            </div>
          ))}
        </ul>
        <div className='flex flex-col justify-center items-center md:flex-row p-5 lg:flex-row bg-white  lg:w-1/3 md:w-1/3  mt-3 md:mt-0 lg:mt-0'>
          <img src={FooterIcon} alt='startup-registerd' className='sm:justify-center h-32 md:w-40' />
          <img src={awsStartup} alt='aws startup' className='sm:justify-center h-32 md:w-52' />
        </div>
      </div>
      <div className='flex flex-col md:flex-row justify-between border-t-2 border-blue-200 bg-blue-700'>
        <ul className='flex flex-col md:flex-row justify-center px-5 py-5 gap-6'>
          <Link to={'/privacy-policy'} className='cursor-pointer hover:underline text-white'>Privacy Policy</Link>
          <Link to={"/terms-and-service"} className='cursor-pointer hover:underline text-white'>Terms of Service</Link>
          <Link to={"/refund-policy"} className='cursor-pointer hover:underline text-white'>Refund Policy</Link>
          <li className='text-white'>&copy; 2024 Inkqubee. All rights reserved.</li>
        </ul>
        <div className='flex justify-center list-none px-5 py-5 mr-8 gap-3'>
          <a href='https://www.facebook.com/people/Inkqubee/61561561103511/?mibextid=LQQJ4d' target='_blank' rel="noopener noreferrer" className='cursor-pointer text-white'><FaFacebook size={30} /></a>
          <a href='https://www.linkedin.com/company/inkqubee/about/' target='_blank' rel="noopener noreferrer" className='cursor-pointer text-white'><FaLinkedin size={30} /></a>
          {/* <li className='cursor-pointer text-white'><FaYoutube size={30} /></li> */}
        </div>
      </div>
    </div>
  )
}

export default React.memo(Footer) 
