import React from 'react'

function FirstSteps({ formik }) {
  return (
    <div className='flex flex-col justify-center items-center '>
      <div>
        <div className='relative w-full mb-4'>
          <input
            type='text'
            id='projectTitle'
            name='projectTitle'
            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 placeholder:text-sm text-black rounded-lg border-1   border-gray-400 `}
            placeholder='Enter Project Title'
            onChange={formik.handleChange}
            value={formik.values.projectTitle}
            onBlur={formik.handleBlur}
          />
          {/* {formik.touched.lastName && formik.errors.lastName ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.lastName}
                        </div> : null
                    } */}
          <label
            htmlFor='projectTitle'
            className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
          >
            Project Title <span className='text-red-600 ml-1'>*</span>
          </label>
        </div>

        <div className='relative w-full mb-4'>
          <textarea
            type='text'
            id='projectDescription'
            name='projectDescription'
            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder-slate-600 placeholder:text-sm text-black rounded-lg border-1   border-gray-400 `}
            placeholder='Enter Project Description'
            rows='5'
            onChange={formik.handleChange}
            value={formik.values.projectDescription}
            onBlur={formik.handleBlur}
          />
          {/* {formik.touched.lastName && formik.errors.lastName ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.lastName}
                        </div> : null
                    } */}
          <label
            htmlFor='description'
            className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
          >
            Project Description <span className='text-red-600 ml-1'>*</span>
          </label>
        </div>

        <div className='relative w-full mb-4'>
          <textarea
            type='text'
            id='objectiveAndGoals'
            name='objectiveAndGoals'
            className={`p-2 border w-96 focus:outline-none focus:border-blue-500 placeholder:text-sm placeholder-slate-600 text-black rounded-lg border-1   border-gray-400 `}
            placeholder='Enter Objectives & Goals'
            rows='5'
            onChange={formik.handleChange}
            value={formik.values.objectiveAndGoals}
            onBlur={formik.handleBlur}
          />
          {/* {formik.touched.lastName && formik.errors.lastName ?
                        <div className='text-red-600 ml-1'>
                            {formik.errors.lastName}
                        </div> : null
                    } */}
          <label
            htmlFor='objectiveAndGoals'
            className='absolute top-0 left-4 -mt-2 px-1 text-sm font-semibold transition-all text-black bg-white'
          >
            Objectives & Goals <span className='text-red-600 ml-1'>*</span>
          </label>
        </div>
      </div>
    </div>
  )
}

export default FirstSteps