import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaChevronDown, FaXTwitter } from "react-icons/fa6";
import { FaChevronUp } from "react-icons/fa6";
import YourVerification from '../../../../components/private/organization/YourVerification';
import Portfolio from '../../../../components/private/organization/Portfolio';
import { companyProfile, getCompanyFiles, getCompanyLogo } from '../../../../helper/organization/orgRequestCall';
import { FaFacebook, FaFlag, FaUsers } from "react-icons/fa";
import CompanyUsers from '../../../../components/private/organization/CompanyUsers'
import defaultLogo from '../../../../assets/logo/defaultCompanyLogo.png'
import { FaPhoneAlt } from "react-icons/fa";
import { jwtDecode } from 'jwt-decode';
import { compProfile } from '../../../../utils/tempJson/_tempItems';
import { CiMail } from 'react-icons/ci';
import { FaLinkedin } from "react-icons/fa";
import { FaExternalLinkAlt } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { RiInstagramFill } from "react-icons/ri";
import brandIcon from '../../../../assets/brandIcon.png'
import { GrServices } from "react-icons/gr";
import { MdEdit } from "react-icons/md";
import OrgProfileMenu from '../../../../components/private/organization/OrgProfileMenu';

function CompanyProfile() {
    const { isDark } = useSelector((state) => state.displaySettingsState)
    const token = localStorage.getItem('authToken')
    const navigate = useNavigate()
    const [openUsers, setOpenUsers] = useState(false)
    const [logoMap, setLogoMap] = useState({});
    const [openVerification, setOpenVerification] = useState(false)
    const [openPortfolio, setOpenPortfolio] = useState(false)
    const [verified, setVerified] = useState(false)
    // const [comapanyProfile, setProfile] = useState(null)
    //temp
    const [comapanyProfile, setProfile] = useState(null)
    const [logo, setLogo] = useState(null)
    const [file, setFile] = useState(null)
    const decoded = jwtDecode(token)
    const userId = decoded.userId
    const location = useLocation();
    const orgId = location.state?.org_id;

    console.log("ord", orgId)

    const handleGoToId = (path, id) => {
        navigate(path, { state: { org_id: id } })
    }


    const getOrgLogo = (companyId) => {
        getCompanyLogo(orgId)
            .then((res) => {
                const url = URL.createObjectURL(res.data);
                setLogo(url)
                // console.log("logo", url)
                setLogoMap((prev) => ({ ...prev, [companyId]: url }));
            })
            .catch((err) => {
                console.error("errpr", err)
            })
    }

    const getOrgFiles = () => {
        getCompanyFiles(orgId)
            .then((res) => {

                const blob = res.blob(); // Get the file as a Blob
                const url = URL.createObjectURL(blob);
                setFile(url)
            })
            .catch((err) => {
                console.error("Error fetching file:", err);
            });
    }

    const getCompanyProfile = () => {
        companyProfile(orgId, userId)
            .then((res) => {
                if (res.status === 200) {
                    setProfile(res.data.data)
                    // setVerified(res.data.verified)
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }

    useEffect(() => {
        getCompanyProfile()
    }, [])
    useEffect(() => {
        if (comapanyProfile && comapanyProfile.companyId && !logoMap[comapanyProfile.companyId]) {
            getOrgLogo(comapanyProfile.companyId);
        }
    }, [comapanyProfile])
    return (
        <div className={`flex flex-col min-h-screen hide-scrollbar overflow-auto justify-center items-center w-full ${!isDark ? 'bg-blue-50' : 'dark-mode'} py-2`}>

            <div className={`flex  ${isDark ? 'bg-slate-400' : 'bg-blue-50'} h-full w-full p-6 rounded space-y-4`}>
                {/* <div> */}
                <OrgProfileMenu />
                {/* </div> */}
                {comapanyProfile && (
                    <div className={`w-full h-full max-w-7xl  max-h-full justify-center ${!isDark ? 'bg-blue-50' : 'bg-black'} rounded-lg py-10 mt-14 px-2`}>



                        <div className='flex justify-between w-full mt-1'>
                            <div></div>
                            <div className='flex'>
                                <button
                                    onClick={() => handleGoToId('/organization/update-profile', comapanyProfile.companyId)}
                                    className='flex bg-blue-800 hover:bg-blue-400 p-2 me-4 mt-4 rounded-lg text-white hover:shadow-lg'
                                >Update Organization <MdEdit className='mt-1 ml-2' /></button>
                                <Link
                                    to={'/organization/add-users'}
                                    className='flex bg-blue-800 hover:bg-blue-400 p-2 me-4 mt-4 rounded-lg text-white hover:shadow-lg'
                                >
                                    Add Users <FaUsers className='mt-1 ml-2' />
                                </Link>
                            </div>
                        </div>
                        <div className='flex justify-between max-w-7xl  p-2 relative'>
                            {
                                <img
                                    src={logoMap[comapanyProfile.companyId] || defaultLogo}
                                    alt='logo'
                                    className='relative h-32 w-44 rounded-xl shadow-lg'
                                />
                            }
                            {/* {
                                comapanyProfile.isVerified ?
                                    <Tooltip placement='top' title='verified'>
                                        <MdVerified color='green' size={30} className='absolute top-0 left-44 cursor-pointer' />
                                    </Tooltip> :
                                    <Tooltip placement='top' title='under verification'>
                                        <GoUnverified color='yellow' size={30} className='absolute top-0 left-44 cursor-pointer' />
                                    </Tooltip>
                            } */}
                            <div className='flex flex-row gap-3'>
                                {/* <div className={`flex flex-col justify-between ${isDark ? 'bg-slate-200' : 'bg-white'} p-2 mb-8 mt-8 rounded-xl shadow-lg border-t-4 border-r-2 border-blue-300`}>
                                    <h1 className='text-black text-xl'>Total Reviews</h1>
                                    <span className='mt-2 text-blue-600'>100</span>
                                </div> */}
                            </div>
                        </div>

                        {/* Company information links */}
                        <div className='flex flex-col gap-6  p-2 h-full '>
                            <section id='companyInfo' className={` flex flex-col gap-4 ${isDark ? 'bg-slate-600' : 'bg-white'} w-full h-auto border shadow-lg rounded-lg p-5 py-14`}  >
                                <div className='flex justify-between border-b p-1'>
                                    <h1 className='text-xl text-gray-600'>Company Information</h1>
                                    <Tooltip title="Update Company Details" placement='top'>
                                        <button onClick={() => handleGoToId('/organization/update-profile', comapanyProfile.companyId)} className='p-2 rounded-full bg-gray-500 text-white'><MdEdit /></button>
                                    </Tooltip>
                                </div>
                                <div className='flex flex-row w-full '>
                                    <div className='border-r w-3/4 p-3'>
                                        <h1 className='font-bold text-lg text-gray-700 '>Buisness Description</h1>
                                        <p className='text-sm text-gray-500'>{comapanyProfile.businessDescription}</p>
                                    </div>
                                    <div>
                                        <div className=' p-2 px-6 max-w-96'>
                                            <span>{comapanyProfile.legalEntityName}</span>
                                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} text-sm mt-1`}><span className='text-black font-semibold'> CIN: </span>{comapanyProfile.cin}</span>
                                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} text-sm mt-1`}> <span className='text-black font-semibold'> PAN:</span>{comapanyProfile.pan}</span>
                                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} text-sm mt-1`}> <span className='text-black font-semibold'> GST Number:</span>{comapanyProfile.gstin}</span>
                                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} mt-1`}><CiMail className='me-1 mt-1' /> {comapanyProfile.officialMailId}</span>
                                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} mt-1`}><FaPhoneAlt className='me-1 mt-1' /> {comapanyProfile.phoneNumber}</span>
                                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} mt-1`}><FaFlag className='me-1 mt-1' /> {comapanyProfile.establishmentYear}</span>
                                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} mt-1`}><FaUsers className='me-1 mt-1' /> {comapanyProfile.noOfEmployees}</span>
                                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} mt-1`}><GrServices className='me-1 mt-1' /> {comapanyProfile.services}</span>
                                            <span className={`flex ${isDark ? 'text-white' : 'text-gray-600'} mt-1`}>
                                                <img
                                                    src={brandIcon}
                                                    alt='brand'
                                                    className='me-1 mt-1 h-5 w-5'
                                                />
                                                {comapanyProfile.companyBrandName}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/*Location Info  */}
                            <section className={` flex flex-col px-8 h-96  justify-between py-7 mt-3 gap-4 ${isDark ? 'bg-slate-600' : 'bg-white'} w-full border shadow-lg rounded-lg`} id='location'>
                                <div className='flex justify-between border-b p-1'>
                                    <h1 className='text-xl text-gray-600'>Location</h1>
                                    <Tooltip title="Update Location Info" placement='top'>
                                        <button onClick={() => handleGoToId('/organization/update-profile', comapanyProfile.companyId)} className='p-2 rounded-full bg-gray-500 text-white'><MdEdit /></button>
                                    </Tooltip>
                                </div>
                                <div className='flex justify-between'>
                                    <div className="flex flex-col w-full justify-start items-start bg-white p-6 rounded-lg  text-black space-y-4 mt-4">
                                        <div className="flex justify-between w-full">
                                            <label className="text-gray-800 font-semibold">Address Line 1:</label>
                                            <span className="text-gray-600 ml-4">{comapanyProfile.communicationAddress.addressLine1}</span>
                                        </div>
                                        <div className="flex justify-between w-full">
                                            <label className="text-gray-800 font-semibold">Address Line 2:</label>
                                            <span className="text-gray-600 ml-4">{comapanyProfile.communicationAddress.addressLine2}</span>
                                        </div>
                                        <div className="flex justify-between w-full">
                                            <label className="text-gray-800 font-semibold">Pin Code:</label>
                                            <span className="text-gray-600 ml-4">{comapanyProfile.communicationAddress.postalCode}</span>
                                        </div>
                                        <div className="flex justify-between w-full">
                                            <label className="text-gray-800 font-semibold">Country:</label>
                                            <span className="text-gray-600 ml-4">{comapanyProfile.communicationAddress.country.name}</span>
                                        </div>
                                        <div className="flex justify-between w-full">
                                            <label className="text-gray-800 font-semibold">State:</label>
                                            <span className="text-gray-600 ml-4">{comapanyProfile.communicationAddress.state.name}</span>
                                        </div>
                                        <div className="flex justify-between w-full">
                                            <label className="text-gray-800 font-semibold">City:</label>
                                            <span className="text-gray-600 ml-4">{comapanyProfile.communicationAddress.city.name}</span>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/* Social media link section */}
                            <section id='socialMediaInfo' className={` flex flex-col px-8   justify-between py-7 mt-3 gap-4 ${isDark ? 'bg-slate-600' : 'bg-white'} w-full border shadow-lg rounded-lg`}>
                                <div className='flex justify-between border-b p-1'>
                                    <h1 className='text-xl text-gray-600'>Social Media Links</h1>
                                    <Tooltip title="Update Social Media Info" placement='top'>
                                        <button onClick={() => handleGoToId('/organization/update-profile', comapanyProfile.companyId)} className='p-2 rounded-full bg-gray-500 text-white'><MdEdit /></button>
                                    </Tooltip>
                                </div>
                                <div className='grid grid-cols-3 gap-6 justify-between'>
                                    <div className="flex">
                                        <label className="text-gray-500 cursor-pointer mt-1.5 me-1"><FaLinkedin /></label>
                                        <a href={comapanyProfile.linkedInUrl} target='_blank' rel="noopener noreferrer" className='flex text-blue-500 hover:text-blue-900 cursor-pointer mt-0.5'>View <FaExternalLinkAlt className='mt-1 ml-1' /></a>
                                    </div>
                                    <div className="flex">
                                        <label className="text-gray-500 cursor-pointer mt-1.5 me-1"><RiInstagramFill /></label>
                                        <a href={comapanyProfile.instagramUrl} target='_blank' rel="noopener noreferrer" className='flex text-blue-500 hover:text-blue-900 cursor-pointer mt-0.5'>View <FaExternalLinkAlt className='mt-1 ml-1' /></a>
                                    </div>
                                    <div className="flex">
                                        <label className="text-gray-500 cursor-pointer mt-1.5 me-1"><FaXTwitter /></label>
                                        <a href={comapanyProfile.twitterUrl} target='_blank' rel="noopener noreferrer" className='flex text-blue-500 hover:text-blue-900 cursor-pointer mt-0.5'>View <FaExternalLinkAlt className='mt-1 ml-1' /></a>
                                    </div>
                                    <div className="flex">
                                        <label className="text-gray-500 cursor-pointer mt-1.5 me-1"><FaFacebook /></label>
                                        <a href={comapanyProfile.facebookUrl} target='_blank' rel="noopener noreferrer" className='flex text-blue-500 hover:text-blue-900 cursor-pointer mt-0.5'>View <FaExternalLinkAlt className='mt-1 ml-1' /></a>
                                    </div>
                                    <div className="flex">
                                        <label className="text-gray-500 cursor-pointer mt-1.5 me-1"><IoLogoYoutube /></label>
                                        <a href={comapanyProfile.youtubeUrl} target='_blank' rel="noopener noreferrer" className='flex text-blue-500 hover:text-blue-900 cursor-pointer mt-0.5'>View <FaExternalLinkAlt className='mt-1 ml-1' /></a>
                                    </div>
                                </div>
                            </section>

                            {/* company related resources(portfolio,users list etc..) */}
                            <div className={`${isDark ? 'bg-slate-700' : 'bg-white'} h-auto rounded-lg w-full shadow-lg border`}>

                                <div className='p-2'>
                                    {/* <h1 className='text-2xl font-bold'>Portfolio details</h1> */}
                                </div>
                                <ul className='p-3 space-y-4'>
                                    <li
                                        onClick={() => setOpenUsers(!openUsers)}
                                        className='flex justify-between text-xl border-b-2 border-gray-300 cursor-pointer hover:underline hover:font-semibold'>
                                        Users
                                        {openUsers ?
                                            <FaChevronUp
                                                className='cursor-pointer'
                                                onClick={() => setOpenUsers(!openUsers)}
                                            />
                                            :
                                            <FaChevronDown
                                                className='cursor-pointer'
                                                onClick={() => setOpenUsers(!openUsers)}
                                            />
                                        }
                                    </li>
                                    {openUsers && <div>
                                        <CompanyUsers id={orgId} />
                                    </div>}

                                    {/* {openVerification && <div>
                                        <YourVerification verified={verified} />
                                    </div>} */}

                                    <li
                                        onClick={() => setOpenPortfolio(!openPortfolio)}
                                        className='flex justify-between text-xl border-b-2 border-gray-300 cursor-pointer hover:underline hover:font-semibold'
                                    >
                                        Portfolio
                                        {openPortfolio ?
                                            <FaChevronUp className='cursor-pointer'
                                                onClick={() => setOpenPortfolio(!openPortfolio)}
                                            /> :
                                            <FaChevronDown
                                                className='cursor-pointer'
                                                onClick={() => setOpenPortfolio(!openPortfolio)}
                                            />}
                                    </li>
                                    {openPortfolio && <div>
                                        <Portfolio org_id={orgId} />
                                    </div>}
                                </ul>
                            </div>
                        </div>


                    </div>)}
            </div>
        </div>
    )
}

export default CompanyProfile