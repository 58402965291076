import React from 'react'
import project from '../../../../../assets/projectIcon.jpg'
import { TiMessages } from "react-icons/ti";
import { CiCircleRemove } from "react-icons/ci";
import { IoIosRemoveCircle } from "react-icons/io";


function Projects({ projects_list }) {
    return (
        <div>
            {projects_list.map((items, index) => (
                <div key={index} className='flex gap-5 mt-2 border rounded-lg  p-3 '>
                    <img
                        src={project}
                        alt='project'
                        className='h-14 w-14 rounded-full'
                    />
                    <div className='flex flex-col mt-2'>
                        <span className='text-gray-700'>{items.title}</span>
                    </div>
                    <div className='flex mt-6  justify-between ml-auto'>
                        {/* <button className='flex bg-blue-800 text-white px-4 py-1 rounded mb-2 me-2 h-8'>Message<TiMessages className='mt-1 ml-1' /></button> */}
                        <button className='flex bg-blue-800 text-white px-4 py-1 rounded h-8'>Remove<IoIosRemoveCircle className='mt-1 ml-1' /></button>
                    </div>

                </div>
            ))}
        </div>
    )
}

export default Projects